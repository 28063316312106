/**
 * IMPORTANT!
 *
 * This file requires Intl available to be imported, so you
 * should import it INDIVIDUALLY after making sure you
 * loaded the Intl polyfill.
 */

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { bool, number } from 'prop-types';

export const percentFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
});

export const ratioFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

const signFor = (value) => value > 0 && '+';

const FormattedPercent = (props) => (
  <FormattedNumber
    {...percentFormat.resolvedOptions()}
    {...props}
  >
    {(str) => (
      <span>
        {props.showSign && signFor(props.value)}
        {str}%
      </span>
    )}
  </FormattedNumber>
);

const FormattedRatio = (props) => (
  <FormattedNumber
    {...percentFormat.resolvedOptions()}
    {...props}
    style="percent"
  />
);

export default function FormatPercent({ isRatio, ...props }) {
  if (isRatio) {
    return <FormattedRatio {...props} />;
  }
  return <FormattedPercent {...props} />;
}

FormatPercent.propTypes = {
  isRatio: bool,
  showSign: bool,
  value: number,
  maximumFractionDigits: number,
  minimumFractionDigits: number,
};
