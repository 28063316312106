import { cloneElement, isValidElement, Children } from 'react';

/**
 * Ensures all elements have keys.
 * See https://fb.me/react-warning-keys
 *
 * @param {React.PropTypes.node} children
 * @param {string} prefix
 */
export default function ensureChildrenHaveKeys(children, prefix = 'ensuredKey') {
  // Single child case. No key is required.
  if (!Array.isArray(children)) return children;

  let nextIndex = 1;

  return Children.map(children, (child) => {
    if (!isValidElement(child) || child.key) {
      return child;
    }
    const key = `${prefix}${nextIndex++}`;
    return cloneElement(child, { key });
  });
}
