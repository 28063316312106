import React, { Component, Children, cloneElement } from 'react';
import { ensureChildrenHaveKeys } from '@lendinghome/utility-belt-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import Measure from 'react-measure';

const { func, node, shape, string } = PropTypes;

const rotationClass = (isRotatedFront) => ({
  'is-flipper-rotated-front': isRotatedFront,
  'is-flipper-rotated-back': !isRotatedFront,
});

const Front = ({ children, rotation, className }) => (
  <section className={classnames(className, rotationClass(rotation === 'front'), 'flipper--front')}>{children}</section>
);

Front.propTypes = {
  children: node.isRequired,
  rotation: string,
  className: string,
};

const Back = ({ children, rotation, className }) => (
  <section className={classnames(className, rotationClass(rotation === 'back'), 'flipper--back')}>{children}</section>
);

Back.propTypes = {
  children: node.isRequired,
  rotation: string,
  className: string,
};

// Currently assumes:
// 1. Flipped elements are equal heights
// 2. Elements can be static in height (will need a wrapper with static height)
// 3. Control to trigger flip will live somewhere inside the elements
export default class Flipper extends Component {
  constructor(props) {
    super(props);
    this.flip = this.flip.bind(this);
    this.state = {
      rotation: props.startingRotation || 'front',
    };
  }

  flip() {
    this.setState(
      (currentState) => ({
        rotation: currentState.rotation === 'front' ? 'back' : 'front',
      }),
      () => {
        this.props.onFlip(this.state.rotation);
      }
    );
  }

  render() {
    const { children } = this.props;
    const doFlip = () => this.flip();

    const renderedChildren = children({
      Front,
      Back,
      doFlip,
    });

    let modifiedChildren = ensureChildrenHaveKeys(renderedChildren);

    modifiedChildren = Children.map(modifiedChildren, (child) => cloneElement(child, { ...this.state }));

    return (
      <section
        className="flipper"
        {...this.props.containerProps}
      >
        {modifiedChildren}
      </section>
    );
  }
}

Flipper.defaultProps = {
  onFlip: () => {},
};

Flipper.propTypes = {
  children: func,
  onFlip: func,
  containerProps: shape({}),
};
