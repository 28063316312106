import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';

function createRecursiveObjectMapper(keysOrValues = 'values') {
  return function recursiveMapper(obj, fn) {
    const mapperFn = keysOrValues === 'values' ? mapValues : mapKeys;
    const converted = isPlainObject(obj)
      ? mapperFn(obj, (value, oldKey) => {
          return fn(value, oldKey);
        })
      : obj;

    return isPlainObject(converted) || Array.isArray(converted)
      ? mapValues(converted, (value) => {
          if (isPlainObject(value)) {
            return recursiveMapper(value, fn);
          } else if (Array.isArray(value)) {
            return value.map((item) => recursiveMapKeys(item, fn));
          } else {
            return value;
          }
        })
      : converted;
  };
}

export const recursiveMapKeys = createRecursiveObjectMapper('keys');
export const recursiveMapValues = createRecursiveObjectMapper('values');
