import React from 'react';
import MaskedInput from 'react-text-mask';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

const SocialSecurityNumberInput = ({ children, id, ...props }) => {
  const containerClasses = classnames('fs-block', {
    'is-input-empty': !props.value,
    'float-input': props.isFloatInput,
    'alternate-label-control': props.alternateLabelControl,
  });

  const theId = id || uniqueId('ssn_');

  return (
    <fieldset className={containerClasses}>
      <MaskedInput
        {...props}
        // Limitation of text-mask component forces us to use a blank unicode
        // char instead of "space"
        // (see: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#placeholderchar)
        placeholderChar={'\u2000'}
        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        type="text"
        id={theId}
      />
      <label htmlFor={theId}>{props.label}</label>
      {props.children}
    </fieldset>
  );
};

SocialSecurityNumberInput.propTypes = {
  /** A string that diplays in the field when the input is empty */
  placeholder: PropTypes.string,
  /** the value of the field */
  value: PropTypes.string,
  /** the CSS id of the field */
  id: PropTypes.string,
  /** a function that fires when the field changes */
  onChange: PropTypes.func,
  /** a function that fires when the field is deselected */
  onBlur: PropTypes.func,
  /** a label for the field, currently this does not seem to render anything */
  label: PropTypes.string,
  /** uses float input style */
  isFloatInput: PropTypes.bool,
  /** uses alternate label style */
  alternateLabelControl: PropTypes.bool,
};

SocialSecurityNumberInput.defaultProps = {
  placeholder: 'Social Security Number',
  value: null,
  id: null,
  onChange: null,
  onBlur: null,
  label: null,
  isFloatInput: true,
  alternateLabelControl: true,
};

export default SocialSecurityNumberInput;
