import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, any, object, func } from 'prop-types';
import { navigateTo } from '../navigation';
import { genericErrorHandler, LendingHomeValidationError } from '../errorHandling';
import funnelStepOrdering from '../funnelStepOrdering';
import clientToServerMapping from '../clientToServerMapping';
import mapStepValueToServerParams from '../mapStepValueToServerParams';
import * as ajax from '../ajax';
import { getFunnelSteps, furthestStepVisited, previousStepVisited } from '../selectors';
import * as actions from '../actions';
import EventNotifier from '../analytics/eventNotifier';
import { scrollToTop } from '../util';

const DEFAULT_FUNNELCAKE_STATE = {
  disabled: false,
  isValid: true,
  invalidMessage: '',
};

export default function FunnelCake({
  InputForm,
  mapValueToAjaxParams,
  mapPropsToAjaxParams,
  saveToServer = ajax.updateLead,
  saveFunnelStep = ajax.saveFunnelStep,
  handleSaveToServerError = genericErrorHandler,
  reenableUponComplete = false,
  notifyStepLoadedHandlers = [EventNotifier.notifyStepLoaded],
  notifyStepCompletedHandlers = [EventNotifier.notifyStepCompleted],
}) {
  class FunnelCakeComponent extends Component {
    constructor(props) {
      super(props);

      this.state = DEFAULT_FUNNELCAKE_STATE;
      this.funnelStepOrdering = funnelStepOrdering(props.stateQuerier.flowName());
    }

    componentDidMount() {
      scrollToTop();

      const { flow, step, stateQuerier, setFunnelMetaData, funnelVisitId } = this.props;

      saveFunnelStep(
        {
          flow,
          step,
          furthestStepVisited: this.props.furthestStepVisited,
          funnelVisitId,
          flowName: stateQuerier.flowName(),
        },
        setFunnelMetaData
      );

      this.saveFunnelStepMetaData(setFunnelMetaData);

      notifyStepLoadedHandlers.forEach((notifyFunction) => {
        notifyFunction({
          flow,
          step,
          state: stateQuerier,
          url: window.location.href,
        });
      });
    }

    saveFunnelStepMetaData = (setFunnelMetaData) => {
      const { flow, step, previousStepVisited: prevStepVisited } = this.props;
      const { stepNumber, terminal } = this.funnelStepOrdering.getStep({ flow, step });

      setFunnelMetaData({ key: 'isTerminal', value: terminal });
      setFunnelMetaData({ key: 'stepNumber', value: stepNumber });
      if (prevStepVisited) {
        const { stepNumber: prevStepNumber } = this.funnelStepOrdering.getStep({
          flow: prevStepVisited.flow,
          step: prevStepVisited.step,
        });
        setFunnelMetaData({ key: 'prevStepNumber', value: prevStepNumber });
        setFunnelMetaData({ key: 'prevStepName', value: prevStepVisited.step });
        setFunnelMetaData({ key: 'prevFlowName', value: prevStepVisited.flow });
      }
    };

    navigateToPrevious = () => {
      const { flow, step, stateQuerier, setFunnelMetaData } = this.props;

      setFunnelMetaData({ key: 'previousStepVisited', value: { flow, step } });

      navigateTo(
        this.funnelStepOrdering.getPreviousStep({
          flow,
          step,
          state: stateQuerier,
        })
      );
    };

    navigateToNext = (navigateFromStep) => {
      const { stateQuerier, setFunnelMetaData } = this.props;
      const { flow, step } = navigateFromStep || this.props;

      setFunnelMetaData({ key: 'previousStepVisited', value: { flow, step } });

      navigateTo(
        this.funnelStepOrdering.getNextStep({
          flow,
          step,
          state: stateQuerier,
        })
      );
    };

    reloadStep = () => {
      window.location.reload();
    };

    storeStepValue = (value) => {
      const { step, setFunnelStepValue } = this.props;
      setFunnelStepValue({ step, value });
    };

    getStepValue = () => {
      const { step, stateQuerier } = this.props;
      return stateQuerier.stepValue(step);
    };

    saveToServerParams() {
      let result;
      const { step } = this.props;
      const value = this.getStepValue();

      if (mapValueToAjaxParams) {
        // TODO:
        // These things are too similarly named now.
        result = mapValueToAjaxParams(value);
      } else {
        result = mapStepValueToServerParams({
          step,
          value,
          clientToServerMapping,
        });
      }

      if (mapPropsToAjaxParams) {
        const extraParams = mapPropsToAjaxParams(this.props);
        result = { ...result, ...extraParams };
      }

      return result;
    }

    completeStep = () => {
      const { flow, step, stateQuerier, setFunnelMetaData } = this.props;

      this.setState({ ...DEFAULT_FUNNELCAKE_STATE, disabled: true });

      const params = this.saveToServerParams();

      // TODO: Should we throw up a spinner when making an ajax request?
      return Promise.resolve(saveToServer(params, setFunnelMetaData)).then(
        () => {
          Promise.allSettled(
            notifyStepCompletedHandlers.map((notifyFunction) =>
              notifyFunction({
                flow,
                step,
                state: stateQuerier,
                url: window.location.href,
              })
            )
          ).then(() => {
            this.navigateToNext();
          });

          if (reenableUponComplete) {
            this.setState({ disabled: false });
          }
        },
        (error) => {
          if (error.body && error.body.reload) {
            this.reloadStep();
          } else {
            // if server throws a validation error, report it to the user
            try {
              handleSaveToServerError(error);
            } catch (localError) {
              if (localError instanceof LendingHomeValidationError) {
                this.setState({ isValid: false, invalidMessage: localError.message, disabled: false });
                return;
              }
              throw localError;
            }
            // if handleSaveToServerError didn't throw, fallback to genericErrorHandler
            genericErrorHandler(error);
          }
        }
      );
    };

    render() {
      const { flow, step, value, stateQuerier } = this.props;

      return (
        <InputForm
          {...this.props}
          flow={flow}
          step={step}
          flowName={stateQuerier.flowName()}
          onChange={this.storeStepValue}
          onComplete={this.completeStep}
          onSubmit={this.completeStep}
          value={value}
          disabled={this.state.disabled}
          isValid={this.state.isValid}
          invalidMessage={this.state.invalidMessage}
          navigateToPrevious={this.navigateToPrevious}
          navigateToNext={this.navigateToNext}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    value: getFunnelSteps(state)[ownProps.step],
    furthestStepVisited: furthestStepVisited(state),
    previousStepVisited: previousStepVisited(state),
  });

  const mapDispatchToProps = (dispatch) => ({
    setFunnelStepValue: ({ step, value }) => dispatch(actions.setFunnelStepValue({ step, value })),
    setFunnelMetaData: ({ key, value }) => dispatch(actions.setFunnelMetaData({ key, value })),
  });

  FunnelCakeComponent.defaultProps = {
    value: undefined,
    furthestStepVisited: undefined,
    previousStepVisited: undefined,
  };

  FunnelCakeComponent.propTypes = {
    flow: string.isRequired,
    step: string.isRequired,
    value: any,
    stateQuerier: object.isRequired,
    furthestStepVisited: object,
    setFunnelStepValue: func.isRequired,
    setFunnelMetaData: func.isRequired,
    previousStepVisited: object,
    handleSaveToServerError: func,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(FunnelCakeComponent);
}
