import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import RatioInput from './RatioInput';
import MaskedNumberInput from './MaskedNumberInput';

const { bool } = PropTypes;

function MoneyInput({
  showRatio,
  isBigMoney = false,
  useMobileNumberKeyboard = false,
  ...props
}) {
  const maskOptions = isBigMoney
    ? {}
    : { allowDecimal: true, allowNegative: props.allowNegative };
  const numberMask = createNumberMask(maskOptions);
  const additionalOptions = {};

  // If you use this, you'll have to include the "novalidate" attribute on your html form, otherwise you'll get an
  // error message. This is because the regex only matches numbers but we're providing a masked string with other chars
  if (isBigMoney && useMobileNumberKeyboard) {
    additionalOptions.pattern = '\\d*';
  }

  let value = props.value;
  // bigMoney numbers have no decimals, so we have to round the value before passing it
  // to the MaskedNumberInput. If it isn't rounded, then it would display weirdly as the decimal
  // would be stripped from it, so something like 100.80 would turn into 10800, not 100.
  if (isBigMoney && value) {
    value = Math.round(value);
  }

  // Using pattern attr here to trigger the large phone style input
  // on mobile devices. Only when the input does not accept fractional numbers.
  // This keyboard style does not provide a decimal key
  // https://stackoverflow.com/questions/25425181/iphone-ios-presenting-html-5-keyboard-for-postal-codes
  // noValidate tells the browser not to try and validate the pattern attr
  // from the input
  const input = (
    <MaskedNumberInput
      {...props}
      {...additionalOptions}
      mask={numberMask}
      value={value}
      noValidate
      autoComplete="off"
    />
  );

  if (showRatio) {
    return (<RatioInput
      {...props}
      input={input}
      disableValidations
    />);
  }

  return input;
}

MoneyInput.defaultProps = {
  allowNegative: false,
};

MoneyInput.propTypes = {
  isBigMoney: bool,
  showRatio: bool,
  allowNegative: bool,
};

export default MoneyInput;
