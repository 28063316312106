import { buildRentalPortfolioUrl } from '../shared';

export function updateAddress(loanId, addressId) {
  return `/loans/${loanId}/addresses/${addressId}`;
}

export function bridgeRateCalculator(loanId) {
  return `/rate-calculator/${loanId}/hard-money`;
}

export function infillRateCalculator(loanId) {
  return `/rate-calculator/${loanId}/bridge-new-construction`;
}

export function checkFatalFlaws(loanId) {
  return `/tof/${loanId}/check_fatal_flaws`;
}

export function editLoan(loanId) {
  return `/loans/${loanId}/edit`;
}

export function rentalPortfolio() {
  return buildRentalPortfolioUrl(gon.MARKETING_SITE);
}
