import React, { Component } from 'react';
import { Input, MoneyInput } from '@lendinghome/core/components';
import FormatMoney, {
  bigMoneyFormat,
} from '@lendinghome/core/components/FormatMoney';
import PropTypes from 'prop-types';
import RCSlider from 'rc-slider';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

const {
  number,
  oneOf,
  oneOfType,
  bool,
  string,
  shape,
  object,
  func,
} = PropTypes;

// A slider Component

const DIRECTION = {
  incrementing: 1,
  decrementing: 2,
};

export default class Slider extends Component {
  constructor(props) {
    super(props);
    const {
      defaultValue,
      incrementUnits,
      incrementInterval,
      minValue,
      ceiling,
      floor,
      range,
      onChange,
      throttleWait,
    } = props;

    let { maxValue } = props;

    // Some very basic input validations
    this.validate(props);

    if (!maxValue) {
      maxValue = defaultValue * 2;
    }

    const median = this.calculateSliderMedian([maxValue || 100, minValue || 0]);

    this.onSliderValueChange = this.onSliderValueChange.bind(this);
    this.beginInfiniteMode = this.beginInfiniteMode.bind(this);
    this.onSliderHandleRelease = this.onSliderHandleRelease.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);

    this.state = {
      sliderValue: Number(defaultValue),
      infiniteValue: defaultValue,
      activeSliderLabel: this.getActiveSliderLabel(defaultValue),
      originalMinValue: minValue || 0,
      originalMaxValue: maxValue || median * 2,
      currentMinValue: minValue || 0,
      currentMaxValue: maxValue || median * 2,
      ceiling: isNil(ceiling) ? Infinity : ceiling,
      floor: isNil(floor) ? -Infinity : floor,
      incrementUnits: incrementUnits || 100,
      incrementInterval: incrementInterval || 10,
      range: range || median * 2,
      isInfiniteMode: false,
      dragging: false,
    };

    if (typeof onChange === 'function' && throttleWait > 0) {
      this.throttledOnChange = throttle(onChange, throttleWait, {
        leading: true,
      });
    }
  }

  validate(props) {
    if (isNil(props.defaultValue) && isNil(props.minValue && props.maxValue)) {
      Frogger.error(
        new Error(
          'You must specify either defaultValue or both minValue and maxValue on a slider instance'
        )
      );
    }
    if (props.minValue > props.maxValue) {
      Frogger.error(new Error('Slider minValue must be less than maxValue'));
    }
  }

  recomputeMinMaxValues(newMedian, currentState) {
    const {
      floor,
      ceiling,
      range,
      originalMinValue,
      originalMaxValue,
    } = currentState;
    const isWithinOriginalRange =
      newMedian < originalMaxValue && newMedian > originalMinValue;

    if (isWithinOriginalRange) {
      return {
        currentMaxValue: originalMaxValue,
        currentMinValue: originalMinValue,
      };
    }

    const newMaximum = Math.min(newMedian + range, ceiling);
    const newMinimum = Math.max(newMaximum - range * 2, floor);

    return {
      currentMaxValue: newMaximum,
      currentMinValue: newMinimum,
    };
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  beginInfiniteMode(direction, startingValue) {
    const { incrementInterval, incrementUnits, ceiling, floor } = this.state;
    const incrementing = direction === DIRECTION.incrementing;
    let currentInterval = 1;

    this.props.onEnterInfiniteMode &&
      this.props.onEnterInfiniteMode(this.props.name);

    this.setState(
      (currentState) => ({
        ...currentState,
        infiniteValue: startingValue,
        isInfiniteMode: true,
        sliderValue: incrementing
          ? currentState.currentMaxValue
          : currentState.currentMinValue,
      }),
      () => {
        const startTimeout = (iterations = 1) => {
          this.timeout = setTimeout(() => {
            const { infiniteValue } = this.state;
            const proposedValue = incrementing
              ? Math.min(infiniteValue + incrementUnits * iterations, ceiling)
              : Math.max(infiniteValue - incrementUnits * iterations, floor);

            this.setState(() => ({
              activeSliderLabel: proposedValue,
              infiniteValue: proposedValue,
            }));

            this.onChange(proposedValue);
            currentInterval = Math.max(500 / iterations, incrementInterval);

            if (this.state.isInfiniteMode) {
              startTimeout(iterations + 1);
            }
          }, currentInterval);
        };

        startTimeout();
      }
    );
  }

  getActiveSliderLabel(value) {
    const { marks, field } = this.props;
    if (field.displayMarks && marks && marks[value]) {
      return marks[value];
    }
    return value;
  }

  onChange(inputValue) {
    let value = inputValue;

    const {
      onChange,
      field: { displayMarks },
      marks,
    } = this.props;

    // When we are displaying the value for the mark, we ought to dispatch
    // the onChange event with that value
    if (displayMarks && !isEmpty(marks)) {
      value = marks[value];
    }

    if (typeof this.throttledOnChange === 'function') {
      this.throttledOnChange(value);
    } else if (typeof this.onChange === 'function') {
      setTimeout(() => {
        onChange(value);
      }, 50);
    }
  }

  onSliderHandleRelease(val) {
    // Slider handle was just released while in *infinite* mode
    if (this.state.isInfiniteMode) {
      this.onChange(this.state.infiniteValue);
      // recompute the max and min if in *infinite* mode, when the slider handle is dropped
      this.setState((currentState) => ({
        ...currentState,
        ...this.recomputeMinMaxValues(currentState.infiniteValue, currentState),
        sliderValue: currentState.infiniteValue,
        isInfiniteMode: false,
        dragging: false,
      }));
    } else {
      this.onChange(val);
      this.setState((currentState) => ({
        ...currentState,
        sliderValue: Number(val),
        infiniteValue: currentState.sliderValue,
        isInfiniteMode: false,
        dragging: false,
      }));
    }
  }

  onSliderValueChange(val) {
    const { floor, ceiling, currentMaxValue, currentMinValue } = this.state;
    const { isInfinite } = this.props;

    if (val > ceiling || val < floor) {
      return;
    } else if (isInfinite && val >= currentMaxValue && val < ceiling) {
      // Infinite mode - incrementing
      this.beginInfiniteMode(DIRECTION.incrementing, val);
    } else if (isInfinite && val <= currentMinValue && val > floor) {
      // Infinite mode - decrementing
      this.beginInfiniteMode(DIRECTION.decrementing, val);
    } else {
      this.setState((currentState) => ({
        ...currentState,
        sliderValue: Number(val),
        activeSliderLabel: this.getActiveSliderLabel(val),
        isInfiniteMode: false,
        dragging: true,
      }));
    }

    this.onChange(val);
  }

  getLabelForField(val) {
    const { labels } = this.props;
    if (labels && labels[val]) {
      return labels[val];
    }
  }

  getBigMoneyFormatLength(value) {
    return bigMoneyFormat.format(value).length;
  }

  generateMarks() {
    const { currentMinValue, currentMaxValue } = this.state;
    const { dataType, drawMedian, marks, nomarks } = this.props;

    if (marks) return marks;

    // If marks not specified, generate them automatically
    const returnVal = {};
    const median = this.calculateSliderMedian([
      currentMinValue,
      currentMaxValue,
    ]);
    const isMoney = dataType === 'Money';
    const formatMoney = (val) => (<FormatMoney
      isBigMoney
      value={val}
    />);
    const formatLabel = (val) =>
      (isMoney ? formatMoney(currentMinValue) : `${val}`);

    if (nomarks) {
      // do not display labels on marks
      return {
        [currentMinValue]: '',
        [currentMaxValue]: '',
      };
    }

    returnVal[currentMinValue] = formatLabel(currentMinValue);
    returnVal[currentMaxValue] = formatLabel(currentMaxValue);

    if (drawMedian) {
      returnVal[median] = formatLabel(median);
    }

    return returnVal;
  }

  calculateSliderMedian(numbers) {
    const numsLen = numbers.length;
    numbers.sort();
    if (numsLen % 2 === 0) {
      return (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
    }
    return numbers[(numsLen - 1) / 2];
  }

  onFieldChange(val) {
    const { floor, ceiling } = this.state;

    // Reject the state update if the value exceeds our floor/ceiling
    if (val < floor || val > ceiling) {
      return false;
    }

    this.setState((currentState) => {
      if (
        val > currentState.currentMaxValue ||
        val < currentState.currentMinValue
      ) {
        // recompute the max, min, and median when user enters a value which
        // exceeds the current maxValue or is less than the current minValue
        return {
          ...currentState,
          ...this.recomputeMinMaxValues(val, currentState),
          sliderValue: Number(val),
          activeSliderLabel: val,
        };
      }

      return {
        ...currentState,
        sliderValue: Number(val),
        activeSliderLabel: val,
      };
    });

    this.onChange(val);
  }

  render() {
    const {
      currentMaxValue,
      currentMinValue,
      floor,
      ceiling,
      isInfiniteMode,
      dragging,
      sliderValue,
      activeSliderLabel,
    } = this.state;
    const {
      dataType,
      isSegmented,
      field,
      incrementUnits,
      isInfinite,
      fieldsetClassName,
      minValErrorMessage,
      maxValErrorMessage,
      errorMessageContainerClassName,
      useMobileNumberKeyboard,
      tabIndex,
    } = this.props;

    const className = classnames('lh-slider', this.props.className, {
      'is-infinite-mode': isInfiniteMode,
      'is-slider-dragging': dragging,
    });
    const fieldVisible = !(field.visible === false);
    const fieldLabel = this.getLabelForField(sliderValue);
    const labelClass = fieldLabel
      ? `is-badge-${fieldLabel.toLowerCase()}`
      : null;
    const maximumConstraint = isInfinite ? ceiling : currentMaxValue;
    const minErrorConfig = minValErrorMessage
      ? { minValue: floor, message: minValErrorMessage }
      : { minValue: floor };
    const maxErrorConfig = maxValErrorMessage
      ? { maxValue: ceiling, message: maxValErrorMessage }
      : { maxValue: ceiling };

    const inputProps = { useMobileNumberKeyboard };

    if (dataType === 'Money') {
      inputProps.isBigMoney = true;
      if (maximumConstraint !== Infinity) {
        inputProps.maxLength = this.getBigMoneyFormatLength(maximumConstraint);
      }
    } else if (maximumConstraint !== Infinity) {
      inputProps.maxLength = maximumConstraint.toString().length;
    }

    return (
      <div className={className}>
        {fieldVisible && (
          <div>
            {field.readOnly ? (
              <div>
                <div className="planner--widget-value">{activeSliderLabel}</div>
                <div className={classnames('badge', labelClass)}>
                  {fieldLabel}
                </div>
              </div>
            ) : (
              <section>
                <Input
                  isFloatInput={false}
                  type="text"
                  debounceOnChange={false}
                  onChange={this.onFieldChange}
                  value={activeSliderLabel}
                  minValue={minErrorConfig}
                  maxValue={maxErrorConfig}
                  fieldsetClassName={fieldsetClassName}
                  errorMessageContainerClassName={
                    errorMessageContainerClassName
                  }
                  inputComponent={
                    dataType === 'Money' ? (
                      <MoneyInput {...inputProps} />
                    ) : (
                      <input
                        type="number"
                        {...inputProps}
                      />
                    )
                  }
                  {...field}
                />
              </section>
            )}
          </div>
        )}
        <section className="slider">
          <RCSlider
            className={isSegmented ? 'is-segmented' : ''}
            defaultValue={sliderValue}
            value={sliderValue}
            min={currentMinValue}
            max={currentMaxValue}
            step={isSegmented ? null : incrementUnits}
            marks={this.generateMarks()}
            onChange={this.onSliderValueChange}
            onAfterChange={this.onSliderHandleRelease}
            railStyle={{}}
            trackStyle={{}}
            handleStyle={{}}
            dotStyle={{}}
            activeDotStyle={{}}
            tabIndex={tabIndex || 0}
          />
        </section>
      </div>
    );
  }
}

Slider.defaultProps = {
  className: null,
  minValue: null,
  maxValue: null,
  floor: null,
  ceiling: null,
  range: null,
  defaultValue: null,
  isInfinite: false,
  incrementUnits: 1,
  incrementInterval: null,
  dataType: 'Number',
  isSegmented: false,
  useMobileNumberKeyboard: false,
  marks: null,
  nomarks: false,
  drawMedian: false,
  field: { visible: true, readOnly: false, throttleWait: 0, required: true },
  onEnterInfiniteMode: null,
  throttleWait: null,
};

Slider.propTypes = {
  className: string,
  /** the starting minimum value for the slider (optional, but both minValue and maxValue MUST be set if no defaultValue is set. Defaults to 0 if not set) */
  minValue: number,
  /** the starting maximum value for the slider (optional, if not specified defaults to defaultValue * 2) */
  maxValue: number,
  /** When in 'Infinite' mode, the slider value can not decrement below the floor value */
  floor: number,
  /** When in 'Infinite' mode, the slider value can not increment past the ceiling value */
  ceiling: number,
  /** Total number of units that the slider can span (ex: min:0, median: 50, max:100, range:100) */
  range: number,
  /** The starting position/value for the slider (optional, but must be set if minValue and maxValue are not set) */
  defaultValue: oneOfType([string, number]),
  /** Whether to enable infinite mode (infinite mode allows the slider to move past the defined minValue/maxValue, but constrained by floor/ceiling) */
  isInfinite: bool,
  /** Units used when incrementing/decrementing the slider value * */
  incrementUnits: number,
  /** the interval used when incrementing/decrementing the slider value when in infinite mode (in milliseconds) */
  incrementInterval: number,
  /** Whether the input field displays as money or as a plain number */
  dataType: oneOf(['Money', 'Number']),
  /** Switched the slider into 'segmented' mode, like the FICO slider on Homeplanner */
  isSegmented: bool,
  /** sliders have inputs on them, so this is to specify the input's keyboard behavior */
  useMobileNumberKeyboard: bool,
  /** Defines the marks/labels which display just below the slider */
  marks: shape({}),
  /** When set, disables the displaying of marks below the slider */
  nomarks: bool,
  /** whether or not to draw the median in the slider marks (defaults to false) */
  drawMedian: bool,
  /** { visible: bool, readOnly: bool, labels: object } allows control of field/input visibility, editability, and labels */
  field: shape({ visible: bool, readOnly: bool, labels: object }),
  /** A calback which is invoked when the slider enters infinite mode */
  onEnterInfiniteMode: func,
  /** used if you want to throttle the slider handles onChange */
  throttleWait: number,
};
