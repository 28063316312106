import React from 'react';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

export default function SvgIcon({ icon, className, style, source }) {
  const cache = get(window, 'lendinghome', { icons: {} });
  let markup = cache[icon];

  // Failing to load/parse an SVG should not cause the app to crash
  try {
    if (isNil(markup)) {
      if (!isNil(source)) {
        // allow the svg source to be specified manually
        markup = atob(source.split(',')[1]);
      } else {
        const dataURI = require(`../../../../client/icons/${icon}.svg`);
        markup = atob(dataURI.split(',')[1].replace('&quot;', ''));
      }
      cache.icons[icon] = markup;
      window.lendinghome = cache;
    }
  } catch (e) {
    if (Frogger) {
      Frogger.error(new Error('Error loading SVG asset', e), {
        icon,
        className,
        style,
      });
    } else {
      throw e;
    }
  }

  const iconClasses = classnames('icon', `icon--${icon}`, className);
  return (<i
    className={iconClasses}
    dangerouslySetInnerHTML={{ __html: markup }}
    style={style}
  />);
}
