import LendingHomeError from './lendingHomeError';

// << LendingHomeAPIError >>
// Throwing LendingHomeAPIError indicates that a server API returned an error response.
// You MUST populate the `options` object with the api response object, so that consumers
// can implement determinative behavior based on response code.
export default class LendingHomeAPIError extends LendingHomeError {
  constructor(...args) {
    super(...args);
    this.name = 'LendingHomeAPIError';

    if (typeof this.options === 'object' && this.options.response) {
      const {
        ok,
        redirected,
        status,
        statusText,
        type,
        url,
        bodyUsed,
      } = this.options.response;
      this.response = this.options.response;
      this.data.push({
        response: {
          ok,
          redirected,
          status,
          statusText,
          type,
          url,
          bodyUsed,
        },
      });
    } else if (window.Frogger) {
      Frogger.warn(
        'LendingHomeAPIError constructor must be called with a response object!'
      );
    } else if (window.console && window.console.warn) {
      window.console.warn(
        'LendingHomeAPIError constructor must be called with a response object!'
      );
    }
  }
}
