import React from 'react';
import emailValidator from '../validators/emailValidator';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';

function EmailInput({ onChange, onBlur, value, disabled, htmlFor, className, placeholder, ...rest }) {
  return (
    <input
      placeholder={placeholder}
      type="email"
      autoCapitalize="off"
      name={htmlFor}
      id={htmlFor}
      maxLength="256"
      className={className}
      autoComplete="on"
      onChange={onChange}
      onBlur={onBlur}
      value={value || ''}
      disabled={disabled}
      {...rest}
    />
  );
}

const VerifyingEmailInput = CreateVerifyingTextField({
  Input: EmailInput,
  validator: emailValidator,
  defaultInvalidMessage: 'Please enter a valid email',
});

VerifyingEmailInput.validator = emailValidator;

export default VerifyingEmailInput;
