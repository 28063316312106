import { get as dig } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import splitLocalhostTreatments from '../../../../gems/core/config/split_localhost_treatments.yaml';

const makeSplitConfig = () => {
  const authKey = dig(window, 'env.SPLIT_AUTHORIZATION_KEY');
  const isSplitLocalHostMode = authKey === 'localhost';

  let key = dig(window, 'gon.analytics.identity.user_token', null);
  if (!key) {
    key = dig(window, 'gon.user_context.user_token', '');
  }
  let features;

  if (isSplitLocalHostMode) {
    features = {
      features: Object.assign(
        {},
        ...splitLocalhostTreatments.map((feature) => {
          const [[splitName, value]] = Object.entries(feature);
          return {
            [splitName]: value.treatment,
          };
        })
      ),
    };

    /* The client SDK assumes a single connected user.
     * Because the server is also a single connected user in localhost mode (you),
     * we just grab the first entry's key
     */
    const [[, { keys }]] = Object.entries(splitLocalhostTreatments[0]);
    key = keys;
  }

  return {
    core: {
      authorizationKey: authKey,
      key,
    },
    ...features,
    impressionListener: {
      logImpression(impressionData) {
        datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
      },
    },
  };
};

const splitConfig = makeSplitConfig();

export default splitConfig;
