//  __
// / _|
// | |_ _ __ ___   __ _  __ _  ___ _ __
// |  _| '__/ _ \ / _` |/ _` |/ _ \ '__|
// | | | | | (_) | (_| | (_| |  __/ |
// |_| |_|  \___/ \__, |\__, |\___|_|
//               __/ | __/ |
//              |___/ |___/
//
//     _    _
//    (o)--(o)
//   /.______.\
//   \________/
//  ./        \.
// ( .        , )
//  \ \_\\//_/ /
//   ~~  ~~  ~~

/* Frogger /frɔɡ-ger/(noun): a FROnt-end loGGER */

/* /// PUBLIC INTERFACE ///

  Frogger.fatal(error, data, tags) - logs a fatal/critical error (tags and data are optional)

  Frogger.error(error, data, tags) - logs a standard error (tags and data are optional)

  Frogger.warn(message, data) - logs a warning message to the console (data is optional)

  Frogger.info(message, data) - logs an informational message to the console (data is optional)

  Frogger.debug(message, data) - logs a debug message to the console (data is optional)

  Frogger.value(data) -- logs an object to the console, ** and returns the object to the caller **

  Frogger.log(message, data) — just an alias for ‘debug' (data is optional)

  Frogger.breadcrumb(message, data) - logs a breadcrumb an associated data to Sentry

  Frogger.deprecation(message) - writes a deprecation warning to the console (when console logging is enabled)

  Frogger.rethrowErrors(bool) — causes Frogger to rethrow any error passed to .fatal or .error

  Frogger.enableThirdParties(bool) — passing `true` enables logging to Sentry and Fullstory

  Frogger.logToConsole(bool) -- passing `true` enables console logging (useful for debugging in Production)

  Frogger.showConfig() -- returns the current Frogger configuration

*/

/* eslint-disable no-console */

const reduxLoggerFilter = /%c?\s(prev\sstate|action|next\sstate)|——\slog\send\s——/;

const consoleStyles = {
  debug: 'color: #000000; background-color: #90d19f;',
  info: 'color: #000000; background-color: #67a3f7;',
  warning: 'color: #000000; background-color: #f99d57;',
  error: 'color: #000000; font-weight: boldest; background-color: #f95757;',
  fatal: 'color: #000000; font-weight: boldest; font-size: 28px; background-color: #ff0000;',
  deprecation: 'color: #000000; font-weight: boldest; font-size: 36px; background-color: #ff0000;',
};

const noop = () => undefined;

const sentry = window.Sentry;

const sentryExists = () => !!(window.SentryInitializer && window.SentryInitializer.installed);

const integrations = {};

const config = {
  enableThirdParties: true,
  rethrowErrors: true,
  logToConsole: true,
  initialized: false,
};

/* Safely attach to the appropriate console for this environment */
const attachConsole = (key) => {
  if (console && typeof console[key] === 'undefined') {
    // fallback to console.log
    return console.log ? console.log : noop;
  } else if (console && console[key] && typeof console[key] === 'function') {
    return console[key];
  }
  return noop;
};

// Copy all console methods to Frogger so it can be used as a drop-in replacement for window.console
const captureConsoleMethods = (frogger) => {
  try {
    const props = Object.getOwnPropertyNames(window.console);
    props.forEach((prop) => {
      if (typeof window.console[prop] === 'function' && !frogger[prop]) {
        frogger[prop] = window.console[prop];
      }
    });
  } catch (e) {
    // do nothing
  }
};

// These map to the Sentry severity definitions at https://docs.sentry.io/clientdev/attributes/
/* eslint no-console: ["error", { allow: ["warn", "error", "debug", "info", "log"] }] */
const SEVERITY = {
  deprecation: {
    key: 'deprecation',
    priority: 0,
    console: attachConsole('info'),
    styling: consoleStyles.deprecation,
    integrations: [],
  },
  debug: {
    key: 'debug',
    priority: 0,
    console: attachConsole('debug'),
    styling: consoleStyles.debug,
    integrations: [
      function fullstory(message) {
        integrations.Fullstory && integrations.Fullstory('debug', message);
      },
    ],
  },
  info: {
    key: 'info',
    priority: 1,
    console: attachConsole('info'),
    styling: consoleStyles.info,
    integrations: [
      function fullstory(message) {
        integrations.Fullstory && integrations.Fullstory('info', message);
      },
    ],
  },
  warning: {
    key: 'warning',
    priority: 2,
    console: attachConsole('warning'),
    styling: consoleStyles.warning,
    integrations: [
      function fullstory(message) {
        integrations.Fullstory && integrations.Fullstory('warn', message);
      },
    ],
  },
  error: {
    key: 'error',
    priority: 3,
    console: attachConsole('error'),
    styling: consoleStyles.error,
    integrations: [
      function sentryFunc(error, ...args) {
        integrations.Sentry && integrations.Sentry(error, ...args);
      },
      function fullstoryFunc(error) {
        integrations.Fullstory && integrations.Fullstory('error', error);
      },
    ],
  },
  fatal: {
    key: 'fatal',
    priority: 4,
    console: attachConsole('error'),
    styling: consoleStyles.fatal,
    integrations: [
      function sentryFunc(error, ...args) {
        integrations.Sentry && integrations.Sentry(error, ...args);
      },
      function fullstoryFunc(error) {
        integrations.Fullstory && integrations.Fullstory('error', error);
      },
    ],
  },
};

/* Private methods */

const addIntegrations = () => {
  if (config.enableThirdParties) {
    // Add Sentry integration
    if (sentryExists()) {
      integrations.Sentry = sentry.captureException.bind(sentry);
    }
    // Add FullStory and other integrations
    integrations.Fullstory = (type, message) => {
      // We can't pre-bind the FS.log method because it is not always present
      // when Frogger initializes
      if (window.FS && window.FS.log) {
        window.FS.log(type, message);
      }
    };
  }
  integrations.consoleGroup = attachConsole('group');
  integrations.consoleGroupEnd = attachConsole('groupEnd');
  integrations.consoleGroupCollapsed = attachConsole('groupCollapsed');
};

const rethrowError = (e) => {
  if (config.rethrowErrors) {
    try {
      if (typeof e === 'object' && e !== undefined && typeof e.froggerHandled !== 'boolean') {
        e.froggerHandled = true;
      }
    } catch (ex) {
      // do nothing
    }

    throw e;
  }
};

// Handles logging of informational messages to third parties (types other than 'error' or 'fatal')
// when console logging is turned off
const logBreadcrumb = (message, data, severity = SEVERITY.debug) => {
  try {
    if (!reduxLoggerFilter.test(message)) {
      // special handling for Sentry (for now)
      if (sentryExists() && sentry.addBreadcrumb) {
        sentry.addBreadcrumb({
          type: 'frogger',
          category: severity.key,
          message,
          data,
        });
      }
    }
  } catch (e) {
    config.logToConsole && console && console.error && console.error(e);
  }
};

const logToConsole = (message, severity = SEVERITY.debug, data, tags) => {
  try {
    if (config && config.logToConsole) {
      try {
        // Since data is sometimes passed as the first/only param, handle that
        if (typeof message === 'object' && !(message instanceof Error)) {
          severity.console('%c[Frogger]:', severity.styling);
          integrations.consoleGroupCollapsed('%c%s', severity.styling, 'data:');
          severity.console(message);
          integrations.consoleGroupEnd();
        } else {
          integrations.consoleGroup(`%c[Frogger]:${message}`, severity.styling);
          if (message.stack) {
            integrations.consoleGroupCollapsed('%c%s', severity.styling, 'stacktrace:');
            severity.console(message.stack);
            integrations.consoleGroupEnd();
          }
          if (data) {
            integrations.consoleGroupCollapsed('%c%s', severity.styling, 'data:');
            severity.console(data);
            integrations.consoleGroupEnd();
          }
          if (tags) {
            integrations.consoleGroupCollapsed('%c%s', severity.styling, 'tags:');
            severity.console(tags);
            integrations.consoleGroupEnd();
          }
          integrations.consoleGroupEnd();
        }
      } catch (e) {
        // do nothing
      }
    } else if (severity.priority < SEVERITY.error.priority) {
      /* Attempt to log messages to Sentry as breadcrumbs when console logging is disabled
      Fatal and Error types are already logged to Sentry, so try and avoid duplicates here */
      logBreadcrumb(message, data, severity);
    }
  } catch (e) {
    config.logToConsole && console && console.error && console.error(e);
  }
};

const logToThirdParties = (error, severity = SEVERITY.debug, data = {}, tags = {}) => {
  if (config.enableThirdParties) {
    let browserData;
    try {
      //  Get the current browser viewport dimensions
      browserData = {
        viewportHeight: window.innerHeight,
        viewportWidth: window.innerWidth,
      };
    } catch (e) {
      // do nothing
    }

    tags.loggedBy = 'Frogger';
    severity.integrations.forEach((integration) => {
      integration(error, {
        level: severity.key,
        tags,
        extra: { froggerConfig: config, browserData, data },
      });
    });
  }
};

// Aggregate all the various possible data-related fields into one object
const combineErrorFields = (errorData, data = {}) => {
  let newdata;
  try {
    /* if data is attached to the error, merge it with any existing data */
    newdata = errorData instanceof Array ? Object.assign(data, ...errorData) : Object.assign(data, errorData);
  } catch (e) {
    // do nothing
  }
  return newdata;
};

const logCustomData = () => {
  try {
    if (window.FS && typeof window.FS.getCurrentSessionURL === 'function') {
      return { fullstory: { url: window.FS.getCurrentSessionURL(true) } };
    }
  } catch (e) {
    return { fullstory: { error: 'Error calling FS.getCurrentSessionURL()' } };
  }
  return {};
};

const logCustomTags = () => {
  const returnval = {};
  try {
    if (window.gon) {
      Object.assign(returnval, {
        leadId: gon.leadId || '',
        flowName: gon.default_flow_name || '',
      });
    }

    if (window.LH && window.LH.router) {
      Object.assign(returnval, { variant: window.LH.router.service || '' });
    }
  } catch (e) {
    // do nothing
  }
  return returnval;
};

const logError = (error, data, tags, severity) => {
  try {
    const combinedData = Object.assign({}, combineErrorFields(error.data, data), logCustomData());
    const combinedTags = Object.assign({}, combineErrorFields(error.tags, tags), logCustomTags());
    /* If this is an error string, convert it to an error object */
    if (typeof error === 'string') {
      error = new Error(error);
    }

    logToThirdParties(error, severity, combinedData, combinedTags);
    logToConsole(error, severity, combinedData, combinedTags);
  } catch (e) {
    config.logToConsole && console && console.error && console.error(e);
  }
};

const notifyLoaded = () => {
  logToConsole('RiBBit...', SEVERITY.debug, config);
};

/* Frogger Class definition */

class Frogger {
  constructor(configuration) {
    try {
      Object.assign(config, configuration);
      addIntegrations();
      captureConsoleMethods(this);
      config.initialized = true;
      notifyLoaded();
    } catch (e) {
      config.logToConsole && console && console.log && console.log('A FRoGGER initialization error occurred');
    }
  }

  // log a Fatal error (only use for the most critical errors)
  fatal(error, data = {}, tags = {}) {
    // Prevent errors from *re-entering* the logging code if they've already been handled by Frogger
    if (error && typeof error.froggerHandled !== 'boolean') {
      if (typeof error === 'object') {
        try {
          error.fatal = true;
        } catch (e) {
          // do nothing
        }
        logError(error, data, tags, SEVERITY.fatal);
      }
    }

    rethrowError(error); // Fatal errors are *always* rethrown in ALL environments
  }

  error(error, data = {}, tags = {}) {
    if (error && typeof error.froggerHandled !== 'boolean') {
      logError(error, data, tags, SEVERITY.error);
      rethrowError(error);
    } else if (config.rethrowErrors || error.fatal) {
      rethrowError(error);
    }
  }

  warn(message, data) {
    logToConsole(message, SEVERITY.warning, data);
  }

  info(message, data) {
    logToConsole(message, SEVERITY.info, data);
  }

  debug(message, data) {
    logToConsole(message, SEVERITY.debug, data);
  }

  value(data) {
    logToConsole(data, SEVERITY.debug);
    return data;
  }

  log(message, data) {
    logToConsole(message, SEVERITY.debug, data);
  }

  deprecation(message, data) {
    logToConsole(`DEPRECATION WARNING: ${message}`, SEVERITY.deprecation);
  }

  ping() {
    try {
      const stack = new Error().stack;
      const lines = stack.split('\n');

      if (lines && lines.length && lines.length > 0) {
        let formattedMessage = lines[2];

        if (formattedMessage) {
          formattedMessage = `%cPING!: ${formattedMessage.trim().replace('at ', '')}`;
        }

        logToConsole(formattedMessage, SEVERITY.info, 'color: green; font-weight: bold; font-size: 1.25rem;');
      }
    } catch (e) {
      // do nothing
    }
  }

  breadcrumb(message, data, severity = SEVERITY.debug) {
    // special handling of calls made via 'yield'
    if (typeof message === 'object' && message.message && message.level) {
      data = message.data;
      severity = message.level;
      message = message.message;
    }
    logBreadcrumb(message, data, severity);
  }

  isInitialized() {
    return config.initialized;
  }

  showConfig() {
    return config;
  }

  /* Publicly configurable options */

  logToConsole(val = true) {
    config.logToConsole = val;
    return `logToConsole now set to ${config.logToConsole}`;
  }

  rethrowErrors(val = true) {
    config.rethrowErrors = val;
    return `rethrowErrors now set to ${config.rethrowErrors}`;
  }
}

export default Frogger;
