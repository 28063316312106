import React from 'react';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';
import FunnelCake from '../FunnelCake';
import SlideInfo from '../SlideInfo';
import GetRateFullScreen from '../GetRateFullScreen';
import { borrowerFirstName, borrowerEmail, userIsLoggedIn } from '../../selectors';
import EmptyFunnelStepForm from '../EmptyFunnelStepForm';

class CheckYourEmailFullScreen extends React.Component {
  componentDidMount() {
    const { userLoggedIn, onComplete } = this.props;

    if (userLoggedIn) {
      onComplete();
    }
  }

  render() {
    const { firstName, email } = this.props;

    return (
      <GetRateFullScreen headline={`Thanks ${firstName}, check your email to continue.`}>
        <EmptyFunnelStepForm
          {...this.props}
          hideBackButton
          hideNextButton
        >
          <SlideInfo>We sent an email to {email}. Please follow the link to move forward.</SlideInfo>
        </EmptyFunnelStepForm>
      </GetRateFullScreen>
    );
  }
}

CheckYourEmailFullScreen.propTypes = {
  firstName: string,
  email: string,
  userLoggedIn: bool,
};

const mapStateToProps = (state) => ({
  firstName: borrowerFirstName(state),
  email: borrowerEmail(state),
  userLoggedIn: userIsLoggedIn(state),
});

const CheckYourEmail = FunnelCake({
  InputForm: connect(mapStateToProps)(CheckYourEmailFullScreen),
});

export default CheckYourEmail;
