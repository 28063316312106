import React from 'react';
import { VerticalSelectBar } from '@lendinghome/core/components';
import { string, bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function ExitsLast24Input({ onChange, value, disabled }) {
  const options = [
    {
      label: 'None',
      value: '0',
    },
    {
      label: '1-4 properties',
      value: '1-4',
    },
    {
      label: '5+ properties',
      value: '5+',
    },
  ];

  return (
    <VerticalSelectBar
      options={options}
      fieldName="estimateNumExitsInLastTwentyFourSelectedRange"
      currentValue={`${value}`}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

ExitsLast24Input.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};

ExitsLast24Input.defaultProps = {
  value: '',
  disabled: false,
};

export default FunnelStepForm({
  Input: ExitsLast24Input,
  submitOnChange: true,
});
