export const ADD_FILE = 'file.add';
export const REMOVE_FILE = 'file.remove';
export const UPDATE_FILE_ID = 'file.update_id';
export const UPDATE_FILE_STATUS = 'file.update_status';
export const UPDATE_FILE_PROGRESS = 'file.update_progress';

export const addFile = (id, name, status, statusLabel) => ({
  type: ADD_FILE,
  id,
  name,
  status,
  statusLabel,
});

export const removeFile = (id) => ({
  type: REMOVE_FILE,
  id,
});

export const updateFileId = (id, newId) => ({
  type: UPDATE_FILE_ID,
  id,
  newId,
});

export const updateFileStatus = (id, status, statusLabel) => ({
  type: UPDATE_FILE_STATUS,
  id,
  status,
  statusLabel,
});

export const updateFileProgress = (id, progress) => ({
  type: UPDATE_FILE_PROGRESS,
  id,
  progress,
});

export default {
  addFile,
  removeFile,
  updateFileId,
  updateFileStatus,
  updateFileProgress,
};
