import { createSelector } from 'reselect';
import { LoanProgram, UsStates } from '@lendinghome/core/constants';
import isNil from 'lodash/isNil';

export const getFunnelSteps = (state) => state.funnelSteps;

export const isRentalProgram = createSelector(getFunnelSteps, (steps) => steps.loanProgram === LoanProgram.RENTAL);

export const isRehabTypeInfill = createSelector(
  getFunnelSteps,
  (steps) => steps.loanProgram === LoanProgram.HARD_MONEY_INFILL
);

export const isRentalPortfolio = createSelector(
  getFunnelSteps,
  (steps) => steps.loanProgram === LoanProgram.RENTAL_PORTFOLIO
);

export const isBorrowerPro = createSelector(getFunnelSteps, (steps) => parseInt(steps.exitsLast24, 10) > 4);

export const hasEnoughInfillExperience = createSelector(getFunnelSteps, (steps) => steps.infillExitsLast60 > 1);

export const hasZeroExits = createSelector(getFunnelSteps, (steps) => parseInt(steps.exitsLast24, 10) === 0);

export const borrowerHasEntity = createSelector(getFunnelSteps, (steps) => steps.entity === true);

export const borrowerHoldsRental = createSelector(getFunnelSteps, (steps) => steps.holdsRental === true);

export const hasNoRentalProperties = createSelector(getFunnelSteps, (steps) => parseInt(steps.dealsLast24, 10) === 0);

export const borrowerFirstName = createSelector(
  getFunnelSteps,
  (steps) => (steps.yourName && steps.yourName.firstName) || ''
);

export const propertyStateCode = createSelector(getFunnelSteps, (steps) => {
  if (steps.propertyAddress && steps.propertyAddress.state) {
    return steps.propertyAddress.state;
  } else if (steps.leadPropertyAddress && steps.leadPropertyAddress.state) {
    return steps.leadPropertyAddress.state;
  }
  return steps.propertyState || '';
});

export const borrowerEmail = createSelector(getFunnelSteps, (steps) => (steps && steps.yourEmail) || '');

function phoneE164(phone) {
  if (phone) {
    return `+1${phone}`;
  }
  return phone;
}

// Borrower Phone # in E.164 format
export const borrowerPhone = createSelector(getFunnelSteps, (steps) => (steps && phoneE164(steps.yourPhone)) || '');

export const borrowerSsn = createSelector(getFunnelSteps, (steps) => steps.yourSSN || '');

export const brokersBridgeLoans = createSelector(getFunnelSteps, (steps) => steps.brokerBridge === true);

export const brokersBridgeRentalLoans = createSelector(getFunnelSteps, (steps) => steps.brokerBridgeRental === true);

export const needsStateSpecificLicensing = createSelector(
  getFunnelSteps,
  // TODO: This list should come from the back end
  (steps) => ['AZ', 'CA', 'FL', 'NV', 'NJ', 'NY', 'NC', 'VA'].includes(steps.brokerUsState)
);

export const fundingState = createSelector(getFunnelSteps, (steps) => {
  if (steps.propertyAddress && steps.propertyAddress.state) {
    let USstate = UsStates.find((state) => state.usps_code === steps.propertyAddress.state);
    // exclude US territories
    if (!USstate || USstate.status !== 0) return null;
    return USstate.funding;
  }
  return null;
});

const getFunnelMetadata = (state) => state.funnelMetaData;

export const furthestStepVisited = createSelector(getFunnelMetadata, (metadata) => metadata.furthestStepVisited);

export const previousStepVisited = createSelector(getFunnelMetadata, (metadata) => metadata.previousStepVisited);

export const userEmailExists = createSelector(getFunnelMetadata, (metadata) => metadata.userEmailExists === true);

export const hasLoans = createSelector(getFunnelMetadata, (metadata) => metadata.userHasLoans === true);

export const unsupportedErrorInfoCollected = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.unsupportedErrorInfoCollected === true
);

export const loanId = createSelector(getFunnelMetadata, (metadata) => metadata.loanId);

export const userIsLoggedIn = createSelector(getFunnelMetadata, (metadata) => metadata.userIsLoggedIn);

export const borrowerId = createSelector(getFunnelMetadata, (metadata) => metadata.borrowerId);

export const presentAddressId = createSelector(getFunnelMetadata, (metadata) => metadata.presentAddressId);

export const funnelVisitId = createSelector(getFunnelMetadata, (metadata) => metadata.funnelVisitId);

export const isInfill = createSelector(getFunnelMetadata, (metadata) => metadata.isInfill);

export const propertyStateUnsupported = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.propertyStateUnsupported === true
);

export const propertyAddressProvided = createSelector(
  getFunnelSteps,
  (steps) => steps.leadPropertyAddress && steps.leadPropertyAddress.line1
);

export const propertyStateRequiresEntity = createSelector(
  getFunnelMetadata,
  (metaData) => metaData.entityRequiredInPropertyState === true
);

export const supportedPropertyStatesList = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.supportedPropertyStatesList
);

export const propertyStateUnsupportedForRental = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.propertyStateUnsupportedForRental === true
);

export const failingEligibilityRules = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.failingEligibilityRules || []
);

export const creditHistoryNotFound = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'not_found'
);

export const creditIsFrozen = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'credit_is_frozen'
);

export const creditPullTimeout = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'credit_pull_timeout'
);

export const hasCreditPullRateLimit = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'rate_limited'
);

export const borrowerHasApprovedTofu = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'approved'
);

export const borrowerHasDeclinedTofu = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'declined'
);

export const lhbrNetworkError = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.tofuSubmitResult === 'lhbr_network_error'
);

export const borrowerHasTofuDecision = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.borrowerHasTofuDecision === true
);

export const tofuResubmitHref = createSelector(getFunnelMetadata, (metadata) => metadata.tofuResubmitHref);

const identityData = createSelector(getFunnelMetadata, borrowerEmail, borrowerPhone, (metadata, email, phone) => ({
  ...metadata.analyticsIdentity,
  ...(email && { email }),
  ...(phone && { phone }),
}));

const leadId = createSelector(identityData, (analyticsData) => analyticsData.lh_lead_id);

const userToken = createSelector(identityData, (analyticsData) => analyticsData.user_token);

const userCreatedAt = createSelector(identityData, (analyticsData) => analyticsData.created_at);

const browserToken = createSelector(identityData, (analyticsData) => analyticsData.browser_token);

const userId = createSelector(identityData, (analyticsData) => analyticsData.lh_user_id);

const adminUserId = createSelector(identityData, (analyticsData) => analyticsData.lh_ghosted_admin_user_id);

const iP = createSelector(identityData, (analyticsData) => analyticsData.ip);

const isFakeLead = createSelector(identityData, (analyticsData) => analyticsData.is_fake);

const urlParams = createSelector(identityData, (analyticsData) => analyticsData.url_params);

export const analytics = {
  identityData,
  leadId,
  userToken,
  browserToken,
  userId,
  userCreatedAt,
  adminUserId,
  iP,
  isFakeLead,
  urlParams,
};

export const brokerStateUnsupported = createSelector(
  getFunnelMetadata,
  (metadata) => metadata.brokerStateUnsupported === true
);

export const isFirstTimeHomeBuyer = createSelector(getFunnelSteps, (steps) => steps.firstTimeHomeBuyer === true);

export const flowName = createSelector(getFunnelMetadata, (metadata) => metadata.flowName);

export const auth0IsDisabled = createSelector(getFunnelMetadata, (metadata) => metadata.auth0IsDisabled);

export const referral = createSelector(getFunnelMetadata, (metadata) => metadata.referral);

export const experiments = createSelector(getFunnelMetadata, (metadata) => metadata.experiments);

const runningExperiment = createSelector(
  experiments,
  (runningExperiments) => runningExperiments && runningExperiments[0]
);

export const runningAlternative = createSelector(
  runningExperiment,
  (experiment) => experiment && experiment.alternative_id
);

export const experimentInsertionPointId = createSelector(
  runningExperiment,
  (experiment) => experiment && experiment.insertion_point_id
);

export const flowDetails = createSelector(getFunnelMetadata, (metadata) => ({
  flowStepOrder: metadata.stepNumber,
  prevStepOrder: metadata.prevStepNumber,
  prevStepName: metadata.prevStepName,
  prevFlowName: metadata.prevFlowName,
  isTerminal: metadata.isTerminal || false,
}));

export const isPurchase = createSelector(getFunnelMetadata, (metadata) => metadata.loanPurpose === 'purchase');

export const programTag = createSelector(getFunnelMetadata, (metadata) => metadata.programTag);

export const lhHolidays = createSelector(getFunnelMetadata, (metadata) => metadata.lhHolidays);

export const minClosingDate = createSelector(getFunnelMetadata, (metadata) => metadata.minClosingDate);

export const lowestInterestRates = createSelector(getFunnelMetadata, (metadata) => metadata.lowestInterestRates);

export const showSocialProofQuotes = createSelector(getFunnelMetadata, (metadata) => metadata.showSocialProofQuotes);

export const hasFullName = createSelector(getFunnelMetadata, (metadata) => !isNil(metadata.borrowerFullName));

export const userIsPendingBroker = createSelector(getFunnelMetadata, (metadata) => metadata.userIsPendingBroker);
