import { FunnelFlowVersions } from '../../constants';
import getRateDefaultRoutes from './getRate';
import standardBorrowerRoutes from './standardBorrower';
import proBorrowerRoutes from './proBorrower';
import rentalBorrowerRoutes from './rentalBorrower';
import brokerRoutes from './broker';
import bridgePropertyPreCalc from './bridgePropertyPreCalc';

// No rental routes because they have been migrated to loan-application-flow
const defaultRoutes = [
  ...getRateDefaultRoutes,
  ...proBorrowerRoutes,
  ...standardBorrowerRoutes,
  ...rentalBorrowerRoutes,
  ...brokerRoutes,
];

export default {
  [FunnelFlowVersions.DEFAULT]: [...defaultRoutes],
  [FunnelFlowVersions.BRIDGE_WITH_EARLIER_PROPERTY_ADDRESS]: [...defaultRoutes, ...bridgePropertyPreCalc],
};
