import * as steps from '../../components/steps';

const rentalBorrowerRoutes = [
  {
    flow: 'rentalBorrower',
    step: 'dealsLast24',
    component: steps.DealsLast24,
  },
  {
    flow: 'rentalBorrower',
    step: 'firstTimeHomeBuyer',
    component: steps.FirstTimeHomeBuyer,
  },
  {
    flow: 'rentalBorrower',
    step: 'unsupportedFirstTimeHomeBuyer',
    component: steps.UnsupportedFirstTimeHomeBuyer,
  },
  {
    flow: 'rentalBorrower',
    step: 'propertyState',
    component: steps.PropertyState,
  },
  {
    flow: 'rentalBorrower',
    step: 'unsupportedUsState',
    component: steps.UnsupportedUsState,
  },
  {
    flow: 'rentalBorrower',
    step: 'entity',
    component: steps.Entity,
  },
  {
    flow: 'rentalBorrower',
    step: 'unsupportedBorrowerNoEntity',
    component: steps.UnsupportedBorrowerNoEntity,
  },
  {
    flow: 'rentalBorrower',
    step: 'unsupportedIndividualUsState',
    component: steps.UnsupportedIndividualUsState,
  },
  {
    flow: 'rentalBorrower',
    step: 'yourEmail',
    component: steps.YourEmail,
  },
  {
    flow: 'rentalBorrower',
    step: 'yourPhone',
    component: steps.YourPhone,
  },
  {
    flow: 'rentalBorrower',
    step: 'yourName',
    component: steps.YourName,
  },
  {
    flow: 'rentalBorrower',
    step: 'checkYourEmail',
    component: steps.CheckYourEmail,
  },
  {
    flow: 'rentalBorrower',
    step: 'setPassword',
    component: steps.SetPassword,
  },
  {
    flow: 'rentalBorrower',
    step: 'signIn',
    component: steps.SignIn,
  },
  {
    flow: 'rentalBorrower',
    step: 'submit',
    component: steps.SubmitRental,
  },
];

export default rentalBorrowerRoutes;
