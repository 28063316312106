import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export default class Pattern {
  constructor({ pattern, message = 'Invalid format', priority = 1 }) {
    this.priority = priority;
    this.pattern = pattern;
    this.message = message;
  }

  isValid(value) {
    // a null/empty value is not a valid comparison for a format validator. That should be handled by a required validator
    if (isNil(value) || isEmpty(value)) return true;
    return new RegExp(this.pattern).test(value);
  }
}
