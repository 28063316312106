import React from 'react';
import { isEvent, uiPosition } from '@lendinghome/core';
import Constants from '@lendinghome/components-core/constants';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import Tooltip from './Tooltip';

const { any, array, bool, func, node, number, oneOf, oneOfType, string } = PropTypes;

function optionRenderer({ value, label }) {
  return <span data-option={value}>{label}</span>;
}

function tooltipRenderer({ value, label, description }) {
  return (
    <Tooltip
      safeDesc={description}
      position={uiPosition.RIGHT}
      staticPositioned
    >
      <span data-option={value}>{label}</span>
    </Tooltip>
  );
}

tooltipRenderer.propTypes = {
  value: any,
  label: any,
  description: any,
};
optionRenderer.propTypes = {
  value: any,
  label: any,
};

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: isNil(props.value) ? props.defaultValue : props.value,
      inputId: props.id || props.name || uniqueId('select-'),
    };
  }

  componentWillReceiveProps(nextProps) {
    const isPropsChanged = !isEqual(this.props, nextProps);

    if (isPropsChanged) {
      const newPropsValue = isNil(nextProps.value) ? '' : nextProps.value;
      this.setState({ value: newPropsValue });
    }
  }

  getOnChangeValueAndEvent = (valueOrEvent) => {
    const { name } = this.props;
    let value = valueOrEvent;
    let evt = { type: 'onSelectChange' };
    if (isEvent(valueOrEvent)) {
      // to handle onChange for native select when isMobileOptimized = true
      value = valueOrEvent.target.value;
      evt = valueOrEvent;
      // needed to access the evt async in setState
      // when passing to onChange callback
      evt.persist();
    } else {
      // to handle onChange for react-select component
      // ensure the object representation of the new value contains a key 'value'

      /* eslint-disable no-nested-ternary */
      // NOTE: this ternary is kind of outta control
      value = Array.isArray(valueOrEvent)
        ? valueOrEvent.map((item) => item.value)
        : isNil(valueOrEvent) || isNil(valueOrEvent.value)
          ? valueOrEvent
          : valueOrEvent.value;
      evt = {
        ...evt,
        target: { name, value, type: typeof value },
        stopPropagation: () => {
          /* noop */
        },
        preventDefault: () => {
          /* noop */
        },
      };
    }

    return {
      value,
      evt,
    };
  };

  handleOnChange = (valueOrEvent) => {
    const { onChange } = this.props;
    const { value, evt } = this.getOnChangeValueAndEvent(valueOrEvent);

    this.setState({ value }, () => {
      if (typeof onChange === 'function') onChange(value, evt);
    });
  };

  getOnBlurEvent = (value) => {
    const { name } = this.props;
    return {
      type: 'onSelectBlur',
      target: { name, value, type: typeof value },
      stopPropagation: () => {
        /* noop */
      },
      preventDefault: () => {
        /* noop */
      },
    };
  };

  handleOnBlur = () => {
    const { onBlur } = this.props;
    const { value } = this.state;
    const evt = this.getOnBlurEvent(value);
    if (typeof onBlur === 'function') onBlur(value, evt);
  };

  render() {
    const {
      multi,
      name,
      label,
      labelPlacement,
      options,
      placeholder,
      disabled,
      clearable,
      searchable,
      closeOnSelect,
      joinValues,
      delimiter,
      simpleValue,
      className,
      reactSelectClassName,
      isMobileOptimized,
      tabIndex,
      isFloatInput,
      required,
      error,
      withTooltip,
    } = this.props;
    const { value, inputId } = this.state;
    const selectPlaceholder = placeholder || label || 'Please Select';

    // Non-mobile optimized Select
    let fieldComponent = (
      <ReactSelect
        name={name}
        value={value}
        multi={multi}
        options={options}
        className={reactSelectClassName}
        onBlur={this.handleOnBlur}
        disabled={disabled}
        placeholder={selectPlaceholder}
        onChange={this.handleOnChange}
        searchable={searchable}
        clearable={clearable}
        optionRenderer={withTooltip ? tooltipRenderer : optionRenderer}
        backspaceToRemoveMessage={''}
        closeOnSelect={closeOnSelect}
        joinValues={joinValues}
        delimiter={delimiter}
        simpleValue={simpleValue}
        id={inputId}
        required={required}
      />
    );

    // Allow mobile devices to use the native 'picker' control
    if (isMobileOptimized && window.innerWidth <= Constants.breakpoints.mobile_max) {
      const optionElements = options.map((option) => (
        <option
          key={option.value}
          value={option.value}
        >
          {option.label}
        </option>
      ));
      optionElements.unshift(
        <option
          key="default"
          value=""
        >
          {selectPlaceholder || 'Please Select'}
        </option>
      );
      fieldComponent = (
        <select
          placeholder={selectPlaceholder}
          tabIndex={tabIndex.toString()}
          name={name}
          value={value}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
        >
          {optionElements}
        </select>
      );
    }

    const labelElement = label && (
      <label
        className="float-input--label"
        htmlFor={inputId}
      >
        {label}
      </label>
    );

    return (
      <div
        className={classnames(className, {
          'float-input': isFloatInput,
          'react-select': true,
          'is-select-empty': !value,
        })}
        data-select={name}
      >
        {labelPlacement === 'before' && labelElement}
        {fieldComponent}
        {labelPlacement === 'after' && labelElement}
        {error && <div className="input--error">{error}</div>}
      </div>
    );
  }
}

Select.defaultProps = {
  defaultValue: null,
  multi: false,
  labelPlacement: 'after',
  disabled: false,
  clearable: false,
  searchable: false,
  closeOnSelect: true,
  joinValues: false,
  delimiter: null,
  simpleValue: false,
  className: null,
  isMobileOptimized: false,
  tabIndex: 0,
  isFloatInput: true,
  required: false,
  reactSelectClassName: null,
  withTooltip: false,
  error: null,
};

Select.propTypes = {
  /** The currently selected value */
  value: any,
  /** A default value if value is not provided */
  defaultValue: any,
  /** Turns multi-select behavior on/off */
  multi: bool,
  /** The HTML name attribute for the component */
  name: string,
  /** The label which appears just above or below the Select */
  label: node,
  /** Position of the label */
  labelPlacement: oneOf(['before', 'after']),
  /** Allows you to provide a callback which is invoked when the value changes */
  onChange: func,
  onBlur: func,
  options: array,
  disabled: bool,
  clearable: bool,
  /** Controls the ability to search within the Select list */
  searchable: bool,
  /** Causes the Select to close when a selection is made (or not) */
  closeOnSelect: bool,
  joinValues: bool,
  delimiter: string,
  simpleValue: bool,
  className: string,
  reactSelectClassName: string,
  isMobileOptimized: bool,
  /** Placeholder text */
  placeholder: string,
  tabIndex: oneOfType([string, number]),
  isFloatInput: bool,
  id: string,
  required: bool,
  error: node,
  withTooltip: bool,
};

export default Select;
