import isEmpty from 'lodash/isEmpty';

export default class MinValue {
  constructor({
    minValue,
    compareToValue,
    message = `Must be at least ${minValue}`,
    priority = null,
  }) {
    this.priority = priority;
    this.minValue = minValue;
    this.message = message;
    this.compareToValue = compareToValue;
  }

  checkValue(value) {
    try {
      if (typeof value === 'string' && isEmpty(value)) return true;
      if (isNaN(value)) return true; // do not attempt a comparison if this is not a number
      return this.compareToValue
        ? value >= this.compareToValue
        : value >= this.minValue;
    } catch (e) {
      return false;
    }
  }

  isValid(value) {
    return this.checkValue(Number(value));
  }
}
