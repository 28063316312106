import React from 'react';
import { VerticalSelectBar } from '@lendinghome/core/components';
import { string, bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function DealsLast24Input({ onChange, value, disabled }) {
  const options = [
    {
      label: 'None',
      value: '0',
    },
    {
      label: '1-4 properties',
      value: '1-4',
    },
    {
      label: '5-9 properties',
      value: '5-9',
    },
    {
      label: 'Over 10 properties',
      value: '10+',
    },
  ];

  return (
    <VerticalSelectBar
      options={options}
      fieldName="estimateNumExitsInLastTwentyFourSelectedRange"
      currentValue={`${value}`}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

DealsLast24Input.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};

DealsLast24Input.defaultProps = {
  value: '0',
  disabled: false,
};

export default FunnelStepForm({
  Input: DealsLast24Input,
  submitOnChange: true,
});
