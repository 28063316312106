// << ApiResponse >>

// Handles validating of the ApiResponse object schema,
// which is returned by all LendingHome API endpoints
//
// See schema at https://lendinghome.gitbook.io/tech/front-end-engineering/shared-libraries/core/net/apiresponse
//
// example usages:
//
// const response = new ApiResponse(responseBody);
//
import { LendingHomeError } from '../errors';

const CURRENT_VERSION = '1.0.0';

// `DISPOSITION.SUCCESS` => 0
// `DISPOSITION[0]` => "SUCCESS"
const DISPOSITION = {};
DISPOSITION[(DISPOSITION.SUCCESS = 0)] = 'SUCCESS';
DISPOSITION[(DISPOSITION.PARTIAL_SUCCESS = 1)] = 'PARTIAL_SUCCESS';
DISPOSITION[(DISPOSITION.FAILURE = 2)] = 'FAILURE';
DISPOSITION[(DISPOSITION.PENDING = 3)] = 'PENDING';

export default class ApiResponse {
  static DISPOSITION = DISPOSITION;
  static CURRENT_VERSION = CURRENT_VERSION;

  constructor(input) {
    // perform validations
    if (input.version !== CURRENT_VERSION) {
      throw new LendingHomeError(
        `Invalid ApiResponse version. Expected \
        '${CURRENT_VERSION}', got '${input.version}'`,
        {
          data: { input },
        }
      );
    }

    if (!DISPOSITION[input.disposition]) {
      throw new LendingHomeError(
        `Invalid ApiResponse disposition '${input.disposition}'`,
        {
          data: { input },
        }
      );
    }

    if (input.disposition === DISPOSITION.FAILURE && !input.errors) {
      throw new LendingHomeError(
        'Invalid ApiResponse: missing errors for failure',
        {
          data: { input },
        }
      );
    }

    this.version = input.version;
    this.disposition = input.disposition;
    this.data = input.data || null;
    this.errors = input.errors || null;
  }
}
