import React, { Component, cloneElement } from 'react';
import { getEventValue } from '@lendinghome/core';
import { ensureChildrenHaveKeys } from '@lendinghome/utility-belt-react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import classnames from 'classnames';

const { string, bool, func, oneOf, number, object, oneOfType } = PropTypes;

export default class CheckboxInput extends Component {
  constructor(props) {
    super(props);
    this.uniqueId = uniqueId('input-');
    this.state = {
      value: false,
    };
  }

  componentWillMount() {
    if (!isNil(this.props.value)) {
      this.setValueFromProps();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.setValueFromProps(nextProps);
    }
  }

  setValueFromProps(props = this.props) {
    this.setState({ value: props.value || false });
  }

  wrappedOnChangeEvent(onChange = () => {}) {
    return (e) => {
      const value = getEventValue(e);
      this.setState({
        value,
      });
      onChange(e, value);
    };
  }

  render() {
    const {
      className,
      fieldsetClassName,
      fieldsetAttributes,
      labelClassName,
      onBlur,
      onChange,
      required,
      inputComponent,
      labelPlacement,
      label,
      id,
      name,
      children,
      infoTip,
      infoTipPlacement,
      renderFieldset,
      ...props
    } = this.props;

    const inputId = id || this.uniqueId;

    const infoTipClasses = classnames('tip', {
      'is-tip-bottom': infoTipPlacement === 'bottom',
      'is-tip-right': infoTipPlacement === 'right',
    });

    let fieldsetChildren = [
      cloneElement(inputComponent, {
        ...props,
        type: 'checkbox',
        disabled: this.props.disabled,
        name: inputComponent.props.name ? inputComponent.props.name : name,
        id: inputId,
        key: inputId,
        value: this.state.value,
        checked: this.state.value === true,
        onChange: this.wrappedOnChangeEvent(onChange),
        className,
        readOnly: this.props.readOnly,
      }),
    ];

    if (label) {
      const labelComponent = (
        <label
          className={classnames(labelClassName)}
          key={`label-${this.uniqueId}`}
          htmlFor={inputId}
        >
          {label}
        </label>
      );

      if (labelPlacement === 'before') {
        fieldsetChildren = [labelComponent].concat(fieldsetChildren);
      } else {
        fieldsetChildren = fieldsetChildren.concat([labelComponent]);
      }
    }

    if (infoTip) {
      const infoTipComponent = <aside className={infoTipClasses}>{infoTip}</aside>;

      fieldsetChildren = fieldsetChildren.concat([infoTipComponent]);
    }

    if (children) {
      fieldsetChildren = fieldsetChildren.concat(
        children({
          value: this.state.value,
        })
      );
    }

    return renderFieldset ? (
      <fieldset
        key={`fieldset-${this.uniqueId}`}
        className={classnames(fieldsetClassName)}
        {...fieldsetAttributes}
      >
        {ensureChildrenHaveKeys(fieldsetChildren)}
      </fieldset>
    ) : (
      <span>{ensureChildrenHaveKeys(fieldsetChildren)}</span>
    );
  }
}

CheckboxInput.defaultProps = {
  inputComponent: <input />,
  infoTipPlacement: 'right',
  renderFieldset: true,
};

CheckboxInput.propTypes = {
  id: string,
  label: string,
  labelClassName: string,
  fieldsetClassName: string,
  fieldsetAttributes: object,
  /** Whether to render a <Fieldset /> element around the checkbox input */
  renderFieldset: bool,
  labelPlacement: oneOf(['before', 'after']),
  onChange: func,
  children: func,
  value: oneOfType([number, string, bool]),
  disabled: bool,
  readOnly: bool,
};
