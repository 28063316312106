import React, { Component } from 'react';
import { normalizeBoolean } from '@lendinghome/core/utils';
import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import {
  number,
  func,
  oneOfType,
  string,
  bool,
  arrayOf,
  shape,
  any,
} from 'prop-types';

export default class SelectBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.props.currentValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      currentValue: nextProps.currentValue,
    }));
  }

  fieldName() {
    return this.props.cleanupFieldNames
      ? kebabCase(this.props.fieldName)
      : this.props.fieldName;
  }

  fieldId(value) {
    return kebabCase(`${this.fieldName()}_${value}`);
  }

  handleOnChange = (event) => {
    const value = normalizeBoolean(event.target.value);

    this.setState({
      currentValue: value,
    });

    if (this.props.onChange) {
      this.props.onChange(value, event);
    }
  };

  render() {
    const { currentValue } = this.state;
    const { options, className, disabled } = this.props;
    const selectbarClassNames = classnames(
      'select-bar',
      {
        'no-vertical-select-bar': this.props.noVerticalSelectbar,
        'is-select-bar-dense': this.props.dense,
      },
      className
    );

    const formattedOptions = options.map((option) => {
      const key = this.fieldId(option.value);
      const checked = currentValue === option.value;

      return [
        <input
          key={key}
          className="select-bar--native select-bar--option"
          id={key}
          name={this.fieldName()}
          type="radio"
          value={option.value}
          onChange={this.handleOnChange}
          checked={checked}
          disabled={disabled}
        />,
        <label
          key={`${key}-label`}
          htmlFor={key}
        >
          {option.label}
        </label>,
      ];
    });

    return <div className={selectbarClassNames}>{formattedOptions}</div>;
  }
}

SelectBar.defaultProps = {
  currentValue: undefined,
  cleanupFieldNames: true,
  className: null,
  noVerticalSelectbar: false,
  dense: false,
  onChange: null,
  disabled: false,
};

SelectBar.propTypes = {
  /** The field value for controlled components */
  currentValue: oneOfType([string, number, bool]),
  /** The name for the field */
  fieldName: string.isRequired,
  /** If true, the fieldName provided is changed to kebab case before being used
   * in the input names and ids */
  cleanupFieldNames: bool,
  /** Prevents the select bar from being laid out verticall on small widths */
  noVerticalSelectbar: bool,
  /** Whether to render the select bar with reduced margin and padding to fit in smaller spaces */
  dense: bool,
  /** Function to call when the user changes the selection */
  onChange: func,
  /** The options available for selection
   * const selectBarOptions = [
   *   {
   *     label: "Option 1",
   *     value: "First",
   *   },
   *   {
   *     label: "Option 2",
   *     value: "Second",
   *   },
   *   {
   *     label: "Option 3",
   *     value: true,
   *   }
   * ]
   */
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: any.isRequired,
    })
  ).isRequired,
  /** A className to be added to the top-most element */
  className: string,
  /** Whether the inputs are disabled */
  disabled: bool,
};
