import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

const { node, func } = PropTypes;

export default class ApplicationErrorPage extends React.Component {
  static propTypes = {
    errorHeader: func,
    errorFooter: func,
    children: node,
  };

  reloadPage = () => {
    window.location.reload(true);
  };

  render() {
    return (
      <div className="notice-page  is-application-error">
        {this.props.errorHeader && React.createElement(this.props.errorHeader)}
        {this.props.children ? (
          this.props.children
        ) : (
          <section className="notice-page--content-container is-application-error-message">
            <SvgIcon
              icon="cone"
              className="is-illustration notice-page--icon"
            />
            <h1 className="notice-page--headline">We&apos;re Sorry!</h1>
            <p className="notice-page--content">
              An unrecoverable error has occurred and was reported to our
              engineering team. Please try reloading the page to continue.
            </p>
            <button
              onClick={this.reloadPage}
              className="button"
            >
              Reload page
            </button>
          </section>
        )}
        {this.props.errorFooter && React.createElement(this.props.errorFooter)}
      </div>
    );
  }
}
