import React from 'react';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';

export function TextField({
  onChange,
  onBlur,
  value,
  disabled,
  htmlFor,
  className,
  placeholder,
  required = true,
  maxLength = '256',
  ...rest
}) {
  // since R15 `null` is not a valid value to input as it risks converting
  // a controlled to an uncontrolled component
  // adding a fallback to empty string in such cases
  // ref: https://github.com/rjsf-team/react-jsonschema-form/issues/336
  return (
    <input
      placeholder={placeholder}
      type="text"
      name={htmlFor}
      id={htmlFor}
      maxLength={maxLength}
      className={className}
      autoComplete="on"
      onChange={onChange}
      onBlur={onBlur}
      value={value || ''}
      disabled={disabled}
      required={required}
      {...rest}
    />
  );
}

export default CreateVerifyingTextField({ Input: TextField });
