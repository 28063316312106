import { Children } from 'react';
import getDisplayName from './getDisplayName';

class ElementTypeError {
  constructor(expectedType, actualType, componentName) {
    this.actualType = actualType;
    this.componentName = componentName;
    this.expectedType = expectedType;
  }
}

class NoneOfElementTypesError extends ElementTypeError {
  constructor(expectedTypes, actualType, componentName) {
    super(null, actualType, componentName);
    this.expectedTypes = expectedTypes;
  }

  toError() {
    return new Error(
      `Invalid child of type ${this.actualType} inside component ${
        this.componentName
      }; expected one of ${this.expectedTypes.join(', ')}.`
    );
  }
}

class UnexpectedElementTypeError extends ElementTypeError {
  toError() {
    return new Error(
      `Illegal child element type ${this.actualType} inside ${this.componentName}; expected ${this.expectedType}.`
    );
  }
}

class ExpectedElementTypeError extends ElementTypeError {
  toError() {
    return new Error(`Illegal child element type ${this.actualType} inside ${this.componentName}.`);
  }
}

const elementTypeValidator = (type, handler) => (props, propName, componentName) =>
  Children.map(props[propName], (child) =>
    handler(type === getDisplayName(child.type), getDisplayName(child.type), componentName, type)
  );

const isElementTypeHandler = (result, actualType, componentName, expectedType) =>
  result || new UnexpectedElementTypeError(expectedType, actualType, componentName);

const notElementTypeHandler = (result, type, componentName) =>
  result && new ExpectedElementTypeError(expectedType, actualType, componentName);

export const elementType = (type) => elementTypeValidator(type, isElementTypeHandler);
export const notElementType = (type) => elementTypeValidator(type, notElementTypeHandler);

export const childrenOfType = (...types) => (props, propName, componentName) => {
  const results = Children.map(props[propName], (child) => {
    if (!child) {
      return null;
    }

    const actualType = getDisplayName(child.type);
    return types.every((type) => actualType !== type)
      ? new NoneOfElementTypesError(types, actualType, componentName)
      : null;
  });

  const error = results.find((item) => item instanceof ElementTypeError);
  if (error) {
    return error.toError();
  }
};

export const childrenTypesExcept = (...types) => (props, propName, componentName) => {
  const results = Children.map(props[propName], (child) => {
    const actualType = getDisplayName(child.type);
    return types.some((type) => actualType === type)
      ? new ExpectedElementTypeError(actualType, actualType, componentName)
      : null;
  });

  const error = results.find((item) => item instanceof ElementTypeError);

  if (error) {
    return error.toError();
  }
};
