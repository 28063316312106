import React from 'react';
import { bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import FunnelStepForm from '../../hoc/FunnelStepForm';
import GeocodingMultiLineAddress from '../../GeocodingMultiLineAddress';

const validator = GeocodingMultiLineAddress.validator;

function PropertyAddressInput({ onChange, value, disabled }) {
  return (
    <Fieldset>
      <GeocodingMultiLineAddress
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </Fieldset>
  );
}

PropertyAddressInput.propTypes = {
  onChange: func.isRequired,
  disabled: bool,
};

PropertyAddressInput.defaultProps = {
  disabled: false,
};

export default FunnelStepForm({
  Input: PropertyAddressInput,
  submitOnChange: false,
  isSubmittable: (value) => value && validator(value),
});
