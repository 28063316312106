import React from 'react';
import EventNotifier from '../../analytics/eventNotifier';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import DealsLast24InputForm from './inputs/DealsLast24InputForm';
import { BrokerLink } from './BrokerLink';

function DealsLast24FullScreen(props) {
  return (
    <GetRateFullScreen headline="How many investment properties have you purchased/refinanced in the last two years?">
      <DealsLast24InputForm {...props} />
      <BrokerLink />
    </GetRateFullScreen>
  );
}

const DealsLast24 = FunnelCake({
  InputForm: DealsLast24FullScreen,
  notifyStepLoadedHandlers: [EventNotifier.notifyHardMoneyFlowLoaded, EventNotifier.notifyStepLoaded],
  notifyStepCompletedHandlers: [EventNotifier.notifyStepCompleted, EventNotifier.notifyIfProPlus],
  mapPropsToAjaxParams: () => ({ account_type: 'borrower' }),
});

export default DealsLast24;
