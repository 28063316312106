import React, { Component, cloneElement } from 'react';
import { ensureChildrenHaveKeys } from '@lendinghome/utility-belt-react';
import { getEventValue, defaultDebounceOnChangeWait } from '@lendinghome/core';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import classnames from 'classnames';

const { string, bool, func, oneOf, number, object } = PropTypes;

export default class TextArea extends Component {
  constructor(props) {
    super(props);
    this.uniqueId = uniqueId('input-');
    this.state = {
      value: '',
    };
  }

  componentWillMount() {
    if (!isNil(this.props.value)) {
      this.setValueFromProps();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.setValueFromProps(nextProps);
    }
  }

  setValueFromProps(props = this.props) {
    this.setState({ value: props.value || '' });
  }

  wrappedEventWithValue(eventHandler) {
    return (e) => {
      const value = getEventValue(e);
      this.setState({
        value: value || '',
      });
      eventHandler(value, e);
    };
  }

  wrappedOnChangeEvent(onChange = () => {}) {
    if (this.props.debounceOnChange && !this.debouncedOnChange) {
      this.debouncedOnChange = debounce(onChange, this.props.debounceOnChangeWait);
    }

    return this.wrappedEventWithValue(this.debouncedOnChange || onChange);
  }

  wrappedOnBlurEvent(onBlur = () => {}) {
    return this.wrappedEventWithValue(onBlur, true);
  }

  render() {
    const {
      className,
      fieldsetClassName,
      fieldsetAttributes,
      labelClassName,
      onBlur,
      onChange,
      required,
      inputComponent,
      labelPlacement,
      label,
      id,
      children,
      infoTip,
      infoTipPlacement,
      debounceOnChange,
      debounceOnChangeWait,
      secret,
      ...props
    } = this.props;

    const inputId = id || this.uniqueId;

    const infoTipClasses = classnames('tip', {
      'is-tip-bottom': infoTipPlacement === 'bottom',
      'is-tip-right': infoTipPlacement === 'right',
    });

    const fullStoryProps = {};

    // Do NOT report this value to fullstory if 'secret' is set
    if (this.props.secret) {
      fullStoryProps['data-fullstory'] = 'off';
    }

    let fieldsetChildren = [
      cloneElement(inputComponent, {
        ...props,
        ...fullStoryProps,
        id: inputId,
        key: inputId,
        value: this.state.value,
        onChange: this.wrappedOnChangeEvent(onChange),
        onBlur: this.wrappedOnBlurEvent(onBlur),
        className,
      }),
    ];

    if (label) {
      const labelComponent = (
        <label
          className={classnames(labelClassName)}
          key={`label-${this.uniqueId}`}
          htmlFor={inputId}
        >
          {label}
        </label>
      );

      if (labelPlacement === 'before') {
        fieldsetChildren = [labelComponent].concat(fieldsetChildren);
      } else {
        fieldsetChildren = fieldsetChildren.concat([labelComponent]);
      }
    }

    if (infoTip) {
      const infoTipComponent = <aside className={infoTipClasses}>{infoTip}</aside>;

      fieldsetChildren = fieldsetChildren.concat([infoTipComponent]);
    }

    if (children) {
      fieldsetChildren = fieldsetChildren.concat(
        children({
          value: this.state.value,
        })
      );
    }

    return (
      <fieldset
        key={`fieldset-${this.uniqueId}`}
        className={classnames(fieldsetClassName)}
        {...fieldsetAttributes}
      >
        {ensureChildrenHaveKeys(fieldsetChildren)}
      </fieldset>
    );
  }
}

TextArea.defaultProps = {
  inputComponent: <textarea />,
  debounceOnChange: true,
  debounceOnChangeWait: defaultDebounceOnChangeWait,
  infoTipPlacement: 'right',
  secret: false,
};

TextArea.propTypes = {
  id: string,
  label: string,
  labelClassName: string,
  fieldsetClassName: string,
  fieldsetAttributes: object,
  labelPlacement: oneOf(['before', 'after']),
  debounceOnChange: bool,
  debounceOnChangeWait: number,
  onChange: func,
  onBlur: func,
  children: func,
  value: string,
  secret: bool,
};
