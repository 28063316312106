export const SENTINEL_KEY = '__LOCAL_STORAGE_CHECKER_3000__';

/**
 * Tests whether or not the Storage is available and functional for the browser. This often returns false if the
 * browser has "Block third-party cookies and site data" or equivalent enabled.
 *
 * @param {Storage?} customStorage - Optional Storage. Defaults to `window.localStorage` if not provided.
 * @returns {Boolean} `true` if the Storage is available and functional; otherwise, returns false.
 */
export const checkLocalStorage = (customStorage) => {
  try {
    // Simply accessing `window.localStorage` may result in error, so we must also wrap it in the try-catch.
    const storage = customStorage !== undefined ? customStorage : window.localStorage;

    if (!storage) {
      return false;
    }

    storage.setItem(SENTINEL_KEY, 'test');
    storage.removeItem(SENTINEL_KEY);
    return true;
  } catch (e) {
    return false;
  }
};

export const isLocalStorageAvailable = checkLocalStorage();
