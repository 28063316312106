import React from 'react';
import { VerticalSelectBar } from '@lendinghome/core/components';
import { number, bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function InfillExitsLast60Input({ onChange, value, disabled }) {
  const onChangeLast60 = (val) => {
    onChange(parseInt(val, 10));
  };

  const options = [
    {
      label: 'None',
      value: '0',
    },
    {
      label: '1 property',
      value: '1',
    },
    {
      label: '2+ properties',
      value: '2',
    },
  ];

  return (
    <VerticalSelectBar
      options={options}
      fieldName="estimateNumExitsLastSixtySelectedRange"
      currentValue={`${value}`}
      onChange={onChangeLast60}
      disabled={disabled}
    />
  );
}

InfillExitsLast60Input.propTypes = {
  onChange: func.isRequired,
  value: number,
  disabled: bool,
};

InfillExitsLast60Input.defaultProps = {
  value: null,
  disabled: false,
};

export default FunnelStepForm({
  Input: InfillExitsLast60Input,
  submitOnChange: true,
});
