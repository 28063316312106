import omit from 'lodash/omit';
import { stringifyError, recursiveMapValues } from '@lendinghome/utility-belt';

const CAPTURE_ERROR = 'crash/CAPTURE_ERROR';

function formatWrappedErrors(payload) {
  // Error object property descriptors are not enumerable and therefore
  // do not get stringified properly when calling `JSON.stringify`
  return recursiveMapValues(payload, (value) =>
    value instanceof Error ? stringifyError(value) : value
  );
}

export const captureError = (error, payload = {}) => ({
  type: CAPTURE_ERROR,
  meta: {
    crash: {
      type: 'captureException',
      error,
      payload,
    },
  },
});

export function external(action) {
  if (action.hidden) {
    return false;
  } else if (action.external) {
    return omit(action, 'external');
  }

  return { type: action.type };
}

export default function crashMiddleware(onError) {
  return (store) => (next) => (action) => {
    // eslint-disable-line consistent-return
    const recordErrorToFrogger = (err) => {
      const payload = external(action);

      if (payload) {
        // Grab arbitrary data from the event to send on to sentry
        const { extra = {} } = action;

        Frogger.error(
          err,
          {
            action: payload,
            ...extra,
          },
          {
            source: 'middleware',
          }
        );
      } else {
        Frogger.error(err);
      }
    };

    const handleError = (err) => {
      if (onError) {
        onError(err, store);
      }

      if (Frogger && Frogger.isInitialized && Frogger.isInitialized()) {
        recordErrorToFrogger(err);
      }
    };

    if (action.meta && action.meta.crash) {
      const {
        meta: {
          crash: { type, error, payload },
        },
      } = action;

      if (__DEVELOPMENT__) {
        Frogger.info('side-effect in crash middleware: ', type, payload);
      }

      switch (type) {
        case 'captureException':
          if (Frogger) {
            Frogger.error(error, formatWrappedErrors(payload));
          }
          break;
        case 'captureBreadcrumb':
          if (Frogger) {
            Frogger.breadcrumb(payload);
          }
          break;
        default:
          if (Frogger) {
            Frogger.info(
              `Unkown side-effect given to crash middleware: ${type}`
            );
          }
      }
    }

    try {
      return next(action);
    } catch (err) {
      handleError(err);
    }
  };
}
