import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import SlideInfo from '../SlideInfo';
import { borrowerFirstName } from '../../selectors';
import ConsentText from '../ConsentText';
import YourPhoneInputForm from './inputs/YourPhoneInputForm';

function YourPhoneFullScreen(props) {
  const { flow } = props;

  const info = {
    proBorrower: 'Provide your preferred phone number so we can easily reach you for loan-related questions.',
    rentalBorrower: 'Provide your preferred phone number so we can easily reach you for loan-related questions.',
    broker: 'Provide your preferred phone number so we can easily reach you for loan-related questions.',
  };

  // TODO:
  // The LabeledPhoneInput is not dry, and does not animate the placeholder up like the generic text input.

  return (
    <GetRateFullScreen headline="What is your phone number?">
      <SlideInfo>{info[flow]}</SlideInfo>
      <YourPhoneInputForm {...props}>
        <ConsentText marketingSiteUrl={gon.MARKETING_SITE} />
      </YourPhoneInputForm>
    </GetRateFullScreen>
  );
}

YourPhoneFullScreen.propTypes = {
  firstName: string,
};

YourPhoneFullScreen.defaultProps = {
  firstName: null,
};

const mapStateToProps = (state) => ({ firstName: borrowerFirstName(state) });

const YourPhone = FunnelCake({
  InputForm: connect(mapStateToProps)(YourPhoneFullScreen),
});

export default YourPhone;
