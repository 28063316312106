// HTTP Methods
export const HttpMethod = {
  Get: 'GET',
  Head: 'HEAD',
  Options: 'OPTIONS',
  Patch: 'PATCH',
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
};
