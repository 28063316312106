import * as steps from '../../components/steps';

const brokerRoutes = [
  {
    flow: 'broker',
    step: 'brokerBridgeRental',
    component: steps.BrokerBridgeRental,
    stepUrl: 'index',
  },
  { flow: 'broker', step: 'brokerBridgeRental', component: steps.BrokerBridgeRental },
  { flow: 'broker', step: 'brokerFailure', component: steps.BrokerBridgeRentalFailure },
  { flow: 'broker', step: 'brokerUsState', component: steps.BrokerBridgeRentalUsState },
  { flow: 'broker', step: 'yourName', component: steps.YourName },
  { flow: 'broker', step: 'yourEmail', component: steps.YourEmail },
  { flow: 'broker', step: 'signIn', component: steps.SignIn },
  { flow: 'broker', step: 'yourPhone', component: steps.YourPhone },
  {
    flow: 'broker',
    step: 'setPassword',
    component: steps.SetBrokerPassword,
  },
  {
    flow: 'broker',
    step: 'checkYourEmail',
    component: steps.CheckYourEmail,
  },
];

export default brokerRoutes;
