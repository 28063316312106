import namesValidator from '../validators/namesValidator';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';
import { TextField } from './VerifyingTextField';

const VerifyingNameField = CreateVerifyingTextField({
  Input: TextField,
  validator: namesValidator,
});

VerifyingNameField.validator = namesValidator;

export default VerifyingNameField;
