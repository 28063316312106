import React, { Component } from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import clamp from 'lodash/clamp';
import SvgIcon from './SvgIcon';

const { number, func } = PropTypes;

class DialInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    let isNextValueInRange;
    const { maxValue, minValue } = nextProps;
    if (!isEqual(this.props.value, nextProps.value)) {
      this.setState(
        () => {
          const validatedInputValue = this.validateInputValue(
            nextProps.value,
            minValue,
            maxValue
          );
          isNextValueInRange = validatedInputValue === nextProps.value;
          return {
            inputValue: validatedInputValue,
          };
        },
        () => {
          if (!isNextValueInRange) {
            // If incoming value doesn't fall within threshold, alter the value
            // to match the current min of max value, then propagate value back up
            this.onChange(this.state.inputValue);
          }
        }
      );
    }
  }

  validateInputValue(value, min, max) {
    return clamp(value, min, max);
  }

  handleDecrement = () => {
    const { minValue, incrementUnits } = this.props;
    this.setState(
      (currentState) => {
        const newInputValue = currentState.inputValue - incrementUnits;
        const validatedInputValue =
          newInputValue >= minValue ? newInputValue : minValue;

        return { inputValue: validatedInputValue };
      },
      () => {
        this.onChange(this.state.inputValue);
      }
    );
  };

  handleIncrement = () => {
    const { maxValue, incrementUnits } = this.props;
    this.setState(
      (currentState) => {
        const newInputValue = currentState.inputValue + incrementUnits;
        const validatedInputValue =
          newInputValue <= maxValue ? newInputValue : maxValue;

        return { inputValue: validatedInputValue };
      },
      () => {
        this.onChange(this.state.inputValue);
      }
    );
  };

  onChange(val) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(val);
    }
  }

  render() {
    const { maxValue, minValue } = this.props;

    invariant(
      maxValue >= minValue,
      'maxValue must be greater than or equal to minValue'
    );

    invariant(
      minValue <= maxValue,
      'minValue must be less than or equal to maxValue'
    );

    const decrementButtonClasses = classnames(
      'button',
      'dial-input-control',
      'decrement-input-value',
      {
        disabled: this.state.inputValue <= minValue,
      }
    );

    const incrementButtonClasses = classnames(
      'button',
      'dial-input-control',
      'increment-input-value',
      {
        disabled: this.state.inputValue >= maxValue,
      }
    );

    return (
      <div className="dial-input-container">
        <button
          type="button"
          className={decrementButtonClasses}
          onClick={this.handleDecrement}
          aria-label="Decrement"
        >
          <SvgIcon
            icon="icon-minus"
            className="no-default-fill"
          />
        </button>
        <span className="dial-input-visual-value">{this.state.inputValue}</span>
        <button
          type="button"
          className={incrementButtonClasses}
          onClick={this.handleIncrement}
          aria-label="Increment"
        >
          <SvgIcon
            icon="icon-plus"
            className="no-default-fill"
          />
        </button>
        <input
          className="dial-input-hidden-input"
          value={this.state.inputValue}
          type="number"
          min={minValue}
          max={maxValue}
          readOnly
        />
      </div>
    );
  }
}

DialInput.defaultProps = {
  minValue: 0,
  maxValue: 1000,
  incrementUnits: 1,
  value: 0,
  onChange: null,
};

DialInput.propTypes = {
  /** The minimum possible value to display in the input */
  minValue: number,
  /** The maximum possible value to display in the input */
  maxValue: number,
  /** The amount to increment/decrement the dial when =/- is clicked */
  incrementUnits: number,
  /** The current value of the input */
  value: number,
  /** A callback that gets invoked when the input value is changed */
  onChange: func,
};

export default DialInput;
