import React from 'react';
import { Header } from '@lendinghome/core/components';
import SvgIcon from '@lendinghome/components-core/components/SvgIcon';
import { node, string } from 'prop-types';
import classnames from 'classnames';
import LandingFooter from './LandingFooter';

export default function PropertyFlowFullScreen({ headline, className, icon, children, slideBackground }) {
  const phone = gon.global.phone.tollfreeMarketing;
  return (
    <section
      className={classnames(
        className,
        'landing is-rebrand flow-biz-purpose-borrower-flow-config layout-new-style-default'
      )}
    >
      <Header
        className="get-rate-flow-header"
        logoIcon={'kiavi-logo'}
        phoneNumber={phone}
      />
      <div className={classnames('slide', slideBackground)}>
        <section className="flow-step">
          {icon && <SvgIcon
            className="prompt-icon is-illustration"
            icon={icon}
          />}
          <h1 className="slide-topic">{headline}</h1>
          {children}
        </section>
      </div>
      <LandingFooter />
    </section>
  );
}

PropertyFlowFullScreen.defaultProps = {
  headline: null,
  className: null,
  icon: null,
  children: null,
  slideBackground: null,
};

PropertyFlowFullScreen.propTypes = {
  headline: node,
  className: string,
  icon: string,
  children: node,
  slideBackground: string,
};
