import Pattern from './Pattern';

// Some examples of valid email addresses:
//  Abc@example.com                                (English, ASCII)
//  Abc.123@example.com                            (English, ASCII)
//  user+mailbox/department=shipping@example.com   (English, ASCII)
//  !#$%&'*+-/=?^_`.{|}~@example.com               (English, ASCII)
//  "Abc@def"@example.com                          (English, ASCII)
//  "Fred Bloggs"@example.com                      (English, ASCII)
//  "Joe.\\Blow"@example.com                       (English, ASCII)

// The validation regex should be a positive match on any of the above addresses.
// In addition, we should support IDN addresses in non-latin character sets,
// like Chinese, Japanese, Arabic, etc...
// Some examples of these include:

// 用户@例子.广告                 (Chinese)
// उपयोगकर्ता@उदाहरण.कॉम             (Hindi)
// юзер@екзампл.ком             (Ukrainian)
// θσερ@εχαμπλε.ψομ             (Greek)
// Dörte@Sörensen.example.com   (German)
// аджай@экзампл.рус            (Russian)

export default class Email extends Pattern {
  constructor({ message = 'Invalid email address' }) {
    super({
      pattern: /^[^\s<>(),:;<>@[\\\]]+@[^\s<>(),:;<>@[\\\]]+\.[^\s<>(),:;<>@[\\\]]{2,}$|^".+"@[^\s<>(),:;<>@[\\\]]+\.[^\s<>(),:;<>@[\\\]]{2,}$/,
      message,
    });
  }

  isValid(value) {
    const retVal = super.isValid(value);
    if (!retVal) {
      Frogger && Frogger.debug(`Invalid email address specified: ${value}`);
    }
    return retVal;
  }
}
