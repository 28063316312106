import React from 'react';
import PropTypes from 'prop-types';

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;

    this.getTimeDifference = this.getTimeDifference.bind(this);
    this.tick = this.tick.bind(this);
    this.finishCountdown = this.finishCountdown.bind(this);

    this.state = {
      deltas: this.getTimeDifference(),
      finished: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.interval = setInterval(this.tick, this.props.intervalDelay);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.clearInterval();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.intervalDelay !== this.props.intervalDelay ||
      nextProps.endDate !== this.props.endDate
    ) {
      this.setState(() => ({
        finished: false,
        deltas: this.getTimeDifference(nextProps.endDate),
      }));
    }
  }

  pad(value) {
    const strValue = String(value);
    return strValue.length >= 2
      ? strValue
      : '0'.repeat(2 - strValue.length) + strValue;
  }

  getTimeDifference(nexPropsEndDate) {
    const milliDiff = (nexPropsEndDate || this.props.endDate) - Date.now();
    const seconds = milliDiff / 1000;
    return {
      days: Math.floor(seconds / (3600 * 24)),
      hours: Math.floor((seconds / 3600) % 24),
      minutes: Math.floor((seconds / 60) % 60),
      seconds: Math.floor(seconds % 60),
    };
  }

  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
  }

  finishCountdown() {
    if (this.props.onCountdownFinished && !this.state.finished) {
      this.setState({ finished: true }, this.props.onCountdownFinished);
    }
  }

  tick() {
    if (!this.mounted) {
      return;
    }
    const deltas = this.getTimeDifference();
    if (deltas.seconds >= 0) {
      this.setState({ deltas });
    } else {
      this.finishCountdown();
      this.clearInterval();
    }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state.deltas;
    return (
      <span>
        {this.pad(days)}:{this.pad(hours)}:{this.pad(minutes)}:
        {this.pad(seconds)}
      </span>
    );
  }
}

Countdown.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  intervalDelay: PropTypes.number,
  onCountdownFinished: PropTypes.func,
};

Countdown.defaultProps = {
  intervalDelay: 1000,
  onCountdownFinished: Function.prototype,
};

export default Countdown;
