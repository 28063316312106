const flow = 'broker';

export default [
  {
    step: 'brokerBridgeRental',
    nextStep: (state) => {
      if (state.broker.brokersBridgeRentalLoans()) {
        return { flow, step: 'brokerUsState' };
      }
    },
    previous: (state) => {
      if (state.borrower.isRentalProgram()) {
        return { flow: 'rentalBorrower', step: 'dealsLast24' };
      }
      return { flow: 'getRate', step: 'exitsLast24' };
    },
    stepNumber: '2.1',
  },
  {
    step: 'brokerFailure',
    guard: (state) => !state.broker.brokersBridgeRentalLoans(),
    terminal: true,
    stepNumber: '2.1.1',
  },
  {
    step: 'brokerUsState',
    stepNumber: '2.2',
  },
  {
    step: 'yourEmail',
    nextStep: (state) => {
      if (state.auth0IsDisabled() && state.user.emailExists()) {
        return { flow, step: 'signIn' };
      }
      return { flow, step: 'yourPhone' };
    },
    stepNumber: '2.3',
  },
  {
    step: 'yourPhone',
    stepNumber: '2.4',
  },
  {
    step: 'yourName',
    nextStep: (state) => {
      if (state.auth0IsDisabled()) {
        return { flow, step: 'setPassword' };
      }

      return { flow, step: 'checkYourEmail' };
    },
    stepNumber: '2.5',
  },
  {
    step: 'checkYourEmail',
    previous: () => ({ flow, step: 'yourName' }),
    nextStep: () => ({ href: '/my/dashboard' }),
    stepNumber: '2.5.1',
  },
  {
    step: 'setPassword',
    previous: () => ({ flow, step: 'yourName' }),
    nextStep: () => ({ href: '/my/dashboard' }),
    stepNumber: '2.6',
  },
  {
    step: 'signIn',
    previous: () => ({ flow, step: 'yourEmail' }),
    nextStep: () => ({ href: '/my/dashboard' }),
    stepNumber: '2.3.1',
  },
];
