import React from 'react';
import { UsStateSelect } from '@lendinghome/core/components';
import { string, bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function PropertyStateInput({ onChange, value, disabled }) {
  return (
    <UsStateSelect
      name="addressState"
      disabled={disabled}
      placeholder="Select a State"
      value={value}
      onChange={onChange}
      searchable
      // exclude US territories
      filter={(state) => state.status === 0}
    />
  );
}

PropertyStateInput.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};

PropertyStateInput.defaultProps = {
  value: '',
  disabled: false,
};

export default FunnelStepForm({
  Input: PropertyStateInput,
  submitOnChange: true,
});
