import React from 'react';
import { string, bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import FunnelStepForm from '../../hoc/FunnelStepForm';
import VerifyingNameField from '../../VerifyingNameField';

function EntityNameInput({ onChange, value, disabled }) {
  function setEntityName({ target }) {
    onChange(target.value);
  }

  return (
    <Fieldset>
      <VerifyingNameField
        label="Entity Name"
        htmlFor="entityName"
        onChange={setEntityName}
        value={value}
        disabled={disabled}
        autoFocus
      />
    </Fieldset>
  );
}

EntityNameInput.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};

EntityNameInput.defaultProps = {
  value: '',
  disabled: false,
};

const validator = VerifyingNameField.validator;

export default FunnelStepForm({
  Input: EntityNameInput,
  submitOnChange: false,
  isSubmittable: (value) => value && validator(value),
});
