import moment from 'moment';

export default class FutureDate {
  constructor({ message = 'Must be a date in the future', priority = null }) {
    this.priority = priority;
    this.message = message;
  }

  checkValue(value) {
    try {
      const dateValue = moment(value, 'MM/DD/YYYY');
      return dateValue.isAfter(moment());
    } catch (e) {
      return false;
    }
  }

  isValid(value) {
    return this.checkValue(value);
  }
}
