import React, { Component } from 'react';
import { SvgIcon } from '@lendinghome/components-core';
import kiaviLogoWithTagline from '../images/landing/kiavi_horz_logo_with_tag-color.svg';

export default class PageFooter extends Component {
  render() {
    const copyrightYear = new Date().getFullYear();
    const marketingSite = gon.MARKETING_SITE;
    const companyName = 'Kiavi Funding Inc.';
    const companyAddress = 'Kiavi. 2 Allegheny Center, Nova Tower 2, Pittsburgh, PA 15212';

    return (
      <footer className="footer--container">
        <section className="footer--content">
          <div className="footer--privacy-links-container">
            <a
              className="footer--link"
              target="_blank"
              rel="noopener noreferrer"
              href={`${marketingSite}/legal/terms`}
            >
              Terms of Service
            </a>
            <a
              className="footer--link"
              target="_blank"
              rel="noopener noreferrer"
              href={`${marketingSite}/legal/privacy`}
            >
              Privacy Policy
            </a>
            <a
              className="footer--link"
              target="_blank"
              rel="noopener noreferrer"
              href={`${marketingSite}/legal/disclosures`}
            >
              Disclosures
            </a>
          </div>
          <div className="footer--detail">
            Copyright &copy;
            {copyrightYear} {companyAddress}. All rights reserved. {companyName} is an Equal Housing Lender.
            <br />
            <a
              className="footer--link"
              href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1125207"
            >
              NMLS ID #1125207 nmlsconsumeraccess.org.
            </a>
          </div>
        </section>
        <section className="footer--logo">
          <SvgIcon
            icon="kiavi_logo_with_tagline"
            className="is-illustration"
            source={kiaviLogoWithTagline}
          />
        </section>
      </footer>
    );
  }
}
