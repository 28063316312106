import omit from 'lodash/omit';
import get from 'lodash/get';

import { getDisplayName } from '@lendinghome/utility-belt-react';
import createBatchingReducer from './createBatchingReducer';

/**
 * Create a reducer that acts on a hash of ids to values at that id (like a resource, for example).
 * Search the codebase for example usages. This is a higher order reducer.
 * Supports batching using createBatchingReducer to batch load a collection of items
 * @param {Function} reducer A reducer that acts on the state of an individual value.
 * @param {Function} getIdFromAction A function that gets the id of the value this action wants to act on.
 * @returns {Function} The indexedReducer.
 */
function createIndexedReducer(reducer, getIdFromAction = ({ id }) => id, { trackOrder = false } = {}) {
  function indexedReducer(state = { __meta: {} }, action = null) {
    const { __meta: meta } = state;

    const id = getIdFromAction(action);
    if (id === undefined || id === null) return state;

    if (trackOrder) {
      meta.order = Array.isArray(meta.order) && !meta.order.indexOf(id) >= 0 ? meta.order.concat(id) : [id];
    }

    const nextValue = reducer(state[id], action);
    return nextValue === state[id] ? state : { ...state, [id]: nextValue, __meta: meta };
  }
  indexedReducer.displayName = `indexedReducer(${getDisplayName(reducer)})`;
  return createBatchingReducer(indexedReducer);
}

// Selector to retrieve indexed values at a particular state key path in `Object.entries` format;
//
// Uses __meta.order to guarantee values are returned in the order they were added, if necessary
//
createIndexedReducer.getEntriesForKey = (keyPath) => (state) => {
  const stateValue = get(state, keyPath);
  const { __meta: { order } = {} } = stateValue;

  // Return array of values guaranteeing order
  if (Array.isArray(order)) {
    return order.map((itemKey) => [itemKey, stateValue[itemKey]]);
  }

  return Object.entries(omit(stateValue, '__meta'));
};

export default createIndexedReducer;
