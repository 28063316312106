import React from 'react';
import Constants from '@lendinghome/components-core/constants';
import Modal from '@lendinghome/core/components/Modal';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';

function isMobile() {
  return window.innerWidth <= Constants.breakpoints.mobile_max;
}

function renderItem({ original }) {
  // eslint-disable-line react/prop-types
  const style = {
    backgroundImage: `url(${original})`,
  };

  return (<div
    className="box is-box-4x3"
    style={style}
  />);
}

function renderThumbInner({ original }) {
  // eslint-disable-line react/prop-types
  const style = {
    backgroundImage: `url(${original})`,
  };

  return (<div
    className="box is-box-square is-box-cover"
    style={style}
  />);
}

/**
 * Gallery show on loan details when images are clicked
 *
 * on mobile, opens directly in fullscreen mode
 * else opens in a modal
 */
export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.onKeypress = this.onKeypress.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeypress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeypress);
  }

  onKeypress(e) {
    if (e.keyCode === Constants.keycodes.esc) {
      this.props.onCloseAsk();
    }
  }

  renderFullscreen() {
    return (
      <ImageGallery
        items={this.props.items}
        ref={(el) => el && el.fullScreen()}
        startIndex={this.props.startIndex || 0}
        useBrowserFullscreen
        showPlayButton={false}
        onScreenChange={(el) => el || this.props.onCloseAsk()}
      />
    );
  }

  renderInModal() {
    return (
      <Modal
        ref={(el) => el && el.show()}
        onRequestClose={this.props.onCloseAsk}
      >
        <ImageGallery
          renderItem={renderItem}
          renderThumbInner={renderThumbInner}
          items={this.props.items}
          startIndex={this.props.startIndex || 0}
          showFullscreenButton={false}
          showPlayButton={false}
        />
      </Modal>
    );
  }

  render() {
    if (isMobile()) {
      return this.renderFullscreen();
    }
    return this.renderInModal();
  }
}

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  startIndex: PropTypes.number,
  onCloseAsk: PropTypes.func,
};
