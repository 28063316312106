import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// depends on the css defined at
// https://style.lendinghome.com/?javascript#float-inputs

export default function WholeDollarInput(props) {
  const { label, name, onValueChange, ...rest } = props;

  return (
    <div className="float-input">
      <NumberFormat
        name={name}
        className="float-input--input"
        prefix={'$'}
        thousandSeparator
        allowNegative={false}
        defaultValue={'0'}
        isNumericStrings
        decimalScale={0}
        onValueChange={onValueChange}
        {...rest}
      />
      <label
        className="float-input--label"
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
}

WholeDollarInput.defaultProps = {
  onValueChange: () => {},
};

WholeDollarInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  /*
  Callback that takes an argument of the following shape
  {
    formattedValue: '$23,234,235', value after applying formatting
    value: '23234235', non formatted value as numeric string
    floatValue: 23234235 floating point representation. for big numbers it can have exponential syntax
  }
  */
  onValueChange: PropTypes.func,
};
