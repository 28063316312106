import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import { stateNameForCode } from '../../util';
import { propertyStateCode, supportedPropertyStatesList } from '../../selectors';
import PropertyFunnelCake from '../PropertyFunnelCake';
import EmptyFunnelStepForm from '../EmptyFunnelStepForm';
import PropertyFlowFullScreen from '../PropertyFlowFullScreen';

function UnsupportedPropertyStateFullScreen(props) {
  const loanType = {
    bridgeProperty: 'bridge',
  };

  return (
    <PropertyFlowFullScreen headline={`Sorry, we do not currently lend in ${props.propertyStateName}.`}>
      <EmptyFunnelStepForm
        {...props}
        hideNextButton
      >
        <strong>We currently offer {loanType[props.flow]} loans in the following states:</strong>
        <p>{props.supportedPropertyStatesList}.</p>
        <div>You can go back and enter a different property address.</div>
      </EmptyFunnelStepForm>
    </PropertyFlowFullScreen>
  );
}

UnsupportedPropertyStateFullScreen.propTypes = {
  propertyStateName: string,
  supportedPropertyStatesList: string,
};

const mapStateToProps = (state) => ({
  propertyStateName: stateNameForCode(propertyStateCode(state)),
  supportedPropertyStatesList: supportedPropertyStatesList(state),
});

const UnsupportedPropertyState = PropertyFunnelCake({
  InputForm: connect(mapStateToProps)(UnsupportedPropertyStateFullScreen),
  mapValueToAjaxParams: () => null,
  saveToServer: () => null,
  saveFunnelStep: () => {},
});

export default UnsupportedPropertyState;
