import * as localStorage from '@lendinghome/utility-belt-react/localStorage';

function createDebugHelper(pluginInstance) {
  const root = typeof global === 'object' ? global : window;

  root.debugSegmentEventBusPlugin = (enabled = true) => {
    localStorage.setItem('debugSegmentEventBusPluginEnabled', enabled);
    pluginInstance.debugEnabled = enabled;
  };

  const existingValue = localStorage.getItem(
    'debugSegmentEventBusPluginEnabled'
  );
  pluginInstance.debugEnabled = existingValue == true;
}

export default createDebugHelper;
