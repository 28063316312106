import React from 'react';
import { connect } from 'react-redux';
import FunnelCake from '../FunnelCake';
import { checkIfEmailExists } from '../../ajax';
import GetRateFullScreen from '../GetRateFullScreen';
import SlideInfo from '../SlideInfo';
import socialProofQuotes from '../../../../../../gems/core/config/locales/marketing/social_proof_quotes.en.yml';
import { propertyStateCode } from '../../selectors';
import YourEmailInputForm from './inputs/YourEmailInputForm';

function YourEmailFullScreen(props) {
  const { flow, stateCode } = props;

  const quote =
    socialProofQuotes.en.social_proof_quotes.kiavi[stateCode.toLowerCase()] ||
    socialProofQuotes.en.social_proof_quotes.kiavi.default;

  const headline = {
    proBorrower: 'What is your email address?',
    standardBorrower: 'Create an account to save your progress.',
    rentalBorrower: 'Enter your email address to create an account.',
    broker: 'What is your email address?',
  };
  const info = {
    proBorrower:
      'Create an account to view your personalized rate, access our team of loan ' +
      'experts, and manage all your required documents in our easy-to-use dashboard. ' +
      'You will also receive a verification email at this address.',
    standardBorrower: 'Your email address will be your login to access your dashboard.',
    rentalBorrower:
      'Create an account to save all your information and give you easy access ' +
      'to your rate, our team of loan experts, and all your required docs.',
    broker:
      'Your email address will be your login to access your dashboard. ' +
      'We will also send a verification email to this address.',
  };

  return (
    <GetRateFullScreen
      headline={headline[flow]}
      socialProofQuote={quote}
    >
      <SlideInfo>{info[flow]}</SlideInfo>
      <YourEmailInputForm {...props} />
      <SlideInfo>
        <span>Already have an account?&nbsp;</span>
        {/* TODO [mjd] This url should be tabled. */}
        <a href="/users/sign_in">Sign In</a>
      </SlideInfo>
    </GetRateFullScreen>
  );
}

const mapStateToProps = (state) => ({
  stateCode: propertyStateCode(state),
});

const YourEmail = FunnelCake({
  InputForm: connect(mapStateToProps)(YourEmailFullScreen),
  saveToServer: checkIfEmailExists,
});

export default YourEmail;
