import React from 'react';
import Select from '@lendinghome/core/components/Select';
import { object, bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import VerifyingNameField from '../../VerifyingNameField';
import FunnelStepForm from '../../hoc/FunnelStepForm';

const suffixes = ['Sr', 'Jr', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIV', 'XV'];

const suffixOptions = suffixes.map((suffix) => ({
  label: suffix,
  value: suffix,
}));

function YourNameInput({ onChange, value, disabled }) {
  function setFirstName({ target }) {
    onChange({ ...value, firstName: target.value });
  }

  function setLastName({ target }) {
    onChange({ ...value, lastName: target.value });
  }

  function setSuffix(suffix) {
    onChange({ ...value, suffix });
  }

  return (
    <div className="your-name">
      <Fieldset fieldsetLayout="two-column">
        <VerifyingNameField
          label="First Name"
          htmlFor="firstName"
          onChange={setFirstName}
          value={value.firstName}
          disabled={disabled}
          autoFocus
          maxLength="255"
        />
        <VerifyingNameField
          label="Last Name"
          htmlFor="lastName"
          onChange={setLastName}
          value={value.lastName}
          disabled={disabled}
          maxLength="255"
        />
      </Fieldset>
      <Fieldset fieldsetLayout="two-column">
        <fieldset className="float-input">
          <Select
            name="suffix"
            className="suffix"
            options={suffixOptions}
            disabled={disabled}
            placeholder="Suffix (Optional)"
            value={value.suffix}
            onChange={setSuffix}
            searchable
          />
        </fieldset>
      </Fieldset>
    </div>
  );
}

YourNameInput.propTypes = {
  onChange: func.isRequired,
  value: object,
  disabled: bool,
};

YourNameInput.defaultProps = {
  value: { firstName: '', lastName: '', suffix: '' },
  disabled: false,
};

function areValidNames({ firstName, lastName }) {
  const validator = VerifyingNameField.validator;

  return firstName && lastName && validator(firstName) && validator(lastName);
}

export default FunnelStepForm({
  Input: YourNameInput,
  submitOnChange: false,
  isSubmittable: (value) => value && areValidNames(value),
});
