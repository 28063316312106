export const FunnelFlowVersions = {
  DEFAULT: 'bizPurpose.default',
  BRIDGE_WITH_EARLIER_PROPERTY_ADDRESS: 'bizPurpose.bridgeWithEarlierPropertyAddress',
};

export const LobErrorCodes = {
  ERROR: 'ERROR',
  CC: 'CC',
  N1: 'N1',
  M3: 'M3',
  M1: 'M1',
  P1: 'P1',
  P3: 'P3',
  A1: 'A1',
};
