import { rentalPortfolio } from '../routes';

export default [
  {
    step: 'loanProgram',
    nextStep: (state) => {
      if (state.borrower.isRentalProgram()) {
        return { flow: 'rentalBorrower', step: 'dealsLast24' };
      }
      if (state.borrower.isRentalPortfolio()) {
        return { href: rentalPortfolio() };
      }
      if (state.project.isInfill()) {
        return { flow: 'getRate', step: 'infillExitsLast60' };
      }
    },
    stepNumber: '1.1',
  },
  {
    step: 'exitsLast24',
    nextStep: (state) => {
      const proOrStandard = state.borrower.isPro() ? 'proBorrower' : 'standardBorrower';

      const enableBlockBorrowersWithZeroExits =
        state.split.getTreatment('enable_block_borrowers_with_zero_exits') === 'on';

      if (state.borrower.hasZeroExits() && enableBlockBorrowersWithZeroExits) {
        return { flow: 'getRate', step: 'unsupportedBorrowerZeroExits' };
      }

      return { flow: proOrStandard, step: 'propertyState' };
    },
    stepNumber: '1.2',
  },
  {
    step: 'infillExitsLast60',
    nextStep: (state) => {
      if (state.borrower.hasEnoughInfillExperience()) {
        return { flow: 'proBorrower', step: 'propertyState' };
      }

      return { flow: 'getRate', step: 'unsupportedBorrowerInfillExits' };
    },
    stepNumber: '1.3',
    previous: () => ({ flow: 'getRate', step: 'loanProgram' }),
  },
  {
    step: 'unsupportedBorrowerInfillExits',
    guard: (state) => !state.borrower.hasEnoughInfillExperience(),
    terminal: true,
    stepNumber: '1.3.1',
  },
];
