import Pattern from './Pattern';

/*

The Entity information number is a nine-digit number in the format "AA-SSSSSSS".
The number is divided into two parts.
*/

export default class EIN extends Pattern {
  constructor({ message = 'Invalid EIN. Please use a valid 9 digit EIN.' }) {
    super({
      pattern: /^\d{2}-?\d{7}$/,
      message,
    });
  }

  isValid(value) {
    const retVal = super.isValid(value);
    if (!retVal) {
      Frogger && Frogger.debug(`Invalid EIN specified: ${value}`);
    }
    return retVal;
  }
}
