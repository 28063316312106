import FunnelStepForm from './hoc/FunnelStepForm';

function EmptyComponent() {
  return null;
}

export default FunnelStepForm({
  Input: EmptyComponent,
  isSubmittable: () => true,
});
