import React from 'react';
import { func } from 'prop-types';
import DancingHouse from '../DancingHouse';

const funFacts = [
  'A loan-to-value (LTV) helps the lender assess potential risk. This ratio is calculated by the mortgage amount divided by the appraised property value.',
  'A loan-to-cost (LTC) is the ratio comparing the loan amount to the total purchase price of the property.',
  'There are many different types of entities, such as: an LLC, a limited partnership, a corporation, a revocable trust, and more.',
  'The capitalization rate is used to measure the length of time it will take for an investment to pay itself off.',
  'A rehab holdback is a process where the rehab/renovation funds are held and then released to reimburse a borrower for documented and approved construction work.',
];

export default class SubmitFullScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTakingExtraLong: false,
      loadingHeader: 'One Moment Please...',
      loadingText: '',
      funFactIdx: 0,
    };
  }

  componentDidMount() {
    this.props.onComplete();

    // change the loading text after 15 sec
    setTimeout(() => {
      // rotate the fun fact after every 10 sec
      const intervalId = setInterval(() => {
        const nextFunFactIdx = (this.state.funFactIdx + 1) % funFacts.length;
        this.setState(() => ({
          funFactIdx: nextFunFactIdx,
          loadingText: funFacts[nextFunFactIdx],
        }));
      }, 10000);

      this.setState((prevState) => ({
        intervalId,
        isTakingExtraLong: true,
        loadingHeader: 'Still working...',
        loadingText: funFacts[prevState.funFactIdx],
      }));
    }, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    let loadingText = null;

    if (this.state.isTakingExtraLong) {
      loadingText = (
        <div className="loading-text">
          <h6>DID YOU KNOW?</h6>
          <p>{this.state.loadingText}</p>
        </div>
      );
    }

    return (<DancingHouse
      loadingHeader={this.state.loadingHeader}
      loadingText={loadingText}
    />);
  }
}

SubmitFullScreen.defaultProps = {
  onComplete: () => {},
};

SubmitFullScreen.propTypes = {
  onComplete: func,
};
