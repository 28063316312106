import React from 'react';
import { connect } from 'react-redux';
import { Header } from '@lendinghome/core/components';
import { string, node, shape, number, oneOfType } from 'prop-types';
import classnames from 'classnames';
import {
  flowName as getFlowName,
  referral as getReferral,
  lowestInterestRates as getLowestInterestRates,
} from '../selectors';
import SocialProof from './SocialProof';
import LandingFooter from './LandingFooter';

function GetRateFullScreen({ headline, className, children, socialProofQuote, slideBackground }) {
  const phone = gon.global.phone.tollfreeMarketing;

  return (
    <section
      className={classnames(
        className,
        'landing is-rebrand flow-biz-purpose-borrower-flow-config layout-new-style-default'
      )}
    >
      <Header
        className="get-rate-flow-header"
        logoIcon={'kiavi-logo'}
        phoneNumber={phone}
      />
      <div className={classnames('slide', slideBackground)}>
        <section className="flow-step">
          <h1 className="slide-topic">{headline}</h1>
          {children}
        </section>
        {socialProofQuote && <SocialProof {...socialProofQuote} />}
      </div>
      <LandingFooter />
    </section>
  );
}

GetRateFullScreen.defaultProps = {
  headline: null,
  className: null,
  children: null,
  flowName: null,
  referral: null,
  slideBackground: null,
};

GetRateFullScreen.propTypes = {
  headline: node,
  className: string,
  children: node,
  flowName: string,
  referral: shape({
    referrer: string,
    refereeReward: oneOfType([string, number]),
  }),
  lowestInterestRates: shape({
    hard_money: number,
    rental: number,
  }).isRequired,
  slideBackground: string,
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  flowName: getFlowName(state),
  referral: getReferral(state),
  lowestInterestRates: getLowestInterestRates(state),
});

export default connect(mapStateToProps)(GetRateFullScreen);
