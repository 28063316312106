export const LOAD_GLOBAL_DATA = '@lendinghome/utility-belt/globalData/LOAD_GLOBAL_DATA';

const loadData = (key, value) => ({
  type: LOAD_GLOBAL_DATA,
  key,
  value,
});

const globalDataLoader = (globalObj) => (...keys) => (dispatch) => {
  const transformFn = keys[0];

  if (typeof transformFn === 'function') {
    const objectsToLoad = transformFn(globalObj);

    for (const [key, value] of Object.entries(objectsToLoad)) {
      dispatch(loadData(key, value));
    }

    return;
  }

  for (const key of keys) {
    if (globalObj[key]) {
      dispatch(loadData(key, globalObj[key]));
    }
  }
};

export default function globalDataReducer(mountKey, initialState = {}, reducer = (state) => state) {
  return (state, action) => {
    if (action.type === LOAD_GLOBAL_DATA && mountKey === action.key) {
      return {
        ...state,
        ...action.value,
      };
    }

    return reducer(state || initialState, action);
  };
}

globalDataReducer.loadData = loadData;
globalDataReducer.createLoader = globalDataLoader;
