import isNil from 'lodash/isNil';

export default class RequiredValidator {
  constructor({ message = 'This field is required', priority = 0 } = {}) {
    this.priority = priority;
    this.message = message;
  }

  isValid(value) {
    return !isNil(value) && value !== '';
  }
}
