import React from 'react';
import { string, element, oneOfType } from 'prop-types';
import * as kiaviLoading from '../images/kiavi-loading-v1.gif';

export default function DancingHouse({ loadingHeader, loadingText }) {
  return (
    <div className="step--underwrite-in-progress">
      <img
        className="loading-animation"
        src={kiaviLoading}
        alt="loading"
      />
      <h1>{loadingHeader}</h1>
      {loadingText}
    </div>
  );
}

DancingHouse.defaultProps = {
  loadingHeader: 'One Moment Please...',
  loadingText: null,
};

DancingHouse.propTypes = {
  loadingHeader: oneOfType([string, element]),
  loadingText: oneOfType([string, element]),
};
