import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import SlideInfo from '../SlideInfo';
import SignInSubmitter from './inputs/SignInSubmitter';

function SignInFullScreen(props) {
  const { flow } = props;

  const headline = {
    proBorrower: 'You already have an account. Please login to continue.',
    standardBorrower: 'It looks like an account with that email address already exists.',
    rentalBorrower: 'You already have an account. Please login to continue.',
    broker: 'You already have an account. Please login to continue.',
  };
  const info = {
    standardBorrower: 'Please enter your password for the account.',
  };

  // TODO:
  // Add forgot password button.  It appears it only exists in the standard borrower flow, and does some
  // non-reusable things that depend on the get rate code.

  return (
    <GetRateFullScreen headline={headline[flow]}>
      <SlideInfo>{info[flow]}</SlideInfo>
      <SignInSubmitter {...props} />
    </GetRateFullScreen>
  );
}

const SignIn = FunnelCake({
  InputForm: SignInFullScreen,
  saveToServer: () => {},
  mapValueToAjaxParams: () => ({}),
});

export default SignIn;
