import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { number, func } = PropTypes;

function generateNumber(max, min) {
  return Math.random() * (max - min) + min;
}

class TweenNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: generateNumber(props.min, props.max),
    };
  }

  componentDidMount() {
    this.startAnimating();
  }

  startAnimating() {
    window.requestAnimationFrame((timestamp) => {
      this.tweenStart = timestamp;
      this.keepAnimating(timestamp);
    });
  }

  keepAnimating(timestamp) {
    const progress = timestamp - this.tweenStart;
    if (progress >= this.props.duration) {
      return this.stopAnimating();
    }

    this.setState({
      number: generateNumber(this.props.min, this.props.max),
    });

    return window.requestAnimationFrame((_timestamp) =>
      this.keepAnimating(_timestamp)
    );
  }

  stopAnimating() {
    this.props.onComplete();
    this.tweenStart = null;
  }

  render() {
    return this.props.children(this.state.number);
  }
}

TweenNumber.propTypes = {
  value: number,
  duration: number,
  min: number,
  max: number,
  onComplete: func,
};

TweenNumber.defaultProps = {
  duration: 1000,
  min: 1,
  max: 100,
  onComplete: () => {},
};

export default class AnimateNumberChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTweening: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        isTweening: true,
      });
    }
  }

  render() {
    const { value, duration, min, max } = this.props;

    if (this.state.isTweening) {
      return (
        <TweenNumber
          {...{ duration, min, max }}
          onComplete={() => this.setState({ isTweening: false })}
        >
          {(tween) => this.props.children(tween)}
        </TweenNumber>
      );
    }

    return this.props.children(value);
  }
}

AnimateNumberChange.propTypes = {
  value: number,
  duration: number,
  min: number,
  max: number,
};
