import React from 'react';
import MaskedInput from 'react-text-mask';
import zipValidator from '../validators/zipValidator';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';

function ZipInput(props) {
  function handleChange({ target }) {
    props.onChange(target.value);
  }

  return (
    <MaskedInput
      {...props}
      // Limitation of text-mask component forces us to use a blank unicode
      // char instead of "space"
      // (see: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#placeholderchar)
      placeholderChar={'\u2000'}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      type="text"
      onChange={handleChange}
    />
  );
}

const VerifyingZipInput = CreateVerifyingTextField({
  Input: ZipInput,
  validator: zipValidator,
  defaultInvalidMessage: 'Please enter a valid ZIP code',
});

VerifyingZipInput.validator = zipValidator;

export default VerifyingZipInput;
