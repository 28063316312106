// these map to lh-ui severity levels (css $states variable)
export const uiStates = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
  CAUTION: 'caution',
};

export const uiBreakpoints = {
  MOBILE_MIN: 750,
  MOBILE_MAX: 768,
  TABLET_MAX: 1024,
};

export const uiViewportModes = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

export const uiPosition = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

export const uiOrientation = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const keyboard = {
  ESCAPE: 27,
  ENTER: 13,
};

export const defaultDebounceOnChangeWait = 250;

/**
 * List of US States
 * More info: https://www.census.gov/geo/reference/ansi_statetables.html
 * funding: Refers to when the funds are disbursed. wet: the same day of closing. dry: few biz days after closing/contracts
 */
export const UsStates = [
  { name: 'Alabama', usps_code: 'AL', fips_code: '01', status: 0, funding: 'wet' },
  { name: 'Alaska', usps_code: 'AK', fips_code: '02', status: 0, funding: 'dry' },
  { name: 'Arizona', usps_code: 'AZ', fips_code: '04', status: 0, funding: 'dry' },
  { name: 'Arkansas', usps_code: 'AR', fips_code: '05', status: 0, funding: 'wet' },
  { name: 'California', usps_code: 'CA', fips_code: '06', status: 0, funding: 'dry' },
  { name: 'Colorado', usps_code: 'CO', fips_code: '08', status: 0, funding: 'wet' },
  { name: 'Connecticut', usps_code: 'CT', fips_code: '09', status: 0, funding: 'wet' },
  { name: 'Delaware', usps_code: 'DE', fips_code: '10', status: 0, funding: 'wet' },
  { name: 'District of Columbia', usps_code: 'DC', fips_code: '11', status: 0, funding: 'wet' },
  { name: 'Florida', usps_code: 'FL', fips_code: '12', status: 0, funding: 'wet' },
  { name: 'Georgia', usps_code: 'GA', fips_code: '13', status: 0, funding: 'wet' },
  { name: 'Hawaii', usps_code: 'HI', fips_code: '15', status: 0, funding: 'dry' },
  { name: 'Idaho', usps_code: 'ID', fips_code: '16', status: 0, funding: 'dry' },
  { name: 'Illinois', usps_code: 'IL', fips_code: '17', status: 0, funding: 'wet' },
  { name: 'Indiana', usps_code: 'IN', fips_code: '18', status: 0, funding: 'wet' },
  { name: 'Iowa', usps_code: 'IA', fips_code: '19', status: 0, funding: 'wet' },
  { name: 'Kansas', usps_code: 'KS', fips_code: '20', status: 0, funding: 'wet' },
  { name: 'Kentucky', usps_code: 'KY', fips_code: '21', status: 0, funding: 'wet' },
  { name: 'Louisiana', usps_code: 'LA', fips_code: '22', status: 0, funding: 'wet' },
  { name: 'Maine', usps_code: 'ME', fips_code: '23', status: 0, funding: 'wet' },
  { name: 'Maryland', usps_code: 'MD', fips_code: '24', status: 0, funding: 'wet' },
  { name: 'Massachusetts', usps_code: 'MA', fips_code: '25', status: 0, funding: 'wet' },
  { name: 'Michigan', usps_code: 'MI', fips_code: '26', status: 0, funding: 'wet' },
  { name: 'Minnesota', usps_code: 'MN', fips_code: '27', status: 0, funding: 'wet' },
  { name: 'Mississippi', usps_code: 'MS', fips_code: '28', status: 0, funding: 'wet' },
  { name: 'Missouri', usps_code: 'MO', fips_code: '29', status: 0, funding: 'wet' },
  { name: 'Montana', usps_code: 'MT', fips_code: '30', status: 0, funding: 'wet' },
  { name: 'Nebraska', usps_code: 'NE', fips_code: '31', status: 0, funding: 'wet' },
  { name: 'Nevada', usps_code: 'NV', fips_code: '32', status: 0, funding: 'dry' },
  { name: 'New Hampshire', usps_code: 'NH', fips_code: '33', status: 0, funding: 'wet' },
  { name: 'New Jersey', usps_code: 'NJ', fips_code: '34', status: 0, funding: 'wet' },
  { name: 'New Mexico', usps_code: 'NM', fips_code: '35', status: 0, funding: 'dry' },
  { name: 'New York', usps_code: 'NY', fips_code: '36', status: 0, funding: 'wet' },
  { name: 'North Carolina', usps_code: 'NC', fips_code: '37', status: 0, funding: 'wet' },
  { name: 'North Dakota', usps_code: 'ND', fips_code: '38', status: 0, funding: 'wet' },
  { name: 'Ohio', usps_code: 'OH', fips_code: '39', status: 0, funding: 'wet' },
  { name: 'Oklahoma', usps_code: 'OK', fips_code: '40', status: 0, funding: 'wet' },
  { name: 'Oregon', usps_code: 'OR', fips_code: '41', status: 0, funding: 'dry' },
  { name: 'Pennsylvania', usps_code: 'PA', fips_code: '42', status: 0, funding: 'wet' },
  { name: 'Rhode Island', usps_code: 'RI', fips_code: '44', status: 0, funding: 'wet' },
  { name: 'South Carolina', usps_code: 'SC', fips_code: '45', status: 0, funding: 'wet' },
  { name: 'South Dakota', usps_code: 'SD', fips_code: '46', status: 0, funding: 'wet' },
  { name: 'Tennessee', usps_code: 'TN', fips_code: '47', status: 0, funding: 'wet' },
  { name: 'Texas', usps_code: 'TX', fips_code: '48', status: 0, funding: 'wet' },
  { name: 'Utah', usps_code: 'UT', fips_code: '49', status: 0, funding: 'wet' },
  { name: 'Vermont', usps_code: 'VT', fips_code: '50', status: 0, funding: 'wet' },
  { name: 'Virginia', usps_code: 'VA', fips_code: '51', status: 0, funding: 'wet' },
  { name: 'Washington', usps_code: 'WA', fips_code: '53', status: 0, funding: 'dry' },
  { name: 'West Virginia', usps_code: 'WV', fips_code: '54', status: 0, funding: 'wet' },
  { name: 'Wisconsin', usps_code: 'WI', fips_code: '55', status: 0, funding: 'wet' },
  { name: 'Wyoming', usps_code: 'WY', fips_code: '56', status: 0, funding: 'wet' },
  { name: 'American Samoa', usps_code: 'AS', fips_code: '60', status: 1, funding: 'wet' },
  { name: 'Guam', usps_code: 'GU', fips_code: '66', status: 1, funding: 'wet' },
  {
    name: 'Commonwealth of the Northern Mariana Islands',
    usps_code: 'MP',
    fips_code: '69',
    status: 1,
    funding: 'wet',
  },
  { name: 'Puerto Rico', usps_code: 'PR', fips_code: '72', status: 1, funding: 'wet' },
  { name: 'U.S. Virgin Islands', usps_code: 'VI', fips_code: '78', status: 1, funding: 'wet' },
  {
    name: 'U.S. Minor Outlying Islands',
    usps_code: 'UM',
    fips_code: '74',
    status: 2,
    funding: 'wet',
  },
  {
    name: 'Federated States of Micronesia',
    usps_code: 'FM',
    fips_code: '64',
    status: 3,
    funding: 'wet',
  },
  { name: 'Marshall Islands', usps_code: 'MH', fips_code: '68', status: 3, funding: 'wet' },
  { name: 'Palau', usps_code: 'PW', fips_code: '70', status: 3, funding: 'wet' },
];

/**
 * List of property unit types
 */
export const PropertyUnitTypes = [
  {
    name: 'Single Family',
    value: 'single-family',
  },
  {
    name: 'Detached-PUD',
    value: 'detached-pud',
  },
  {
    name: 'Attached-PUD',
    value: 'attached-pud',
  },
  {
    name: 'Condo',
    value: 'condo',
  },
  {
    name: '2-4 Unit',
    value: '2-4plex',
  },
  {
    name: 'Manufactured Home',
    value: 'manufactured-or-mobile-home',
  },
];

/**
 * List of loan programs/products LH offers
 */
export const LoanProgram = {
  HARD_MONEY: 'hard_money',
  RENTAL: 'rental',
  RENTAL_PORTFOLIO: 'rental_portfolio',
  HARD_MONEY_INFILL: 'hard_money_infill',
};

export const RehabType = {
  INFILL: 'Infill',
};

export const LoanProduct = {
  [LoanProgram.HARD_MONEY]: {
    LH_BRIDGE_12M_FIXED: 'lh_bridge_12m_fixed',
    LH_BRIDGE_JUMBO_12M_FIXED: 'lh_bridge_jumbo_12m_fixed',
    LH_BRIDGE_PRO_12M_FIXED: 'lh_bridge_pro_12m_fixed',
  },
  [LoanProgram.RENTAL]: {
    LH_RENTAL_3_1_ARM: 'lh_rental_3_1_arm',
    LH_RENTAL_5_1_ARM: 'lh_rental_5_1_arm',
    LH_RENTAL_7_1_ARM: 'lh_rental_7_1_arm',
    LH_RENTAL_30Y_FIXED: 'lh_rental_30y_fixed',
  },
};

export const HardMoneyLoanProduct = LoanProduct[LoanProgram.HARD_MONEY];
export const RentalLoanProduct = LoanProduct[LoanProgram.RENTAL];
