import React, { Component } from 'react';
import { ensureChildrenHaveKeys } from '@lendinghome/utility-belt-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { uiPosition } from '../constants';

const LARGE_SCREEN_BREAKPOINT = 1024;
const RESIZE_DEBOUNCE_TIME = 1000;

export default class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      windowWidth: window.innerWidth,
    };
  }

  componentWillMount() {
    this.handleResize = debounce(this.handleResize.bind(this), RESIZE_DEBOUNCE_TIME);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onMouseOver = (e) => {
    this.setState({ active: true });
    if (typeof this.props.onMouseOver === 'function') {
      this.props.onMouseOver(e);
    }
  };

  onMouseOut = (e) => {
    this.setState({ active: false });
    if (typeof this.props.onMouseOut === 'function') {
      this.props.onMouseOut(e);
    }
  };

  onClick = (e) => {
    e.preventDefault();
    this.setState((currentState) => ({ active: !currentState.active }));
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(e);
    }
  };

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  renderTooltip() {
    const { position = uiPosition.BOTTOM, desc, safeDesc, ...rest } = this.props;
    const names = classNames('tip', {
      [`is-tip-${position}`]: position,
      'is-tip-active': this.state.active,
      'is-tip-wide': this.props.isTipWide,
      'is-tip-dark': this.props.isTipDark,
    });

    const descClassName = classNames('tip--content', 'tip--content--small');

    return (
      <aside
        className={names}
        {...rest}
      >
        {desc ? (
          <div
            className={descClassName}
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        ) : (
          <div className={descClassName}>{safeDesc}</div>
        )}
      </aside>
    );
  }

  shouldUnderline = (children) => this.props.underline || children.length > 1 || typeof children.type === 'string';

  render() {
    const tagName = this.props.tag || 'div';
    const wrapperProps = {};
    const classes = [
      'has-tip',
      this.shouldUnderline(this.props.children) ? 'underlined' : null,
      this.props.staticPositioned ? 'static-positioned' : null,
    ];

    if (this.state.windowWidth < LARGE_SCREEN_BREAKPOINT) {
      wrapperProps.onClick = this.onClick;
      classes.push('is-tip-mobile');
    } else {
      wrapperProps.onMouseOver = this.onMouseOver;
      wrapperProps.onMouseOut = this.onMouseOut;
    }

    wrapperProps.className = classNames(classes);

    if (this.props.disabled) {
      return <div>{this.props.children}</div>;
    }

    return React.createElement(
      tagName,
      wrapperProps,
      ensureChildrenHaveKeys([this.props.children, this.renderTooltip()])
    );
  }
}

Tooltip.propTypes = {
  isTipDark: PropTypes.bool,
  isTipWide: PropTypes.bool,
  desc: PropTypes.string,
  position: PropTypes.oneOf(Object.values(uiPosition)),
  children: PropTypes.node,
  tag: PropTypes.string,
  disabled: PropTypes.bool,
  safeDesc: PropTypes.node,
  underline: PropTypes.bool,
  staticPositioned: PropTypes.bool,
};
