import * as steps from '../../components/steps';

const flow = 'bridgePropertyPreCalc';

const bridgePropertyPreCalcRoutes = [
  {
    flow,
    step: 'propertyAddress',
    component: steps.PropertyAddressBridgePreCalc,
    stepUrl: 'index',
    withLoanId: true,
  },
  {
    flow,
    step: 'propertyAddress',
    component: steps.PropertyAddressBridgePreCalc,
    withLoanId: true,
  },
  {
    flow,
    step: 'unsupportedPropertyState',
    component: steps.UnsupportedPropertyState,
    withLoanId: true,
  },
];

export default bridgePropertyPreCalcRoutes;
