if (Promise && !Promise.allSettled) {
  Promise.allSettled = function(promises) {
    let mappedPromises = promises.map(
      (promise) =>
        promise &&
        promise
          .then((data) => ({
            status: 'fulfilled',
            data,
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason,
          }))
    );
    return Promise.all(mappedPromises);
  };
}
