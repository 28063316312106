export default class MinLength {
  constructor({
    minLength,
    message = `Must be at least ${minLength} characters`,
    priority = null,
  }) {
    this.priority = priority;
    this.minLength = minLength;
    this.message = message;
  }

  checkLength(value) {
    return value && value.length >= this.minLength;
  }

  isValid(value) {
    return this.checkLength(value);
  }
}
