import { GATEWAY_TIMEOUT_MSG } from './constants';
import LendingHomeError from './lendingHomeError';

// << LendingHomeGatewayTimeoutError >>
// Throwing LendingHomeGatewayTimeoutError indicates that a server API returned an error response.
// You MUST populate the `options` object with the api response object, so that consumers
// can implement determinative behavior based on response code.
export default class LendingHomeGatewayTimeoutError extends LendingHomeError {
  constructor(...args) {
    super(GATEWAY_TIMEOUT_MSG, ...args);
    this.name = 'LendingHomeGatewayTimeoutError';
    const warningMessage = 'LendingHomeGatewayTimeoutError constructor must be called with a response object!';

    if (typeof this.options === 'object' && this.options.response) {
      const { ok, redirected, status, statusText, type, url, bodyUsed } = this.options.response;
      this.response = this.options.response;
      this.data.push({
        response: {
          ok,
          redirected,
          status,
          statusText,
          type,
          url,
          bodyUsed,
        },
      });
    } else if (window.Frogger) {
      Frogger.warn(warningMessage);
    } else if (window.console && window.console.warn) {
      window.console.warn(warningMessage);
    }
  }
}
