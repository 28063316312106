import React from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';

export default function SlideInfo({ className, children }) {
  if (children) {
    return <div className={classnames(className, 'slide-info')}>{children}</div>;
  }
  return null;
}

SlideInfo.defaultProps = {
  className: null,
  children: null,
};

SlideInfo.propTypes = {
  className: string,
  children: node,
};
