import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import SlideInfo from '../SlideInfo';
import EntityInfoInputForm from './inputs/EntityInfoInputForm';

const info =
  'If you use multiple business entities, please provide the full legal name of the one you used most recently';

function EntityInfoFullScreen(props) {
  return (
    <GetRateFullScreen headline={'What’s the business entity name?'}>
      <SlideInfo>{info}</SlideInfo>
      <EntityInfoInputForm {...props} />
    </GetRateFullScreen>
  );
}

const EntityInfo = FunnelCake({
  InputForm: EntityInfoFullScreen,
});

export default EntityInfo;
