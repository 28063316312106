import { globalDataReducer } from '@lendinghome/utility-belt';
import { combineReducers } from 'redux';
import * as actions from './actions';

export function funnelStepsReducer(state = {}, action) {
  switch (action.type) {
    case actions.SET_FUNNEL_STEP_VALUE:
      return {
        ...state,
        [action.step]: action.value,
      };
    default:
      return state;
  }
}

export function funnelMetaDataReducer(state = {}, action) {
  switch (action.type) {
    case actions.SET_FUNNEL_META_DATA:
      if (action.value instanceof Object && !(action.value instanceof Array)) {
        return {
          ...state,
          [action.key]: { ...state[action.key], ...action.value },
        };
      }

      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
}

export default combineReducers({
  funnelSteps: funnelStepsReducer,
  funnelMetaData: funnelMetaDataReducer,
  googleMapsApiState: globalDataReducer('googleMapsApiState', {
    loaded: false,
  }),
});
