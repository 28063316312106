import * as steps from '../../components/steps';

const proBorrowerRoutes = [
  {
    flow: 'proBorrower',
    step: 'propertyState',
    component: steps.PropertyState,
  },
  { flow: 'proBorrower', step: 'yourName', component: steps.YourName },
  { flow: 'proBorrower', step: 'yourEmail', component: steps.YourEmail },
  { flow: 'proBorrower', step: 'signIn', component: steps.SignIn },
  { flow: 'proBorrower', step: 'yourPhone', component: steps.YourPhone },
  { flow: 'proBorrower', step: 'entity', component: steps.Entity },
  { flow: 'proBorrower', step: 'unsupportedBorrowerNoEntity', component: steps.UnsupportedBorrowerNoEntity },
  { flow: 'proBorrower', step: 'entityInfo', component: steps.EntityInfo },
  {
    flow: 'proBorrower',
    step: 'setPassword',
    component: steps.SetPassword,
  },
  {
    flow: 'proBorrower',
    step: 'checkYourEmail',
    component: steps.CheckYourEmail,
  },
  {
    flow: 'proBorrower',
    step: 'submit',
    component: steps.SubmitPro,
  },
  {
    flow: 'proBorrower',
    step: 'unsupportedUsState',
    component: steps.UnsupportedUsState,
  },
  {
    flow: 'proBorrower',
    step: 'leadPropertyAddress',
    component: steps.PropertAddressLead,
  },
];

export default proBorrowerRoutes;
