import React from 'react';
import { string, bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import VerifyingPasswordInput from '../../VerifyingPasswordInput';
import FunnelStepForm from '../../hoc/FunnelStepForm';

const validator = VerifyingPasswordInput.validator;

function SetPasswordInput({ onChange, value, disabled, isValid, invalidMessage }) {
  function setPassword({ target }) {
    onChange(target.value);
  }

  return (
    <Fieldset>
      <VerifyingPasswordInput
        label="Enter password"
        htmlFor="password"
        name="password"
        onChange={setPassword}
        value={value}
        disabled={disabled}
        isValid={isValid}
        invalidMessage={invalidMessage}
        maxLength={128}
        autoFocus
      />
    </Fieldset>
  );
}

SetPasswordInput.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
  isValid: bool,
  invalidMessage: string,
};

SetPasswordInput.defaultProps = {
  value: '',
  disabled: false,
  isValid: true,
  invalidMessage: 'Password must be 8 to 128 characters, contain a number, upper case letter, and lower case letter.',
};

export default FunnelStepForm({
  Input: SetPasswordInput,
  submitOnChange: false,
  isSubmittable: (value) => value && validator(value),
});
