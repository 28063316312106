import EventBus from '@lendinghome/event-bus';
import { eventTypes as segmentEvents, analyticsKit } from '@lendinghome/segment-event-bus-plugin';
import SegmentEventBusPlugin from '@lendinghome/segment-event-bus-plugin/react';

const identityPayload = analyticsKit.addUserTraitsPayload;

const funnelEventToSegmentPayloadMappings = {
  /*
   * marketing team uses these events for remarketing lists in our ad efforts.
   * More info: https://lendinghome.slack.com/archives/C01THMN4PT6/p1632239176118600
   * All other flow events are tracked on the server via the Segment Ruby SDK
   */
  'borrower.isProPlus': ({ state }) => [
    identityPayload(state.analytics.identityData()),
    {
      eventType: segmentEvents.TRACK,
      eventPayload: {
        event: 'Borrower reported pro/plus',
      },
    },
  ],
  'flow.completed': ({ flow, step, state }) => [
    identityPayload(state.analytics.identityData()),
    {
      eventType: segmentEvents.TRACK,
      eventPayload: {
        event: 'Flow Completed',
        properties: { flow, step },
      },
    },
  ],
  'flow.loaded': ({ flow, step, state }) => [
    identityPayload(state.analytics.identityData()),
    {
      eventType: segmentEvents.TRACK,
      eventPayload: {
        event: 'Flow Loaded',
        properties: { flow, step },
      },
    },
  ],
  // Segment's identify API call is triggered in the client for every step in the m/flow to add
  // new traits entered by the borrower in the funnel. This is used by client-side Segment destinations
  // such as Google Enhanced Conversions.
  'step.completed': ({ flow, step, state }) => [identityPayload(state.analytics.identityData())],
};

const eventBus = analyticsKit.initAnalyticsBus(EventBus, {
  PluginClass: SegmentEventBusPlugin,
  mapper: funnelEventToSegmentPayloadMappings,
});

class SegmentDestination {
  static notify(event, data) {
    return eventBus.trigger(event, data);
  }
}

export default SegmentDestination;
