import React from 'react';
import { VerticalSelectBar } from '@lendinghome/core/components';
import { string, bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function BrokerBridgeInput({ onChange, value, disabled, fieldName }) {
  const options = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  return (
    <VerticalSelectBar
      options={options}
      fieldName={fieldName}
      currentValue={`${value}`}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

BrokerBridgeInput.propTypes = {
  onChange: func.isRequired,
  value: bool,
  disabled: bool,
  fieldName: string,
};

BrokerBridgeInput.defaultProps = {
  disabled: false,
  value: undefined,
  fieldName: 'brokerBridge',
};

export default FunnelStepForm({
  Input: BrokerBridgeInput,
  submitOnChange: true,
  isSubmittable: (value) => value || value === false,
});
