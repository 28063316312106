export default {
  // ---------------------------------------------------------------------------------------
  // getRate steps

  exitsLast24: 'estimate_num_exits_in_last_twenty_four_selected_range',
  infillExitsLast60: 'estimate_num_exits_in_last_sixty',

  // ---------------------------------------------------------------------------------------
  // shared steps

  propertyState: 'address_state',
  holdsRental: 'has_rental',
  howManyRental: 'num_rental_properties_range',
  yourEmail: 'email',
  yourPhone: 'phone_number',
  entity: 'has_entity',
  yourName: {
    firstName: 'first_name',
    lastName: 'last_name',
    suffix: 'suffix',
  },

  // ---------------------------------------------------------------------------------------
  // proBorrower steps

  entityInfo: 'entity_name',
  pastPropertyAddresses: 'has_addresses_of_past_properties',
  moreThanFiftyExits: 'more_than_fifty_exits',
  isRefinance: 'is_refinance',

  // ---------------------------------------------------------------------------------------
  // standardBorrower steps

  purchaseTimeline: 'purchase_timeline',
  // TODO:
  // Do we need to provide a mapping for eligibilityConfirmation?  It may be sufficient to leave the mapping
  // on the actual funnel cake because it's unlikely we'd ever return to this step a second time.

  // ---------------------------------------------------------------------------------------
  // rentalBorrower steps

  firstTimeHomeBuyer: 'first_time_home_buyer',
  loanProgram: 'desired_loan_program',
  dealsLast24: 'estimate_num_exits_in_last_twenty_four_selected_range',

  // ---------------------------------------------------------------------------------------
  // broker steps

  brokerBridge: 'brokers_bridge_loans',
  brokerUsState: 'brokers_lendable_states',
  brokerStateLicensing: 'broker_has_appropriate_state_licensing',
  brokerCloses: 'estimated_num_brokered_loans_in_last_month_range',
  brokerVolume: 'estimated_monthly_brokered_loans_volume_range',
  brokerRental: 'broker_offers_rental_loans',

  // ---------------------------------------------------------------------------------------
  // shared property steps

  leadPropertyAddress: {
    line1: 'address_line1',
    line2: 'address_line2',
    city: 'address_city',
    state: 'address_state',
    zip: 'address_zip',
  },

  propertyAddress: {
    line1: 'loan.property.address.line1',
    line2: 'loan.property.address.line2',
    city: 'loan.property.address.city',
    state: 'loan.property.address.state',
    zip: 'loan.property.address.zip',
  },

  // ---------------------------------------------------------------------------------------
  // bridgeProperty steps

  borrowerAddress: {
    line1: 'loan.present_address.line1',
    line2: 'loan.present_address.line2',
    city: 'loan.present_address.city',
    state: 'loan.present_address.state',
    zip: 'loan.present_address.zip',
  },
  dateOfBirth: 'loan.date_of_birth',
  preferredSigningDate: 'loan.closing.borrower_requested_date_signing',

  // ---------------------------------------------------------------------------------------
  // brokerProperty steps

  primaryContact: {
    isBrokerPrimaryContact: 'loan.is_broker_primary_contact',
    email: 'loan.broker_primary_contact.email',
    firstName: 'loan.broker_primary_contact.first_name',
    lastName: 'loan.broker_primary_contact.last_name',
    name: 'loan.broker_primary_contact.name',
    phoneNumber: 'loan.broker_primary_contact.phone',
  },

  borrowerName: {
    borrowerType: 'loan.borrower.borrower_type',
    borrowerFirstName: 'loan.borrower.first_name',
    borrowerLastName: 'loan.borrower.last_name',
    borrowerEntityName: 'loan.borrower.entity_name',
  },
};
