import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const { bool, string, func, node, number } = PropTypes;

// This is pilfered almost verbatim from the flow framework.  It wasn't imported as a reusable component, so it's
// copied here to make it available to funnel cakes.  It should probably wind up in core/components.
class PasswordInput extends React.Component {
  state = { passwordVisible: false };

  togglePasswordVisibility = () => {
    this.setState((currentState) => ({
      passwordVisible: !currentState.passwordVisible,
    }));
  };

  render() {
    const {
      value,
      name,
      autoFocus,
      showText,
      hideText,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      tabIndex,
      required,
      label,
      disabled,
      maxLength,
      children,
    } = this.props;

    // TODO try to use BEM naming
    const classes = classNames('float-input--input', this.props.className, {
      'is-input-empty': !value,
    });

    const buttonText = this.state.passwordVisible ? hideText : showText;
    const inputType = this.state.passwordVisible ? 'text' : 'password';

    const windowWidth = window.innerWidth;
    // TODO:
    // This is pilfered code from inside elevate forms.  The hardcoded 768 is no bueno.  This needs to be cleaned up.
    // investigate WindowResizeObserver hoc
    const shouldAutoFocus = autoFocus && windowWidth > 768;

    /* eslint-disable jsx-a11y/no-autofocus */
    return (
      <fieldset className="password-input float-input fs-block">
        <input
          className={classes}
          id={name}
          name={name}
          value={value}
          type={inputType}
          autoFocus={shouldAutoFocus}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          tabIndex={tabIndex}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
        {label && <label className="float-input---label">{label}</label>}
        <button
          onClick={this.togglePasswordVisibility}
          className="button--text password-input--show-hide-button"
          type="button"
        >
          {buttonText}
        </button>
        {children}
      </fieldset>
    );
  }
}

PasswordInput.defaultProps = {
  showText: 'Show',
  hideText: 'Hide',
  disabled: false,
};

PasswordInput.propTypes = {
  hasError: bool,
  value: node,
  passwordVisible: bool,
  name: string,
  autoFocus: bool,
  hideText: string,
  showText: string,
  onChange: func,
  onBlur: func,
  onFocus: func,
  placeholder: string,
  tabIndex: number,
  required: bool,
  disabled: bool,
  label: string,
};

export default PasswordInput;
