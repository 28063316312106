import invariant from 'invariant';

const storageUnsupported = '__LOCAL_STORAGE_UNSUPPORTED__';
let storage;

const safeLogToFrogger = (message, data) => {
  if (window.Frogger) {
    Frogger.debug(message, { data });
  }
};

try {
  const uid = new Date().toString();
  storage = window.localStorage;
  storage.setItem(uid, uid);
  const fail = storage.getItem(uid) !== uid;
  storage.removeItem(uid);
  fail && (storage = null);
} catch (e) {
  safeLogToFrogger('Error in localStorage setup', e);
  storage = null;
}

const assertString = (value) =>
  invariant(typeof value === 'string', `Item key must be string, but received a value of type ${typeof value}.`);

const deserialize = (value) => {
  if (!value) {
    return value;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const serialize = (value) => {
  return JSON.stringify(value);
};

export const getItem = (key) => {
  assertString(key);

  if (!storage) {
    safeLogToFrogger('LocalStorage unavailable', {
      data: { key, source: 'getItem' },
    });
    return storageUnsupported;
  }

  const value = storage.getItem(key);
  safeLogToFrogger('Retrieved value from LocalStorage', {
    data: { value, key },
  });
  return deserialize(value);
};

export const setItem = (key, value) => {
  assertString(key);

  if (!storage) {
    safeLogToFrogger('LocalStorage unavailable', {
      data: { key, source: 'setItem' },
    });
    return storageUnsupported;
  }

  storage.setItem(key, serialize(value));
  safeLogToFrogger('Set value to LocalStorage', { data: { value, key } });
};

export const removeItem = (key) => {
  assertString(key);
  let returnval;

  if (!storage) {
    safeLogToFrogger('LocalStorage unavailable', {
      data: { key, source: 'removeItem' },
    });
    return storageUnsupported;
  }

  returnval = storage.removeItem(key);
  safeLogToFrogger('Removed item from LocalStorage', { data: { key } });
  return returnval;
};

export const clear = () => {
  let returnval;
  if (!storage) {
    safeLogToFrogger('LocalStorage unavailable', { data: { source: 'clear' } });
    return storageUnsupported;
  }

  returnval = storage.clear();
  safeLogToFrogger('LocalStorage cleared');
  return returnval;
};
