import React from 'react';
import { VerticalSelectBar } from '@lendinghome/core/components';
import { string, bool, func } from 'prop-types';
import useIsBrokerApprovedForInfill from '../hooks/useIsBrokerApprovedForInfill';
import { InfoAccordion, InfoAccordionList } from './InfoAccordion';

const defaultProgramOptions = [
  {
    label: 'Bridge / Fix and Flip / Fix to Rent',
    value: 'hard_money',
  },
  {
    label: 'New Construction *',
    value: 'hard_money_infill',
  },
  {
    label: 'Rental - Individual (1 property financed with 1 loan)',
    value: 'rental',
  },
  {
    label: 'Rental - Portfolio (5+ properties financed with 1 loan)',
    value: 'rental_portfolio',
  },
  {
    label: "I'm not sure yet",
    value: 'not_sure',
  },
];
const brokerProgramOptions = [
  {
    label: 'Bridge or Rehab',
    value: 'hard_money',
  },
  {
    label: 'Rental - Individual (1 property financed with 1 loan)',
    value: 'rental',
  },
  {
    label: 'Rental - Portfolio (5+ properties financed with 1 loan)',
    value: 'rental_portfolio',
  },
];
const brokerProgramOptionsWithInfill = [
  {
    label: 'Bridge / Fix and Flip / Fix to Rent',
    value: 'hard_money',
  },
  {
    label: 'New Construction *',
    value: 'hard_money_infill',
  },
  {
    label: 'Rental - Individual (1 property financed with 1 loan)',
    value: 'rental',
  },
  {
    label: 'Rental - Portfolio (5+ properties financed with 1 loan)',
    value: 'rental_portfolio',
  },
];

const newConstructionHelpMessages = {
  heading: '* What is a New Construction deal at Kiavi?',
  listTitle: 'A New Construction project at Kiavi could be:',
  listItems: [
    'A vacant lot w/ ground-up development',
    'An addition to existing improvements with an increase of gross living area greater than 100% of the existing square footage',
    'Structural replacement of the roof and more than one exterior wall while retaining existing foundation',
    'Structural replacement of the roof and all interior framing',
  ],
};

export default function ChooseProgramForm({ onChange, value, disabled, isUserBroker }) {
  const { isBrokerApprovedForInfill, loading: loadingIsBrokerApprovedToInfill } = useIsBrokerApprovedForInfill(
    isUserBroker
  );

  if (loadingIsBrokerApprovedToInfill) {
    return null;
  }

  let result;

  const showNewConstructionAccordion = !isUserBroker || (isBrokerApprovedForInfill && isUserBroker);

  if (isUserBroker) {
    if (isBrokerApprovedForInfill) {
      result = brokerProgramOptionsWithInfill;
    } else {
      result = brokerProgramOptions;
    }
  } else {
    result = defaultProgramOptions;
  }

  return (
    <React.Fragment>
      <VerticalSelectBar
        options={result}
        fieldName="program"
        currentValue={`${value}`}
        onChange={onChange}
        disabled={disabled}
      />
      {showNewConstructionAccordion ? (
        <InfoAccordion message={newConstructionHelpMessages.heading}>
          <InfoAccordionList
            title={newConstructionHelpMessages.listTitle}
            items={newConstructionHelpMessages.listItems}
          />
        </InfoAccordion>
      ) : null}
    </React.Fragment>
  );
}

ChooseProgramForm.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
  isUserBroker: bool,
};

ChooseProgramForm.defaultProps = {
  value: undefined,
  disabled: false,
  isUserBroker: false,
};
