import React from 'react';
import { MaskedNumberInput } from '@lendinghome/core/components';
import PropTypes from 'prop-types';

function PhoneInput({ children, wrapWithFieldset, ...props }) {
  const InputField = (
    <MaskedNumberInput
      // Limitation of text-mask component forces us to use a blank unicode
      // char instead of "space"
      // (see: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#placeholderchar)
      placeholderChar={'\u2000'}
      mask={[
        '(',
        /[2-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      type="tel"
      {...props}
    />
  );

  return wrapWithFieldset ? (
    <fieldset>
      {InputField}
      {children}
    </fieldset>
  ) : (
    <div>
      {InputField}
      {children}
    </div>
  );
}

PhoneInput.defaultProps = {
  wrapWithFieldset: false,
  placeholder: 'Enter a phone number',
  onChange: null,
  value: null,
};

PhoneInput.propTypes = {
  /**
   * flag to wrap the field in a `fieldset`
   */
  wrapWithFieldset: PropTypes.bool,
  /**
   * a callback to handle input change event
   */
  onChange: PropTypes.func,
  /**
   * an unformatted phone number
   */
  value: PropTypes.number,
  /**
   * A string to show when field is empty
   */
  placeholder: PropTypes.string,
};

export default PhoneInput;
