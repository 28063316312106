import React from 'react';

const NON_BREAKING_SPACE = '\u00A0';

export default function ErrorLabel({ isValid, message }) {
  return (
    <label className="input--error">
      <span className="is-tip-error">{isValid ? NON_BREAKING_SPACE : message}</span>
    </label>
  );
}
