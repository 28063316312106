function createDebugHelpers(getClient) {
  return {
    startDebugging() {
      getClient().debug();
    },

    stopDebugging() {
      getClient().debug(false);
    },
  };
}

export default createDebugHelpers;
