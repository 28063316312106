import React, { Component } from 'react';
import { bool, number, string, shape, array, arrayOf, oneOfType, func } from 'prop-types';
import classnames from 'classnames';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default class Table extends Component {
  getTableClassName = () => {
    const { tableClassName } = this.props;
    if (typeof tableClassName === 'function') {
      return tableClassName();
    }
    return tableClassName;
  };

  getHeaderClassName = () => {
    const { headerClassName } = this.props;
    if (typeof headerClassName === 'function') {
      return headerClassName();
    }
    return headerClassName;
  };

  getCellClassName = () => {
    const { cellClassName } = this.props;
    if (typeof cellClassName === 'function') {
      return cellClassName();
    }
    return cellClassName;
  };

  getRowClassName = (rowInfo) => {
    const { rowClassName } = this.props;
    if (typeof rowClassName === 'function') {
      return rowClassName(rowInfo);
    }
    return rowClassName;
  };

  getThProps = () => ({
    className: classnames(this.getHeaderClassName()),
  });

  getTrProps = (state, rowInfo) => ({
    className: classnames(this.getRowClassName(rowInfo)),
  });

  getTdProps = () => ({
    className: classnames(this.getCellClassName()),
  });

  render() {
    const {
      data,
      columns,
      minRows,
      showPagination,
      pageSize,
      showPageSizeOptions,
      sortable,
      sorted,
      filterable,
      resizable,
      striped,
      highlighted,
      SubComponent,
      onFilteredChange,
      onSortedChange,
      manual,
      dense,
      expanded,
    } = this.props;

    const tableClasses = [striped ? '-striped' : null, highlighted ? '-highlight' : null, dense ? '-dense' : null];

    return (
      <ReactTableFixedColumns
        data={data}
        columns={columns}
        className={classnames(this.getTableClassName(), tableClasses)}
        getTheadThProps={this.getThProps}
        getTrProps={this.getTrProps}
        getTdProps={this.getTdProps}
        onFilteredChange={onFilteredChange}
        onSortedChange={onSortedChange}
        minRows={minRows}
        showPagination={showPagination}
        pageSize={pageSize}
        showPageSizeOptions={showPageSizeOptions}
        sortable={sortable}
        sorted={sorted}
        filterable={filterable}
        resizable={resizable}
        manual={manual}
        expanded={expanded}
        SubComponent={SubComponent}
      />
    );
  }
}

Table.defaultProps = {
  data: [],
  minRows: 0,
  showPagination: false,
  showPageSizeOptions: false,
  sortable: false,
  filterable: false,
  manual: false,
  resizable: false,
  striped: true,
  highlighted: false,
  pageSize: 1000,
  tableClassName: null,
  headerClassName: null,
  cellClassName: null,
  rowClassName: null,
  SubComponent: null,
  onFilteredChange: null,
  onSortedChange: null,
  dense: false,
  expanded: [],
};

Table.propTypes = {
  data: arrayOf(shape({})),
  columns: arrayOf(
    shape({
      id: string,
      Header: string,
      accessor: oneOfType([string, func]),
      Cell: func,
    })
  ).isRequired,
  minRows: number,
  showPagination: bool,
  pageSize: number,
  showPageSizeOptions: bool,
  sortable: bool,
  sorted: array,
  filterable: bool,
  resizable: bool,
  striped: bool,
  highlighted: bool,
  tableClassName: oneOfType([string, array, func]),
  headerClassName: oneOfType([string, array, func]),
  cellClassName: oneOfType([string, array, func]),
  rowClassName: oneOfType([string, array, func]),
  SubComponent: func,
  onFilteredChange: func,
  onSortedChange: func,
  /** Condenses the table by removing some vertical padding in table cells */
  dense: bool,
  manual: bool,
  expanded: array,
};
