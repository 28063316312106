import React from 'react';
import { LoanProgram } from '@lendinghome/core/constants';
import FunnelCake from '../FunnelCake';
import { createLoan } from '../../ajax';
import GetRateFullScreen from '../GetRateFullScreen';
import SubmitFullScreen from './SubmitFullScreen';

function SubmitProFullScreen(props) {
  return (
    <GetRateFullScreen
      className="step--submit"
      slideBackground="no-background"
    >
      <SubmitFullScreen {...props} />
    </GetRateFullScreen>
  );
}

const SubmitPro = FunnelCake({
  InputForm: SubmitProFullScreen,
  mapValueToAjaxParams: () => ({
    program_tag: LoanProgram.HARD_MONEY,
  }),
  saveToServer: createLoan,
  saveFunnelStep: () => {},
});

export default SubmitPro;
