import LendingHomeError from '@lendinghome/core/errors/lendingHomeError';
import { FileList } from '../components/FileList';
import * as actions from './fileList';

export const onBeforeUpload = (rcUploadFile) => (dispatch) => {
  dispatch(
    actions.addFile(
      rcUploadFile.uid,
      rcUploadFile.name,
      FileList.STATUS_PROCESSING,
      'Preparing to upload...'
    )
  );
};

export const onStart = (rcUploadFile) => (dispatch) => {
  dispatch(
    actions.updateFileStatus(
      rcUploadFile.uid,
      FileList.STATUS_PROCESSING,
      'Uploading...'
    )
  );
};

export const markAsComplete = (file) => (dispatch) => {
  dispatch(actions.updateFileStatus(file.id, FileList.STATUS_OK, 'Complete'));
  dispatch(actions.updateFileProgress(file.id, null));
};

export const onProgress = (progress, rcUploadFile) => (dispatch) =>
  dispatch(actions.updateFileProgress(rcUploadFile.uid, progress));

export const onError = (error, rcUploadFile, rethrow = true) => (dispatch) => {
  dispatch(actions.updateFileProgress(rcUploadFile.uid, null));
  dispatch(
    actions.updateFileStatus(
      rcUploadFile.uid,
      FileList.STATUS_ERROR,
      error.message || 'Error occurred!'
    )
  );

  if (rethrow) {
    const lhError = new LendingHomeError(error);
    lhError.data.push({ uploadingFile: rcUploadFile });
    lhError.tags.push({ source: 'FileUploader' });

    throw lhError;
  }
};
