import '@babel/polyfill';
import './promise-all-settled-polyfill';
import React from 'react';
import '@lendinghome/frogger';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Application } from '@lendinghome/components-core';
import { Header } from '@lendinghome/core/components';
import { globalDataReducer } from '@lendinghome/utility-belt';
/* We're using the Split SDK directly instead of @lendinghome/feature-flags due to
 * funnel-cake functional components returning a class instance. So we can't use hooks from the feature-flags package.
 */
import { SplitFactory } from '@splitsoftware/splitio-react';
import { createStore } from 'redux';
import messages from '../shared/locales/en';
import splitConfig from '../core/splitConfig';
import App from './components/App';
import appReducer from './reducers';
import makeStateQuerier from './makeStateQuerier';

// Allows us to control the name of the generated file
import(/* webpackChunkName: "funnel-cakes-styles" */ './funnel-cakes-styles.scss').then(() => {});

const store = createStore(appReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const stateQuerier = makeStateQuerier(store);

// This global function is called when the google maps api is finished loading.
// We dispatch an action so that any part of the application that depends on the
// presence of the api can check that it is available
window.googleMapsLoadedCallback = () =>
  store.dispatch(globalDataReducer.loadData('googleMapsApiState', { loaded: true }));

ReactDOM.render(
  <SplitFactory config={splitConfig}>
    <Provider store={store}>
      <IntlProvider
        locale="en"
        messages={messages}
      >
        <Application
          applicationName="Funnel Cakes"
          errorHeader={Header}
        >
          <App stateQuerier={stateQuerier} />
        </Application>
      </IntlProvider>
    </Provider>
  </SplitFactory>,
  document.getElementById('das-boot')
);
