import React, { Component } from 'react';
import classnames from 'classnames';
import ErrorLabel from '../ErrorLabel';

export default function CreateVerifyingTextField({
  Input,
  treatZeroAsBlank = false,
  validator = () => true,
  defaultInvalidMessage,
}) {
  return class VerifyingField extends Component {
    state = { validatable: false };

    becomeValidatable = () => {
      this.setState({ validatable: true });
    };

    render() {
      const {
        onChange,
        value,
        disabled,
        label,
        htmlFor,
        inputClassName,
        required = true,
        requiredMessage = 'This field is required',
        invalidMessage = defaultInvalidMessage,
        isValid: isValidOverride = true,
        maxLength = '256',
        ...extras
      } = this.props;

      const { validatable } = this.state;

      let isValid = true;
      let message = '';

      let isEmpty = value === undefined || value === null || value === '';

      // This is a bit goofy.  This is only necessary because the PhoneInput field treats a phone number as a
      // number instead of a string.  When a partial number is typed then backspaced out, it becomes zero
      // under the hood instead of a blank.
      if (treatZeroAsBlank) {
        isEmpty = isEmpty || value === 0;
      }

      if (isEmpty && required) {
        isValid = !validatable;
        message = requiredMessage;
      } else if ((validatable && !validator(value)) || !isValidOverride) {
        isValid = false;
        message = invalidMessage;
      }

      const inputErrorClass = isValid ? '' : 'is-input-error';
      const inputEmptyClass = isEmpty ? 'is-input-empty' : '';
      const inputClass = classnames(inputClassName, inputErrorClass, inputEmptyClass, 'float-input--input');
      const fieldsetClass = `float-input ${inputEmptyClass}`;

      return (
        <fieldset className={fieldsetClass}>
          <Input
            className={inputClass}
            htmlFor={htmlFor}
            name={htmlFor}
            placeholder={label}
            onChange={onChange}
            onBlur={this.becomeValidatable}
            value={value}
            disabled={disabled}
            maxlength={maxLength}
            {...extras}
          />
          <label
            className="float-input--label"
            htmlFor={htmlFor}
          >
            {label}
          </label>
          <ErrorLabel
            isValid={isValid}
            message={message}
          />
        </fieldset>
      );
    }
  };
}
