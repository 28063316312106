import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Gallery from './Gallery';

class ImageThumbnail extends Component {
  constructor(props) {
    super(props);
    this.bindHandlers();
    this.state = {
      isBrowsingPics: false,
    };
  }

  getFileIndexFromFileId(id) {
    return this.props.files.map((f) => f.id).indexOf(id);
  }

  openGallery(e) {
    e.preventDefault();
    this.setState({ isBrowsingPics: true });
  }

  closeGallery() {
    this.setState({ isBrowsingPics: false });
  }

  bindHandlers() {
    this.closeGallery = this.closeGallery.bind(this);
    this.openGallery = this.openGallery.bind(this);
  }

  render() {
    let index;
    let { file } = this.props;
    const { fileId, files, className } = this.props;

    if (file) {
      index = this.getFileIndexFromFileId(file.id);
    } else {
      index = this.getFileIndexFromFileId(fileId);
      file = files[index];
    }

    return (
      <div>
        <div
          className={className}
          style={{
            backgroundImage: `url(${file.thumbnail})`,
          }}
          onClick={this.props.openGallery && this.openGallery}
        />
        <div>
          {this.state.isBrowsingPics && (
            <Gallery
              items={files}
              onCloseAsk={this.closeGallery}
              startIndex={index}
            />
          )}
        </div>
      </div>
    );
  }
}

ImageThumbnail.propTypes = {
  file: PropTypes.object,
  fileId: PropTypes.string,
  openGallery: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.object),
};

ImageThumbnail.defaultProps = {
  files: [],
  openGallery: true,
  className: 'image--square',
};

export default ImageThumbnail;
