import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import { getEventValue } from '../utils';
import { MinValue, MaxValue } from '../validations';

const negativeSignZeroDecimalRegex = /^(-0?\.?|-\.0|0\.0{0,2}|\.00?)$/;
const shouldKeepValue = (value) => negativeSignZeroDecimalRegex.test(value);

class MaskedNumberInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    const { value, transformMultiple, min, max } = props;
    const validations = [];
    if (!isNil(min)) {
      validations.push(new MinValue({ minValue: min }));
    }
    if (!isNil(max)) {
      validations.push(new MaxValue({ maxValue: max }));
    }

    this.state = {
      stringValue: this.deriveStringValue(value, transformMultiple),
      transformMultiple,
      validations,
      isValid: true,
      errors: null,
    };

    this.onKeyUp = this.onKeyUp.bind(this);
    this.unmaskingEvents = {
      onChange: this.unmaskingEvent(this.handleManualOnChange),
      onBlur: this.unmaskingEvent(this.props.onBlur),
      onKeyUp: this.onKeyUp,
    };
  }

  // Some types of numbers might need their value transformed before being sent out to the application.
  // For example, percentage inputs must be divided by 100. In general, we can just divide by 1 to avoid any change.
  deriveStringValue(value, transformMultiple) {
    if (isNil(value)) {
      return null;
    }

    if (value === '') {
      return value;
    }

    let transformedValue = value * transformMultiple;
    return transformedValue.toString();
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.setState((currentState) => {
        let nextValue = '';
        if (!Number.isNaN(nextProps.value) && isFinite(nextProps.value)) {
          nextValue = this.deriveStringValue(
            nextProps.value,
            currentState.transformMultiple
          );
        } else if (shouldKeepValue(nextProps.value)) {
          nextValue = nextProps.value;
        }
        return { stringValue: nextValue };
      });
    }
  }

  unmaskNumber(value) {
    const stringValue = this.props.allowNegative
      ? value.replace(/(?!^)-/g, '').replace(/[^0-9.-]/g, '')
      : value.replace(/[^0-9.]/g, '');
    return stringValue;
  }

  setStateToValue(stringValue) {
    this.setState(() => ({ stringValue }));
  }

  transformedFloat(value) {
    return parseFloat(value) / this.state.transformMultiple;
  }

  unmaskingEvent(eventHandler) {
    const { allowBlank } = this.props;
    if (eventHandler) {
      return (event) => {
        let unmaskedValue = this.unmaskNumber(getEventValue(event));
        this.setStateToValue(unmaskedValue);
        if (unmaskedValue === '') {
          // Run the event handler with null or zero if the unmasked value is an empty string
          unmaskedValue = allowBlank ? null : 0;
          eventHandler(unmaskedValue, event);
        } else if (shouldKeepValue(unmaskedValue)) {
          eventHandler(unmaskedValue, event);
        } else {
          eventHandler(this.transformedFloat(unmaskedValue), event);
        }
      };
    }
  }

  getKey(event) {
    const value = event.key;
    const key = event.which || event.keyCode;
    const isNumberRowKey = key >= 48 && key <= 57;
    const isNumberPadKey = key >= 96 && key <= 105;
    const isShiftOrAltPressed = event.shiftKey || event.altKey;
    const isNumericKey =
      !isShiftOrAltPressed && (isNumberRowKey || isNumberPadKey);

    return {
      value,
      isNumericKey,
    };
  }

  validate = (value) => {
    if (!this.props.validate) {
      return [true];
    }

    if (this.props.allowBlank && isNil(value)) {
      return [true];
    }
    if (isNaN(value)) {
      return [false, 'Value must be of type number'];
    }

    const firstInvalid = this.state.validations.find(
      (validation) => !validation.isValid(value)
    );

    if (firstInvalid) {
      return [false, firstInvalid.message];
    }

    return [true];
  };

  // this method will handle the below corner cases where the number get transposed
  // entering -.25 results in -0.52
  // entering . before 0 followed by 25 results in 0.52
  handleCursorOnChange = (value) => {
    if (isNil(value)) return;
    let stringValue = value.toString();
    if (stringValue.startsWith('-0.') && stringValue.length === 4) {
      this.inputRef.inputElement.setSelectionRange(4, 4);
    } else if (stringValue.startsWith('0.') && stringValue.length === 3) {
      this.inputRef.inputElement.setSelectionRange(3, 3);
    }
  };

  handleManualOnChange = (value, event) => {
    const { onChange } = this.props;
    this.handleCursorOnChange(value);
    const [isValid, errors] = this.validate(value);
    this.setState({
      value,
      isValid,
      errors,
    });

    if (typeof onChange === 'function') {
      onChange(value, event);
    }
  };

  onKeyUp(event) {
    const key = this.getKey(event);
    const unmaskedValue = this.unmaskNumber(getEventValue(event));

    if (unmaskedValue === '0' && key.isNumericKey) {
      event.preventDefault();
      this.setStateToValue(key.value);
      this.handleManualOnChange(parseFloat(key.value), event);
    }
  }

  render() {
    const {
      referenceValue,
      useMobileNumberKeyboard,
      transformMultiple,
      allowBlank,
      allowNegative,
      isFloatInput,
      validate,
      ...rest
    } = this.props;

    const { stringValue, isValid, errors } = this.state;

    const classes = classnames(this.props.className, {
      'float-input--input': isFloatInput,
      'is-input-empty': !stringValue,
      'is-input-error': !isValid,
    });
    return (
      <div
        className={classnames('masked-number-input', {
          'float-input': isFloatInput,
        })}
      >
        <MaskedInput
          {...rest}
          {...this.unmaskingEvents}
          value={stringValue}
          className={classes}
          ref={(input) => {
            this.inputRef = input;
          }}
        />
        {!isValid && errors && <div className="input--error">{errors}</div>}
      </div>
    );
  }
}

const noop = () => {};

MaskedNumberInput.defaultProps = {
  onBlur: noop,
  onChange: noop,
  transformMultiple: 1,
  allowBlank: false,
  allowNegative: false,
  isFloatInput: false,
  min: null,
  max: null,
  validate: false,
};

MaskedNumberInput.propTypes = {
  /** blur event handler */
  onBlur: PropTypes.func,
  /** change event handler */
  onChange: PropTypes.func,
  /**
   * Some types of numbers might need their value transformed before
   * being sent out to the application.
   * For example, percentage inputs must be divided by 100.
   * In general, we can just divide by 1 to avoid any change.
   */
  transformMultiple: PropTypes.number,
  /** Allow input to be blank. Default value is false and input value will be set to 0 */
  allowBlank: PropTypes.bool,
  /**
   * Indicates if the input should allow negative numbers or not.
   * If this is set, ensure the mask also has this setting
   */
  allowNegative: PropTypes.bool,
  /**
   * Applies lh-ui error fields styling
   *  - red highlight/text with an inline error message
   */
  isFloatInput: PropTypes.bool,
  /** A numeric value to validate against */
  min: PropTypes.number,
  /** A numeric value to validate against */
  max: PropTypes.number,
  /** A flag to enable/disable validations */
  validate: PropTypes.bool,
};

export default MaskedNumberInput;
