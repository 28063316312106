import { fetchApi } from '@lendinghome/core';

const featureFlagQuery = `
  query isFeatureFlagEnabled($input: String!){
    featureFlag(feature: $input) {
      enabled
    }
  }
`;

const isBrokerApprovedForInfillQuery = `
  query getBroker {
    broker {
      isApprovedForInfill
    }
  }
`;

export function featureFlagEnabled(input) {
  return fetchApi.graphql({
    query: featureFlagQuery,
    variables: {
      input,
    },
  });
}

export function getIsBrokerApprovedForInfill() {
  return fetchApi.graphql({
    query: isBrokerApprovedForInfillQuery,
  });
}
