import moment from 'moment';
import '@lendinghome/frogger';
import { fetchApi, HttpMethod } from '@lendinghome/core';
import uuidv4 from 'uuid/v4';

export default class DataEventsClient {
  constructor({ mapper = {}, logger = Frogger, payloadFilter = (payload) => payload, gqlMapper = {} } = {}) {
    this.mapper = mapper;
    this.logger = logger;
    this.payloadFilter = payloadFilter;
    this.gqlMapper = gqlMapper;

    const env = window.env || {};
    this.url = env.DATA_EVENTS_PUBLIC_URL;
    this.enabled = env.DATA_EVENTS_ENABLED;
    this.environment = env.STACK_VARIANT_NAME;
  }

  triggerViaGraphql(eventName, eventData) {
    if (this.enabled && typeof this.mapper[eventName] === 'function') {
      const body = this._buildPayloadWithoutEnrichedData(this.mapper[eventName], eventData);

      return fetchApi
        .graphql({
          query: this.gqlMapper[eventName](eventData),
          variables: {
            input: body.event_details,
          },
        })
        .catch((err) => {
          this.logger.warn('Failed to send event data', err);
        });
    }
  }

  trigger(eventName, eventData) {
    if (this.enabled && typeof this.mapper[eventName] === 'function') {
      const body = this._buildPayload(this.mapper[eventName], eventData);

      return fetchApi
        .json(this.url, {
          body,
          method: HttpMethod.Post,
        })
        .catch((err) => {
          this.logger.warn('Failed to send event data', err);
        });
    }
  }

  _buildPayload(mapperFunction, eventData) {
    const payload = mapperFunction(eventData);
    const filteredPayload = this.payloadFilter(payload);
    const enrichedPayload = this._enrichPayload(filteredPayload);
    return this._removeEmptyValues(enrichedPayload);
  }

  _buildPayloadWithoutEnrichedData(mapperFunction, eventData) {
    const payload = mapperFunction(eventData);
    const filteredPayload = this.payloadFilter(payload);
    return this._removeEmptyValues(filteredPayload);
  }

  _enrichPayload(payload) {
    return {
      ...payload,
      client_id: 'JS Data Events Client',
      environment: this.environment,
      timestamp_utc: moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
      event_id: uuidv4(),
    };
  }

  _removeEmptyValues(payload) {
    const eventDetails = payload.event_details || {};

    for (const [key, value] of Object.entries(eventDetails)) {
      if (value === null || value === undefined) {
        delete eventDetails[key];
      }
    }

    return {
      ...payload,
      event_details: eventDetails,
    };
  }
}
