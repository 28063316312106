import * as Sentry from '@sentry/browser';

export default function getGraphqlUrl(baseUrl = window.gon?.API_BASE_URL) {
  const path = '/graphql';
  try {
    return baseUrl ? new URL(path, baseUrl).toString() : path;
  } catch (error) {
    Sentry.addBreadcrumb({
      message: 'Error occurred while determing graphql url',
      category: 'getGraphqlUrl',
      level: 'warning',
      data: { error },
    });

    return path;
  }
}
