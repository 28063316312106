import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import EntityInputForm from './inputs/EntityInputForm';

function EntityFullScreen(props) {
  return (
    <GetRateFullScreen headline={'Do you hold title to your investment properties in an entity?'}>
      <EntityInputForm {...props} />
    </GetRateFullScreen>
  );
}

const Entity = FunnelCake({
  InputForm: EntityFullScreen,
  mapValueToAjaxParams: (value) => ({
    has_entity: value,
    borrower_type: value === true ? 'entity' : 'individual',
  }),
});

export default Entity;
