export const SET_FUNNEL_STEP_VALUE = 'SET_FUNNEL_STEP_VALUE';
export function setFunnelStepValue({ step, value }) {
  return { type: SET_FUNNEL_STEP_VALUE, step, value };
}

// Use case: dispatch(setFunnelMetaData({ key: 'userExists', value: false }));
export const SET_FUNNEL_META_DATA = 'SET_FUNNEL_META_DATA';
export function setFunnelMetaData({ key, value }) {
  return { type: SET_FUNNEL_META_DATA, key, value };
}
