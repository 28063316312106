import DataEventsClient from '@lendinghome/data-events-client';
import * as mutations from './mutations';

const funnelEventToPayloadMappings = {
  'step.loaded': ({ flow, step, state, url }) => {
    let flowDetails = state.flowDetails();
    return {
      event_details: {
        url,
        loanId: state.loan.getId(),
        flowDetails: {
          ...flowDetails,
          flowName: flow,
          stepName: step,
        },
        experimentInsertionPointId: state.experimentInsertionPointId(),
      },
    };
  },
  'step.completed': ({ flow, step, state, url }) => {
    let stepValue = state.stepValue(step);
    if (stepValue !== undefined && stepValue !== null && typeof stepValue !== 'object') {
      stepValue = stepValue.toString();
    }
    let flowDetails = state.flowDetails();

    return {
      event_details: {
        url,
        loanId: state.loan.getId(),
        flowDetails: {
          ...flowDetails,
          flowName: flow,
          stepName: step,
          stepValue,
        },
        experimentInsertionPointId: state.experimentInsertionPointId(),
      },
    };
  },
};

const funnelEventToGraphqlMutationMappings = {
  'step.loaded': ({ flow }) =>
    flow.includes('Property') ? mutations.propertyFlowStepLoaded : mutations.mFlowStepLoaded,
  'step.completed': ({ flow }) =>
    flow.includes('Property') ? mutations.propertyFlowStepCompleted : mutations.mFlowStepCompleted,
};

function payloadFilter(payload) {
  const { event_details: eventDetails } = payload;
  const {
    flowDetails: { stepName, stepValue },
  } = eventDetails;

  if (stepValue && (stepName === 'setPassword' || stepName === 'signIn' || stepName === 'yourSSN')) {
    return {
      event_details: {
        ...eventDetails,
        flowDetails: {
          ...eventDetails.flowDetails,
          stepValue: 'omitted',
        },
      },
    };
  }

  return payload;
}

const client = new DataEventsClient({
  mapper: funnelEventToPayloadMappings,
  payloadFilter,
  gqlMapper: funnelEventToGraphqlMutationMappings,
});

class DataEventsDestination {
  static notify(event, data) {
    return client.triggerViaGraphql(event, data);
  }
}

export default DataEventsDestination;
