import React from 'react';
import EventNotifier from '../../analytics/eventNotifier';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import ExitsLast24InputForm from './inputs/ExitsLast24InputForm';
import { BrokerLink } from './BrokerLink';

function ExitsLast24FullScreen(props) {
  return (
    <GetRateFullScreen headline={'How many properties have you flipped (or exited) in the last 24 months?'}>
      <ExitsLast24InputForm {...props} />
      <BrokerLink />
    </GetRateFullScreen>
  );
}

const ExitsLast24 = FunnelCake({
  InputForm: ExitsLast24FullScreen,
  notifyStepLoadedHandlers: [EventNotifier.notifyHardMoneyFlowLoaded, EventNotifier.notifyStepLoaded],
  notifyStepCompletedHandlers: [EventNotifier.notifyStepCompleted, EventNotifier.notifyIfProPlus],
  mapPropsToAjaxParams: () => ({ account_type: 'borrower' }),
});

export default ExitsLast24;
