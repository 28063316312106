import createUserAndGroupHelpers from './helpers/createUserAndGroupHelpers';
import createDebugHelpers from './helpers/createDebugHelpers';
import createTimeoutHelpers from './helpers/createTimeoutHelpers';
import createEmitterHelpers from './helpers/createEmitterHelpers';

export default getClient => {
  const userAndGroupHelpers = createUserAndGroupHelpers(getClient);
  const emitterHelpers = createEmitterHelpers(getClient);
  const debugHelpers = createDebugHelpers(getClient);
  const timeoutHelpers = createTimeoutHelpers(getClient);

  return Object.defineProperties(
    {},
    {
      ...Object.getOwnPropertyDescriptors(userAndGroupHelpers),
      ...Object.getOwnPropertyDescriptors(emitterHelpers),
      ...Object.getOwnPropertyDescriptors(debugHelpers),
      ...Object.getOwnPropertyDescriptors(timeoutHelpers),
    }
  );
};
