import React from 'react';
import { string, arrayOf } from 'prop-types';

export const InfoAccordionList = ({ title, items }) => (
  <div className="info-accordion-list">
    {title ? <p>{title}</p> : null}
    <ul>
      {items.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  </div>
);

InfoAccordionList.defaultProps = {
  title: null,
};

InfoAccordionList.propTypes = {
  title: string,
  items: arrayOf(string).isRequired,
};

export default InfoAccordionList;
