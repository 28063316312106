import { PhoneInput } from '@lendinghome/core/components';
import phoneValidator from '../validators/phoneValidator';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';

const VerifyingPhoneInput = CreateVerifyingTextField({
  Input: PhoneInput,
  validator: phoneValidator,
  defaultInvalidMessage: 'Please enter a valid phone number',
  treatZeroAsBlank: true,
});

VerifyingPhoneInput.validator = phoneValidator;

export default VerifyingPhoneInput;
