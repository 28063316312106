import React from 'react';
import { bool, func } from 'prop-types';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { RESET_PASSWORD_TREATMENT_NAME } from '../../shared';

function ResetPassword({ passwordHasBeenReset, handleResetPassword, disabled }) {
  return (
    <div className="reset-password-container">
      {passwordHasBeenReset ? (
        <span>We just sent you a password reset link. Please check your email.</span>
      ) : (
        <SplitTreatments names={[RESET_PASSWORD_TREATMENT_NAME]}>
          {({ treatments: splits }) => (
            <button
              disabled={disabled}
              className="button--text reset-password-link"
              onClick={() => handleResetPassword(splits)}
              type="button"
            >
              Forgot Password?
            </button>
          )}
        </SplitTreatments>
      )}
    </div>
  );
}

ResetPassword.propTypes = {
  handleResetPassword: func.isRequired,
  passwordHasBeenReset: bool,
  disabled: bool,
};

ResetPassword.defaultProps = {
  passwordHasBeenReset: false,
  disabled: false,
};

export default ResetPassword;
