import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';

export const CLIENT_REQUEST = 'CLIENT_REQUEST';

const noop = () => {};

function getMessages(input) {
  if (typeof input === 'string') {
    return [input];
  }
  if (has(input, 'message')) {
    return compact([input.message]);
  }
  return flatten(values(input).map((error) => getMessages(error)));
}

class PromiseRejection extends Error {
  constructor(error) {
    super('Promise was rejected with an error');
    this.error = error;
  }
}

export function errorMessages(action) {
  let messages = [];
  const { body } = action.payload;

  if (body && body.errors) {
    messages = getMessages(body.errors);
  }

  if (isEmpty(messages)) {
    messages.push('An error occurred and LendingHome has been notified automatically.');
  }

  return messages;
}

export default function clientMiddleware(apiClient, errorHandler = noop) {
  return (store) => (next) => (action) => {
    if (action.type !== CLIENT_REQUEST) {
      return next(action);
    }

    const { types, payload, conditional, successFlash, hideErrorFlash, ensureChainable, delay, ...rest } = action;
    const [REQUEST, SUCCESS, FAILURE] = types;

    if (conditional && !conditional(store.getState())) {
      return Promise.reject();
    }

    next({ ...rest, type: REQUEST });

    let promise = payload(apiClient, store.getState());

    if (delay) {
      const getFirst = ([first]) => first;
      const rejectFirst = ([first]) => Promise.reject(first);

      promise = Promise.all([promise, new Promise((resolve) => setTimeout(() => resolve(), delay))]).then(
        getFirst,
        rejectFirst
      );
    }

    function success(result) {
      let newAction = {
        ...rest,
        payload: result,
        type: SUCCESS,
      };

      if (successFlash) {
        newAction = {
          ...newAction,
          flash: {
            type: 'success',
            messages: [successFlash],
          },
        };
      }

      return next(newAction);
    }

    const error = (err) => {
      let newAction = {
        ...rest,
        payload: err,
        type: FAILURE,
      };

      // Set `hideErrorFlash` to false if your error response does not
      // contain a "message" key with the error message.
      //
      // Otherwise you may be in for some fun infinite recursion.
      if (!hideErrorFlash) {
        newAction = {
          ...newAction,
          flash: {
            type: 'error',
            messages: errorMessages(newAction),
          },
        };
      }

      next(newAction);

      const { rethrow, newError } = errorHandler(err, store) || {};

      if (rethrow) {
        // Need to ensure we ALWAYS throw an Error object, for
        // better tracking on Sentry
        let throwable = newError || error;

        if (!(throwable instanceof Error)) {
          throwable = new PromiseRejection(throwable);
        }

        return Promise.reject(throwable);
      }
    };

    if (ensureChainable) {
      return promise.then(success, error);
    }
    promise.then(success, error);

    return promise;
  };
}
