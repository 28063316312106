import React from 'react';
import { string } from 'prop-types';
import uniqueId from 'lodash/uniqueId';

export default function FormatLineBreaks({ ...props }) {
  let lines = [];

  props.children.split(/\n/).forEach((line) => {
    if (line) {
      lines.push(line);
    }
    lines.push(<br key={uniqueId('format-line-breaks-')} />);
  });

  return <span>{lines}</span>;
}

FormatLineBreaks.propTypes = {
  children: string,
};
