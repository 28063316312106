import React from 'react';
import { LoanProgram as LoanProgramConstants, RehabType } from '@lendinghome/core/constants';
import EventNotifier from '../../analytics/eventNotifier';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import LoanProgramInputForm from './inputs/LoanProgramInputForm';

function LoanProgramFullScreen(props) {
  return (
    <GetRateFullScreen headline={'What kind of real estate investment are you considering?'}>
      <LoanProgramInputForm
        hideBackButton
        {...props}
      />
    </GetRateFullScreen>
  );
}

const LoanProgram = FunnelCake({
  InputForm: LoanProgramFullScreen,
  notifyStepLoadedHandlers: [EventNotifier.notifyFlowLoaded, EventNotifier.notifyStepLoaded],
  mapValueToAjaxParams: (value) => {
    let programTag = value;
    let rehabType = null;
    if (value === 'not_sure') {
      programTag = LoanProgramConstants.HARD_MONEY;
    }
    if (value === LoanProgramConstants.HARD_MONEY_INFILL) {
      programTag = LoanProgramConstants.HARD_MONEY;
      rehabType = RehabType.INFILL;
    }

    return {
      desired_loan_program: value,
      program_tag: programTag,
      rehab_type: rehabType,
    };
  },
});

export default LoanProgram;
