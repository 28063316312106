import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import { checkIfBrokerStateUnsupported } from '../../ajax';
import BrokerUsStateInputForm from './inputs/BrokerUsStateInputForm';

function BrokerBridgeRentalUsStateFullScreen(props) {
  return (
    <GetRateFullScreen headline={'In which states are you currently licensed in?'}>
      <BrokerUsStateInputForm {...props} />
    </GetRateFullScreen>
  );
}

const BrokerBridgeRentalUsState = FunnelCake({
  InputForm: BrokerBridgeRentalUsStateFullScreen,
  saveToServer: checkIfBrokerStateUnsupported,
});

export default BrokerBridgeRentalUsState;
