// A thin wrapper around the built-in `toLocaleString` for date.
// Accepts a native Date object OR a String in ISO format.
// Provides some sane defaults for the options to `toLocaleString`
// giving you a date that looks like:
//
// 9/26/16, 4:40 PM PST
//
export default function formatDateTime(dateTime, opts = {}) {
  if (!dateTime) {
    return;
  }

  let dateObj = dateTime;
  const { dateOnly, timeOnly, includeTimeZone = true, ...options } = opts;

  const timeDefaults = {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  };

  if (includeTimeZone) {
    timeDefaults.timeZoneName = 'short';
  }

  const dateDefaults = {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  };

  if (typeof dateTime === 'string') {
    dateObj = new Date(dateTime);
  }

  if (dateOnly) {
    return dateObj.toLocaleDateString('en-US', {
      ...dateDefaults,
      ...options,
    });
  }

  if (timeOnly) {
    return dateObj.toLocaleTimeString('en-US', {
      ...timeDefaults,
    });
  }

  return dateObj.toLocaleString('en-US', {
    ...dateDefaults,
    ...timeDefaults,
    ...options,
  });
}
