import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default function FunnelStepForm({ Input, submitOnChange, isSubmittable = (value) => value }) {
  // TODO: pass isSubmittable as a prop? this way we test each overridden func
  return function({
    onChange,
    onSubmit,
    value,
    disabled,
    hideBackButton = false,
    backButtonText = 'Go Back',
    hideSkipButton = true,
    skipButtonText = 'Skip',
    hideNextButton = false,
    nextButtonText = 'Next',
    nextButtonSize = 'large',
    nextButtonClassNames = null,
    navigateToPrevious,
    navigateToNext,
    children,
    ...otherProps
  }) {
    const submitDisabled = !isSubmittable(value) || disabled;

    function onInputChange(inputValue) {
      onChange(inputValue);
      if (submitOnChange) {
        onSubmit();
      }
    }

    function onFormSubmit(event) {
      event.preventDefault();
      if (!submitDisabled) {
        onSubmit();
      }
    }

    const buttonGroupClasses = `button-group flow-navigation-container ${
      hideBackButton && hideSkipButton ? 'has-one-button' : ''
    }`;

    const skipButtonClasses = classnames(`button is-button-secondary is-button-skip is-button-${nextButtonSize}`);
    const nextButtonClasses = classnames(nextButtonClassNames, `button is-button-next is-button-${nextButtonSize}`);

    return (
      <form
        noValidate
        className="elevate-form step-form"
        onSubmit={onFormSubmit}
      >
        <section className="fields-container">
          <Input
            onChange={onInputChange}
            value={value}
            disabled={disabled}
            {...otherProps}
          />
          {children}
        </section>
        <div className={buttonGroupClasses}>
          {!hideBackButton && (
            <button
              className="is-button-back button--text"
              type="button"
              onClick={navigateToPrevious}
              disabled={disabled}
            >
              {backButtonText}
            </button>
          )}
          {!hideSkipButton && (
            <React.Fragment>
              <div style={{ flex: '1' }} />
              <button
                className={skipButtonClasses}
                type="button"
                onClick={() => navigateToNext()}
              >
                {skipButtonText}
              </button>
            </React.Fragment>
          )}
          {!hideNextButton && (
            <button
              className={nextButtonClasses}
              id="funnel-step-form-submit"
              type="submit"
              disabled={submitDisabled}
            >
              {nextButtonText}
            </button>
          )}
        </div>
      </form>
    );
  };
}

FunnelStepForm.defaultProps = {
  hideBackButton: false,
  backButtonText: 'Go Back',
  hideSkipButton: true,
  skipButtonText: 'Skip',
  hideNextButton: false,
  nextButtonText: 'Next',
  nextButtonSize: 'large',
  nextButtonClassNames: null,
  navigateToPrevious: null,
  navigateToNext: null,
};

FunnelStepForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  value: PropTypes.any,
  hideBackButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  hideSkipButton: PropTypes.bool,
  skipButtonText: PropTypes.string,
  hideNextButton: PropTypes.bool,
  nextButtonText: PropTypes.string,
  nextButtonSize: PropTypes.string,
  nextButtonClassNames: PropTypes.string,
  navigateToPrevious: PropTypes.func,
  navigateToNext: PropTypes.func,
};
