/**
 * A custom error class thrown by LendingHome applications
 * example usages:
 *
 * throw new LendingHomeError('This is a new error wrapping an existing one', error);
 * throw new LendingHomeError('This is an error with debug data', { data: { key: 'this is debug data' }});
 * throw new LendingHomeError('This is an error with debug data and sentry tags',
 *                             { data: { key: 'this is debug data' }, tags: { key: 'this is a sentry tag' }});
 *
 * You may also add data and/or tags to an error instance, like:
 * const error = new LendingHomeError('My error');
 * error.data.push({ key: 'value' });
 * error.tags.push({ key: 'value' });
 *
 * *Please* decorate your errors with as much debug data as possible, without compromising PNI
 * All data/tags attached to the error will be reported to Sentry
 * Handles the following param structures:
 * case 1: (message, error, { data:{ key: value }, tags:{ key: value } })
 * case 2: (message, { data:{ key: value }, tags:{ key: value } })
 * case 3: (message, { data:{ key: value } })
 * case 4: (message)
 * case 5: (error, { data:{ key: value }, tags:{ key: value } })
 * case 6: (error, { data:{ key: value } })
 * case 7: (error)
 */
export default class LendingHomeError extends Error {
  /**
   * Create an error
   * @param  {...any} args
   */
  constructor(...args) {
    if (typeof args[0] !== 'string' && !(args[0] instanceof Error)) {
      Frogger.warn('Unprocessable call to LendingHomeError constructor!');
    }

    // set the message
    super(args[0]);

    const error = args.find((arg) => arg instanceof Error);

    this.data = [];
    this.tags = [];
    this.name = 'LendingHomeError';

    this.options = args.find(
      (arg) => typeof arg === 'object' && !(arg instanceof Error)
    );

    if (typeof this.options === 'object') {
      const { data, tags } = this.options;
      if (data) {
        this.data.push(data);
      }
      if (tags) {
        this.tags.push(tags);
      }
    }

    // If we're wrapping an existing error
    if (error) {
      // assemble a stacktrace which includes the stack from both errors
      this.originalError = error;
      try {
        this.stack = this.stack.concat(`\n${error.stack}`);
      } catch (e) {
        /* do nothing */
      }
      // Also, copy data and tags from the original error into our new error
      try {
        if (error.data && error.data instanceof Array) {
          this.data = this.data.concat(error.data);
          this.tags = this.tags.concat(error.tags);
        }
      } catch (e) {
        /* do nothing */
      }
    }
  }
}
