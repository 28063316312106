import React, { useState } from 'react';
import { string, node } from 'prop-types';
import { InfoAccordionList } from './InfoAccordion/InfoAccordionList';

export const InfoAccordion = ({ message, children }) => {
  const [showAccordion, setShowAccordion] = useState(false);

  const handleAccordionShown = () => {
    setShowAccordion(!showAccordion);
  };

  return (
    <React.Fragment>
      <a
        className="info-accordion"
        onClick={handleAccordionShown}
      >
        {message}
      </a>
      {showAccordion && <div className="info-accordion-container">{children}</div>}
    </React.Fragment>
  );
};

InfoAccordion.defaultProps = {
  children: null,
};

InfoAccordion.propTypes = {
  message: string.isRequired,
  children: node,
};

export { InfoAccordionList };

export default InfoAccordion;
