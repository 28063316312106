import React, { Children, cloneElement } from 'react';
import { PropTypes as LHPropTypes } from '@lendinghome/utility-belt-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import invariant from 'invariant';
import flow from 'lodash/fp/flow';

const { func, node, bool, string } = PropTypes;
const { childrenTypesExcept, childrenOfType } = LHPropTypes;

// Helper to make Children.map work with lodash fp function composition
const fpMapChildren = (fn) => (children) => Children.map(children, fn);

/* Supporting Components (private and not exported) */

const Term = ({ children, skew: isSkewed = true }) => (
  <dt className={classnames({ 'is-card-term-skewed': isSkewed }, 'card--term')}>
    {children}
  </dt>
);
Term.propTypes = {
  children: node,
  skew: bool,
};

const Description = ({ children, skew: isSkewed = false }) => (
  <dd
    className={classnames(
      { 'is-card-description-skewed': isSkewed },
      'card--description'
    )}
  >
    {children}
  </dd>
);
Description.propTypes = {
  children: node,
  skew: bool,
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// Primary, required wrapper for children of a card
const Container = ({
  children,
  isSlim,
  isSelectable,
  isSelected,
  className,
  onSelect,
  style,
}) => (
  <section
    className={classnames('card', className, {
      'is-card-slim': isSlim,
      'is-card-selectable': isSelectable,
      'is-card-selected': isSelected,
    })}
    onClick={onSelect}
    style={style}
  >
    {children}
  </section>
);

Container.defaultProps = {
  isContainer: true, // for PropType checking
};

Container.propTypes = {
  children: childrenOfType(
    'Section',
    'HeadingSection',
    'DescriptionSection',
    'Component'
  ),
};

const HeadingSection = ({ className, children }) => (
  <dl className={classnames('card--section card--heading-section', className)}>
    {children}
  </dl>
);
HeadingSection.propTypes = {
  className: string,
  children: childrenOfType(
    'Term',
    'Description',
    'SelectedSectionFlag',
    'Component'
  ),
};

const DescriptionSection = ({ className, children }) => (
  <dl
    className={classnames('card--section card--description-section', className)}
  >
    {children}
  </dl>
);
DescriptionSection.propTypes = {
  className: string,
  children: childrenOfType('Term', 'Description'),
};

const Section = ({ className, children }) => (
  <section className={classnames('card--section', className)}>
    {children}
  </section>
);
Section.propTypes = {
  children: childrenTypesExcept('Description', 'Term'),
  className: string,
};

const SelectedSectionFlag = ({ children }) => (
  <div className="card--is-selected-flag">{children}</div>
);

/* Main Component */

// Card is a bordered container for description lists with
// support for some static content as well. It has a maximum
// width, and a couple different representations of lists to
// support rendering different data.
//
export default function Card({
  children,
  isSlim,
  isSelectable,
  isSelected,
  className,
  style,
  onSelect = () => {},
}) {
  const ensureFirstChildIsContainer = (rendered) => {
    invariant(
      rendered.props.isContainer,
      'Card must contain a single child of type Container.'
    );
    return rendered;
  };

  const addPropsToContainer = (rendered) =>
    cloneElement(rendered, {
      className,
      isSlim,
      isSelectable,
      isSelected,
      onSelect,
      style,
    });

  const renderChildren = flow(
    children,
    ensureFirstChildIsContainer,
    fpMapChildren(addPropsToContainer)
  );

  return renderChildren({
    Term,
    Description,
    Container,
    HeadingSection,
    DescriptionSection,
    Section,
    SelectedSectionFlag,
  })[0];
}

Card.propTypes = {
  children: func,
};
