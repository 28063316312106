import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { camelizeKeys } from 'humps';
import { fromObj } from 'form-data-to-object';
import invariant from 'invariant';
import Qs from 'qs';
import isPlainObject from 'lodash/isPlainObject';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import AsyncHelper from '@lendinghome/async-helper';
import { LendingHomeError, LendingHomeAPIError, LendingHomeGatewayTimeoutError } from '../errors';
import { UNKNOWN_STATUS_MSG, NETWORK_ERROR_MSG, UNAUTHORIZED_ERROR_CODE } from '../errors/constants';
import getCsrfToken from '../utils/get_csrf_token';
import getGraphqlUrl from '../utils/get_graphql_url';
import { HttpMethod } from './constants';

const ERROR_CATEGORY = 'fetchAPI';

const { assign } = Object;

const logInfo = (message, data = {}) => {
  if (message && window.Frogger) {
    const formattedMessage = `${message} @ ${moment().format('HH:mm:ss.ms')}`;
    if (data.status && data.status >= 400) {
      Frogger.warn(formattedMessage, data);
    } else {
      Frogger.info(formattedMessage, data);
    }
  }
};

function checkStatus(response) {
  if (response.status === 204) {
    return {
      isEmpty: true,
      response,
    };
  }

  if (response.status >= 200 && response.status < 300) {
    // this property cannot be trusted. APIs can return 200s with no content.
    // Content-Length cannot be trusted either. LH APIs have been observed as misbehaving
    // in both those cases.
    // The only way to correctly detect empty content is to response.text() and check
    // resulting text.length

    // This block is problematic on some browsers (e.clone().text() is undefined,
    // so adding a try/catch block to better log the issue)
    try {
      return response
        .clone()
        .text()
        .then((text) => ({
          isEmpty: !text.length,
          response,
        }));
    } catch (error) {
      throw new LendingHomeError(NETWORK_ERROR_MSG, error, {
        data: { response },
        tags: { source: ERROR_CATEGORY },
      });
    }
  } else if (response.status === 504) {
    throw new LendingHomeGatewayTimeoutError({
      response,
      tags: { source: ERROR_CATEGORY },
    });
  }

  const message = response.statusText || `${response.status || UNKNOWN_STATUS_MSG} - An unknown HTTP error occurred`;
  throw new LendingHomeAPIError(message, {
    response,
    tags: { source: ERROR_CATEGORY },
  });
}

export const extractErrorsFromResponse = (error) => {
  if (!error.response) throw error;

  if (typeof error.response.json !== 'function') throw error;

  return error.response
    .json()
    .then((data) => {
      if (data.errors) error.errors = data.errors;
      error.body = data;
      throw error;
    })
    .catch(() => {
      // json parsing error
      throw error;
    });
};

// will take a request, expecting it to return an async_helper job_id,
// it will monitor that job for progress then resolve the job response
// when it completes
function asyncPlumbing(request /* fetch promise */, timeout /* in ms */) {
  return new Promise((resolve, reject) => {
    request.then((response) => {
      // this is what you get back from AsyncHelper::ActionControllerHelper controller
      // before_action hook
      response
        .json()
        .then(camelizeKeys)
        .then(({ jobId }) => {
          if (jobId === undefined) {
            throw new Error('fetchApi async: missing job_id, are you sure action is enabled for async?');
          }
          const work = AsyncHelper.subscribe(jobId, null, timeout);
          // this is what you get back from AsyncHelper::ActionControllerHelper::Runner
          work
            .then(({ failed, data }) => {
              if (failed) {
                throw new Error('fetchApi async: async job failed');
              }
              // create a whatwg fetch reponse, shape of data response from controller helper
              // already resembles one
              const { body, headers, status, statusText } = data.response;
              // something chrome doesn't like about this guy, i don't think it's a security
              // thing as guard is 'none' in this constructor form. Thinking bad string format
              // simply deleting as doubt anyone will need to read that.
              delete headers['Set-Cookie'];
              const blody = new Blob([body], {
                type: headers['Content-Type'] || '',
              });
              const localResponse = new Response(blody, {
                headers,
                status,
                statusText,
              });
              return resolve(localResponse);
            })
            .catch(reject);
        });
    });
    request.catch(reject);
  });
}

export function urlWithQueryParams(_url, params) {
  const url = _url.split('?');
  const start = url[0];
  let qs = (url[1] || '').split('#')[0];
  const end = url[1] && url[1].split('#').length > 1 ? `#${url[1].split('#')[1]}` : '';
  qs = isString(params) ? params : Qs.stringify(assign(Qs.parse(qs), params));
  if (qs !== '') {
    qs = `?${qs}`;
  }
  return start + qs + end;
}

// fetchApi() - window.fetch() wrapper
// ```
// fetchApi('/foo', { body: ... })
//   .then(({isEmpty, response}) => { ... })
// ```
// @param options.async {boolean|Number} will run the request using async_helper
// when Number, defines timeout duration (default 2 mins)
export default function fetchApi(url, options = {}) {
  const { headers = {}, ...otherOptions } = options;
  const { async } = otherOptions;
  delete otherOptions.async;
  const csrfToken = getCsrfToken();

  if (csrfToken) {
    headers['X-CSRF-Token'] = csrfToken;
  }

  if (async) {
    headers['X-Lh-Async'] = 'yes';
  }

  const fetchOptions = {
    headers: {
      Accept: '*/*',
      'X-Requested-With': 'XMLHttpRequest',
      ...headers,
    },

    credentials: 'same-origin',
    ...otherOptions,
  };

  logInfo('fetchApi: began fetch request', { url, fetchOptions });
  let req = fetch(url, fetchOptions);

  if (async) {
    const timeout = isNumber(async) ? async : 2 * 60 * 1000; /* 2 min default */
    req = asyncPlumbing(req, timeout);
  }

  req.then((response) => {
    logInfo('fetchApi: completed fetch request', {
      status: response.status,
      statusText: response.statusText,
      bodyUsed: response.bodyUsed,
      requestId: response.headers.get('x-request-id'),
    });
  });

  return req.then(checkStatus).catch((error) => {
    error.debugFetch = { url, ...fetchOptions };

    Sentry.addBreadcrumb({
      message: 'Error occurred while making a request',
      category: ERROR_CATEGORY,
      level: 'error',
      data: { error },
    });

    throw error;
  });
}

function bodyWithRailsMethodHack(body, method) {
  return fromObj({
    ...body,
    _method: method,
  });
}

// fetchApi.json() - json oriented window.fetch() wrapper
// ```
// fetchApi.json('/foo', { body: { ... } })
//   .then(responseBody => { ... })
// ```
// will camelizeKeys of responseBody by default (transformKeys: true)
export function json(url, options = {}) {
  const { headers = {}, body, transformKeys = true, ...otherOptions } = options;
  const { method } = otherOptions;

  invariant(isEmpty(body) || isPlainObject(body), 'fetchApi.json expects body to be an object');

  let hackedBody;

  // TODO: Remove this when we are fully on phantomjs 2.x everywhere
  //
  // Doing this due to phantomjs not sending request body for DELETE requests. See:
  // https://github.com/ariya/phantomjs/issues/10873
  // for more info.
  //
  if (method === HttpMethod.Delete) {
    hackedBody = bodyWithRailsMethodHack(body, method);
    hackedBody = Object.keys(hackedBody)
      .map((key) => [encodeURIComponent(key.replace(/\[\d\]/, '[]')), encodeURIComponent(hackedBody[key])].join('='))
      .join('&');
    otherOptions.method = 'POST';
    headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  } else {
    hackedBody = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  /* eslint-disable no-shadow */

  return fetchApi(url, {
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    body: hackedBody,
    ...otherOptions,
  })
    .catch(extractErrorsFromResponse)
    .then(({ isEmpty, response }) => (isEmpty ? {} : response.json()))
    .then(transformKeys ? camelizeKeys : (a) => a);
}

export class GraphQLError extends Error {
  constructor(errors, data, body) {
    super('Errors occurred during GraphQL request');
    this.errors = errors;
    this.data = data;
    this.body = body;
  }
}

const hasErrors = (errors, code) =>
  errors.some((error) => {
    const extenstions = error.extensions || {};
    return extenstions.code === code;
  });

export function graphql(body, graphqlUrl = getGraphqlUrl()) {
  return json(graphqlUrl, {
    body,
    method: 'POST',
    credentials: 'include',
    transformKeys: false,
  }).then(({ data, errors }) => {
    if (errors) {
      // TODO
      // Lending Growth to clean this https://lendinghome.atlassian.net/browse/CXO-1281
      if (
        (window.location.host.includes('app.') || window.location.host.includes('3000')) &&
        hasErrors(errors, UNAUTHORIZED_ERROR_CODE)
      ) {
        window.location.pathname = '/users/sign_in';
      }
      throw new GraphQLError(errors, data, body);
    }
    return data;
  });
}

fetchApi.json = json;
fetchApi.graphql = graphql;
