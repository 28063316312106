import { ratioFormat } from '@lendinghome/core/components/FormatPercent';

export default class MinRatio {
  constructor({
    minRatio,
    compareToValue,
    compareToValueLabel,
    message,
    priority = null,
  }) {
    this.priority = priority;
    this.minRatio = minRatio;
    this.compareToValue = compareToValue;
    this.compareToValueLabel = compareToValueLabel;
    this.message =
      message ||
      `Must be at least ${ratioFormat.format(
        minRatio
      )} of ${compareToValueLabel || compareToValue}`;
  }

  isValid(value) {
    return Number(value) >= this.minRatio * this.compareToValue;
  }
}
