import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import FirstTimeHomeBuyerInputForm from './inputs/FirstTimeHomeBuyerInputForm';

function FirstTimeHomeBuyerFullScreen(props) {
  return (
    <GetRateFullScreen headline="Are you a first time home owner looking to finance your first home?">
      <FirstTimeHomeBuyerInputForm {...props} />
    </GetRateFullScreen>
  );
}

const FirstTimeHomeBuyer = FunnelCake({
  InputForm: FirstTimeHomeBuyerFullScreen,
});

export default FirstTimeHomeBuyer;
