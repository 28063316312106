import React from 'react';
import moment from 'moment';
import { setCookie } from '@lendinghome/utility-belt';
import { LoanProgram, HardMoneyLoanProduct } from '@lendinghome/core/constants';
import FunnelCake from '../FunnelCake';
import { updateAndCreateLoan } from '../../ajax';
import GetRateFullScreen from '../GetRateFullScreen';
import SubmitFullScreen from './SubmitFullScreen';

class SubmitStandardFullScreen extends React.Component {
  componentDidMount() {
    setCookie('showTOFUCalculatorWelcomeModal', 'true', null, '/');
  }

  render() {
    return (
      <GetRateFullScreen
        className="step--submit"
        slideBackground="no-background"
      >
        <SubmitFullScreen {...this.props} />
      </GetRateFullScreen>
    );
  }
}

const SubmitStandard = FunnelCake({
  InputForm: SubmitStandardFullScreen,
  mapValueToAjaxParams: () => ({
    // This is unfortunate.  We don't actually know the borrower has interior access, but the calculator bombs
    // on a fatal flaw rule if this isn't set.
    has_interior_access: true,

    // Likewise, this value is needed by the loan submit screen.  Before there was a such thing as the property
    // flow, this information was collected in short form.  Previously this had been handled by the calculator
    // when it took a TOFU application as the input and was responsible for building a loan.  It did the same
    // kludge default, but now that we're creating a loan before the calculator, we have to do this kludge earlier.
    borrower_requested_date_signing: moment()
      .add(30, 'days')
      .format('YYYY-MM-DD'),

    // TODO:
    // This is a reasonable initial guess, and it helps in allowing the calculator to even request a score.
    // But... we may choose rental further down the line.  We need a cleaner solution than this.
    program_tag: LoanProgram.HARD_MONEY,
    product_tag: HardMoneyLoanProduct.LH_BRIDGE_12M_FIXED,
  }),
  saveToServer: updateAndCreateLoan,
  saveFunnelStep: () => {},
});

export default SubmitStandard;
