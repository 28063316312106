import * as routes from '../routes';

const flow = 'bridgePropertyPreCalc';

export default [
  {
    step: 'propertyAddress',
    nextStep: (state) => {
      if (state.property.stateUnsupported()) {
        return { flow, step: 'unsupportedPropertyState' };
      }

      if (state.project?.isInfill()) {
        return { href: routes.infillRateCalculator(state.loan.getId()) };
      }

      return { href: routes.bridgeRateCalculator(state.loan.getId()) };
    },
    stepNumber: '4.0',
  },
  {
    step: 'unsupportedPropertyState',
    guard: (state) => state.property.stateUnsupported(),
    terminal: true,
    stepNumber: '4.2.1',
  },
];
