import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@lendinghome/core/components';
import { snakeCaseKeys } from '@lendinghome/utility-belt';
import SlideInfo from '../SlideInfo';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import { saveUnsupportedStepInfo } from '../../ajax';
import { unsupportedErrorInfoCollected } from '../../selectors';
import UnsupportedErrorInputForm from './inputs/UnsupportedErrorInputForm';

function UnsupportedFirstTimeHomeBuyerFullScreen({ unsupportedErrorInfoCollected: infoCollected, ...props }) {
  const borrowerHeadline = infoCollected
    ? "Thank you for your information. We'll be in touch!"
    : 'Sorry, we do not currently lend to first time home owners.';
  const borrowerInfo = infoCollected
    ? null
    : 'Please leave your contact information so we can get in touch with you if this changes in the future.';

  const goToHomepage = () => {
    window.location = '/';
  };

  return (
    <GetRateFullScreen headline={borrowerHeadline}>
      <SlideInfo>{borrowerInfo}</SlideInfo>
      {infoCollected && (
        <SlideInfo className="go-to-homepage__container">
          <Button
            size="large"
            onClick={goToHomepage}
          >
            Go Back
          </Button>
        </SlideInfo>
      )}
      <UnsupportedErrorInputForm
        hideBackButton={infoCollected}
        hideNextButton={infoCollected}
        nextButtonText="Keep me posted"
        nextButtonSize="xl"
        infoCollected={infoCollected}
        {...props}
      />
    </GetRateFullScreen>
  );
}

const mapStateToProps = (state) => ({
  unsupportedErrorInfoCollected: unsupportedErrorInfoCollected(state),
});

const UnsupportedFirstTimeHomeBuyer = FunnelCake({
  InputForm: connect(mapStateToProps)(UnsupportedFirstTimeHomeBuyerFullScreen),
  mapValueToAjaxParams: (value) => snakeCaseKeys(value),
  saveToServer: saveUnsupportedStepInfo,
  reenableUponComplete: true,
  saveFunnelStep: () => {},
});

export default UnsupportedFirstTimeHomeBuyer;
