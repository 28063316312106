import isNumericString from './isNumericString';

/**
 * stringToNumber converts a valid string to a Number
 * A string input is considered valid if it is a valid representation of a number
 * like percentage, money, etc.
 *
 * Ex: if a server returns a numeric formatted value in the form of a string,
 * such as rates = '2.5%'
 * We want to make the UI representation for such numbers consistent using our
 * core FormatPercent (or FormatMoney or FormattedNumber) component
 * say, all % must be accurate to 2-decimal places
 * Use this util method to convert '2.5%' => 2.5, to pass down to FormatPercent component
 *
 * @param {string} string [eg: "1", "1.0", "-1.0", "1%", "1.00%", "$1"]
 * @return {number}        [a numeric value - integer, decimal or negative]
 */
export default function stringToNumber(string) {
  if (string && !isNumericString(string) && isNaN(string)) {
    string = string.replace(/[^\d\.-]/g, '');
  }
  return Number(string);
}
