import React from 'react';
import { injectIntl } from 'react-intl';
import EventNotifier from '../../analytics/eventNotifier';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import { InfoAccordion, InfoAccordionList } from '../../../shared/components/InfoAccordion';
import InfillExitsLast60InputForm from './inputs/InfillExitsLast60InputForm';
import { BrokerLink } from './BrokerLink';

function InfillExitsLast60FullScreen(props) {
  const { formatMessage } = props.intl;

  return (
    <GetRateFullScreen headline={formatMessage({ id: 'components.funnel_cakes.infill.num_exits.title' })}>
      <InfoAccordion
        message={formatMessage({ id: 'components.funnel_cakes.infill.num_exits.new_construction_help.title' })}
      >
        <p>{formatMessage({ id: 'components.funnel_cakes.infill.num_exits.new_construction_help.paragraph' })}</p>
        <InfoAccordionList
          title={formatMessage({ id: 'components.funnel_cakes.infill.num_exits.new_construction_help.list.title' })}
          items={formatMessage({ id: 'components.funnel_cakes.infill.new_construction_help.list.items' })}
        />
      </InfoAccordion>
      <InfillExitsLast60InputForm {...props} />
      <BrokerLink />
    </GetRateFullScreen>
  );
}

const InfillExitsLast60 = FunnelCake({
  InputForm: injectIntl(InfillExitsLast60FullScreen),
  notifyStepLoadedHandlers: [EventNotifier.notifyHardMoneyFlowLoaded, EventNotifier.notifyStepLoaded],
  notifyStepCompletedHandlers: [EventNotifier.notifyStepCompleted, EventNotifier.notifyIfProPlus],
  mapPropsToAjaxParams: () => ({ account_type: 'borrower' }),
});

export default InfillExitsLast60;
