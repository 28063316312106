import fetchApi from '@lendinghome/core/net/fetchApi';
import S3 from 'aws-sdk/clients/s3';

/**
 * Returns a promise that resolves to a properly authenticated S3 service object.
 *
 * @returns Promise
 */
S3.getService = () => {
  const headers = { Accept: 'application/json' };
  const baseEndpoint = window.gon.s3_endpoint;
  const baseApiUrl = [baseEndpoint, '/aws_s3/temporary_credentials'].join('');
  return (
    fetchApi(baseApiUrl, { headers })
      // fetchApi returns a wrapped response: { response: { ... }, ... }
      .then((res) => res.response.json())
      .then((credentials) => {
        const s3 = new S3({
          endpoint: baseEndpoint,
          accessKeyId: credentials.access_key_id,
          secretAccessKey: credentials.secret_access_key,
          sessionToken: credentials.session_token,
        });

        // Stores orginal credentials object so it is available outside this method
        s3.credentials = credentials;

        return s3;
      })
  );
};

export default S3;
