import React from 'react';
import FunnelCake from '../FunnelCake';
import EventNotifier from '../../analytics/eventNotifier';
import GetRateFullScreen from '../GetRateFullScreen';
import YourNameInputForm from './inputs/YourNameInputForm';

function YourNameFullScreen(props) {
  return (
    <GetRateFullScreen headline="What is your name?">
      <YourNameInputForm {...props} />
    </GetRateFullScreen>
  );
}

const YourName = FunnelCake({
  InputForm: YourNameFullScreen,
  notifyStepCompletedHandlers: [EventNotifier.notifyStepCompleted, EventNotifier.notifyFlowCompleted],
});

export default YourName;
