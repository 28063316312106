import React from 'react';
import Geosuggest from 'react-geosuggest';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';

function GeosuggestInput({
  onChange,
  onBlur,
  onSuggestSelect,
  value,
  disabled,
  htmlFor,
  className,
  placeholder,
  ...rest
}) {
  const getSuggestLabel = (suggestion) =>
    // HACK: prevent 'United States' from displaying on all addresses
    suggestion && suggestion.description && suggestion.description.replace(', USA', '');

  return (
    <Geosuggest
      placeholder={placeholder}
      name={htmlFor}
      id={htmlFor}
      maxLength="256"
      inputClassName={className}
      onChange={onChange}
      onBlur={onBlur}
      onSuggestSelect={onSuggestSelect}
      disabled={disabled}
      country="us"
      getSuggestLabel={getSuggestLabel}
      types={['address']}
      initialValue={value || ''}
      {...rest}
    />
  );
}

export default CreateVerifyingTextField({ Input: GeosuggestInput });
