// Supplants "LHAnalytics (_a)"
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import * as eventTypes from './eventTypes';
import * as analyticsKit from './helpers/analyticsKit';
import defaultClient from './defaultClient';
import createHelpers from './createHelpers';
import createDebugHelper from './createDebugHelper';

const LOG_PREFIX = '[Analytics]';
const INFO = 'info';

// TODO: Refactor this use splat args & iterators if we ever add a third
// argument to get props from
function mergeProps(eventPayload, originalPayload = {}) {
  if (isEmpty(eventPayload.properties) && isEmpty(originalPayload.properties)) {
    return eventPayload;
  }

  let newProperties = {};

  if (!isEmpty(eventPayload.properties)) {
    newProperties = {
      ...newProperties,
      ...eventPayload.properties,
    };
  }

  if (!isEmpty(originalPayload.properties)) {
    newProperties = {
      ...newProperties,
      ...originalPayload.properties,
    };
  }

  eventPayload.properties = newProperties;

  return eventPayload;
}

// Translates EventBus events into event specs that segment understands.
// For correct spec data structures, see:
//  https://segment.com/docs/sources/website/analytics.js/
function getPayloadForEventType(eventType, triggeredEvent, { originalPayload, eventPayload = {} }) {
  switch (eventType) {
    case eventTypes.TRACK: {
      const { properties, event = triggeredEvent, options } = mergeProps(eventPayload, originalPayload);
      return [event, properties, options];
    }
    case eventTypes.PAGE: {
      const { name, properties, options, pageName } = eventPayload;
      return [name || pageName, properties, options];
    }
    case eventTypes.IDENTIFY: {
      const { userId, traits, options } = eventPayload;
      return [userId, traits, options];
    }
    case eventTypes.GROUP: {
      const { groupId, traits, options } = eventPayload;
      return [groupId, traits, options];
    }
    case eventTypes.ALIAS: {
      const { userId, previousId, options } = eventPayload;
      return [userId, previousId, options];
    }
    case eventTypes.RESET: {
      return [];
    }
  }

  return new Error(`Unknown event type named ${eventType}`);
}

class SegmentEventBusPlugin {
  constructor({ mapper, logger, debug = false, getClient = defaultClient } = {}) {
    // Client must be passed as a function that returns an object that responds
    // to the analytics.js API; typically this will be window.analytics
    //
    // It MUST be a function otherwise we will end up with an analytics object
    // captured at a point where it might not be ready to make calls
    this.getClient = getClient;
    this.mapper = mapper;
    this.logger = logger || console;
    this.helpers = createHelpers(getClient);

    createDebugHelper(this);

    if (debug) {
      this.debugEnabled = true;
    }
  }

  log(severity, ...messages) {
    if (this.debugEnabled) {
      if (typeof messages[0] === 'string') {
        messages[0] = `${messages[0]}`;
      } else {
        messages.unshift(LOG_PREFIX);
      }
      this.logger[severity](...messages);
    }
  }

  startLoggingGroup(message) {
    if (this.debugEnabled) {
      const groupMessage = [LOG_PREFIX, `:: ${message}`].join(' ');

      this.logger.group ? this.logger.group(groupMessage) : this.logger.info(groupMessage);
    }
  }

  endLoggingGroup() {
    if (this.debugEnabled && this.logger.groupEnd) {
      this.logger.groupEnd();
    }
  }

  trigger(event, payload) {
    this.startLoggingGroup(`event ${event} received`);
    this.log(INFO, `%c event`, `color: blue`, event);
    this.log(INFO, `%c payload`, `color: blue`, payload);
    if (this.mapper) {
      const mapped = this.mapper[event];

      if (typeof mapped === 'function') {
        const specs = mapped(payload, event, this.helpers);

        if (!specs) return;

        if (Array.isArray(specs)) {
          this.callMultipleSpecs(specs, event, payload);
        } else {
          this.callIndividualSpec(specs, event, payload);
        }
      } else if (typeof mapped === 'string') {
        this.callIndividualSpec({ eventType: mapped, eventPayload: { event } }, event, payload);
      }
    } else {
      const spec = payload || { eventType: 'track' };
      this.callIndividualSpec(spec, event, payload);
    }
    this.endLoggingGroup();
  }

  callClient(eventType, ...args) {
    // NOTE: process.env.ENABLE_SEGMENT_EVENT_BUS_PLUGIN is defined at build time
    const enabled = window.env
      ? window.env.ENABLE_SEGMENT_EVENT_BUS_PLUGIN === true
      : process.env.ENABLE_SEGMENT_EVENT_BUS_PLUGIN === 'true';
    if (!enabled) {
      this.log(INFO, `client integration is disabled in environment -- would have called client[${eventType}]`);
      return;
    }

    const client = this.getClient();
    this.log(INFO, `3rd party request invoke: client[${eventType}]()`);
    if (typeof client[eventType] !== 'function') {
      this.logger.error(`client.${eventType} is not supported by client:`, client);
      return;
    }
    client[eventType](...args);
  }

  // Handles payloads sent to the SegmentEventBusPlugin
  // This is where global segment logic goes
  callMultipleSpecs(specs, ...args) {
    for (const spec of specs) {
      this.callIndividualSpec(spec, ...args);
    }
  }

  callIndividualSpec({ eventType, eventPayload = {} }, triggeredEvent, originalPayload) {
    let result = getPayloadForEventType(eventType, triggeredEvent, {
      originalPayload,
      eventPayload,
    });

    result = compact(result);

    this.log(INFO, `%c ${eventType}`, `color: darkGray; font-weight: bold`, result);

    this.callClient(eventType, ...result);
  }
}

export default SegmentEventBusPlugin;
export { eventTypes, analyticsKit };
