import PropTypes from 'prop-types';
import React from 'react';
import isFinite from 'lodash/isFinite';
import { FormattedNumber } from 'react-intl';

const { number, any, object } = PropTypes;

export default function FormattedNumberWithDefault({
  defaultValue,
  value,
  formatOptions,
}) {
  return isFinite(value) ? (
    <FormattedNumber
      {...formatOptions}
      value={value}
    />
  ) : (
    <span>{defaultValue}</span>
  );
}

FormattedNumberWithDefault.propTypes = {
  defaultValue: any.isRequired,
  value: number.isRequired,
  formatOptions: object,
};
