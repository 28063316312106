import { json, HttpMethod } from '@lendinghome/core';
import * as routes from '../routes';

export function updateLead(params) {
  return json('/get_rate_flow/lead', {
    body: params,
    method: HttpMethod.Patch,
  });
}

export function checkEligibility(loanId) {
  return json(routes.checkFatalFlaws(loanId), {
    method: HttpMethod.Get,
  });
}

export function updateConvertedLead(loanId, params) {
  return json(`/pflow/${loanId}/update_lead`, {
    body: params,
    method: HttpMethod.Patch,
  });
}

export function updatePropertyLoan(loanId, loanParams) {
  return json(`/pflow/${loanId}`, {
    body: loanParams,
    method: HttpMethod.Patch,
  });
}

export function updateUser(loanId, params) {
  return json(`/pflow/${loanId}/update_user`, {
    body: params,
    method: HttpMethod.Patch,
  });
}

export function updateFunnelVisit(funnelVisitId, params) {
  return json('/funnel_visits', {
    body: { id: funnelVisitId, funnel_visit: params },
    method: HttpMethod.Patch,
  });
}

export function updateAddress(loanId, addressId, params) {
  return json(routes.updateAddress(loanId, addressId), {
    body: params,
    method: HttpMethod.Patch,
  });
}

export function propertyStateSupportedForLoanProgram(loanId, stateCode) {
  return json(`/pflow/state_supported?state=${stateCode}&loan_id=${loanId}`, {
    method: HttpMethod.Get,
  });
}

/* This was moved from TOFU to LoansController since Sales team is still using this data. */
export function completeInitialApplication(loanId) {
  return json(`/loans/${loanId}/complete_initial_application`, {
    method: HttpMethod.Post,
  });
}
