import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { borrowerHasApprovedTofu } from '../../selectors';
import PropertyFunnelCake from '../PropertyFunnelCake';
import PropertyFlowFullScreen from '../PropertyFlowFullScreen';
import GetRateFullScreen from '../GetRateFullScreen';
import {
  savePropertyAddressThenCheckStateSupported,
  checkEligibility,
  swapApplicants,
  checkIfPropertyStateUnsupported,
} from '../../ajax';
import { completeInitialApplication } from '../../ajax/primitive';
import FunnelCake from '../FunnelCake';
import PropertyAddressInputForm from './inputs/PropertyAddressInputForm';

export async function completeBridgePropertyAddressStep({ shouldSkipEligibilityCheck, ...params }, setFunnelMetaData) {
  const supported = await savePropertyAddressThenCheckStateSupported(params, setFunnelMetaData);

  await completeInitialApplication(params.loanId);

  if (!supported) {
    return;
  }

  await swapApplicants(params, setFunnelMetaData);

  if (!shouldSkipEligibilityCheck) {
    await checkEligibility(params, setFunnelMetaData);
  }
}

const headline = {
  standardBorrower: 'One last question to get your personalized rate:',
  proBorrower: 'One last question to get your personalized rate:',
  bridgePropertyPreCalc: 'What is the address of the property you would like to purchase?',
  bridgeProperty: 'What is the address of the property you would like to purchase?',
  brokerProperty: 'What is the address of the property your client would like to finance?',
  proBridgeProperty: 'What is the address of the property you would like to finance?',
};

function PropertyAddressFullScreen(props) {
  const { flow } = props;

  return (
    <PropertyFlowFullScreen
      slideBackground="doors-outline"
      headline={headline[flow]}
    >
      <PropertyAddressInputForm {...props} />
    </PropertyFlowFullScreen>
  );
}

function PropertyAddressPreCalcFullScreen(props) {
  const { flow } = props;

  return (
    <PropertyFlowFullScreen
      slideBackground="doors-outline"
      headline={headline[flow]}
    >
      <PropertyAddressInputForm
        {...props}
        hideBackButton
        hideSkipButton={false}
      />
    </PropertyFlowFullScreen>
  );
}

function LeadPropertyAddressFullScreen(props) {
  const { flow } = props;

  return (
    <GetRateFullScreen headline={headline[flow]}>
      <p>What is the address of the property you would like to finance?</p>
      <PropertyAddressInputForm
        {...props}
        hideSkipButton={false}
      />
    </GetRateFullScreen>
  );
}

export const PropertAddressLead = FunnelCake({
  InputForm: LeadPropertyAddressFullScreen,
  mapValueToAjaxParams: (value) => ({
    address_line1: value.line1,
    address_line2: value.line2,
    address_city: value.city,
    address_state: value.state,
    address_zip: value.zip,
  }),
  saveToServer: checkIfPropertyStateUnsupported,
});

export const PropertyAddress = PropertyFunnelCake({
  InputForm: PropertyAddressFullScreen,
  mapValueToAjaxParams: (value) => value,
  saveToServer: savePropertyAddressThenCheckStateSupported,
});

export const PropertyAddressBridgePreCalc = PropertyFunnelCake({
  InputForm: PropertyAddressPreCalcFullScreen,
  mapValueToAjaxParams: (value) => value,
  saveToServer: savePropertyAddressThenCheckStateSupported,
});

const PropertyAddressBridgeCake = PropertyFunnelCake({
  InputForm: PropertyAddressFullScreen,
  mapValueToAjaxParams: (value) => value,
  mapPropsToAjaxParams: (props) => ({
    shouldSkipEligibilityCheck: props.shouldSkipEligibilityCheck,
  }),
  saveToServer: completeBridgePropertyAddressStep,
});

PropertyAddressBridgeCake.propTypes = {
  shouldSkipEligibilityCheck: bool,
};

const mapStateToProps = (state) => ({
  shouldSkipEligibilityCheck: borrowerHasApprovedTofu(state),
});

export const PropertyAddressBridge = connect(mapStateToProps)(PropertyAddressBridgeCake);
