import React from 'react';
import Input from '@lendinghome/core/components/Input';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const percentOfReference = (value, referenceValue) => {
  if (value && referenceValue) {
    const percent = (value / referenceValue) * 100;
    return `(${Math.floor(percent)}%)`;
  }
};

export default function RatioInput({
  className,
  value,
  input,
  referenceValue,
  fieldsetClassName,
  ...props
}) {
  return (
    <Input
      {...props}
      id={props.id || props.name}
      value={value}
      inputComponent={input}
      className={className}
      isFloatInput={false}
      isRatioInput
      fieldsetAttributes={{
        'data-percent-complete': percentOfReference(value, referenceValue),
      }}
      fieldsetClassName={classNames(
        fieldsetClassName,
        'ratio-input-decoration'
      )}
      debounceOnChange={false}
    />
  );
}

RatioInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.node,
  referenceValue: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
