import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SvgIcon } from '../../components-core';

const { func, string } = PropTypes;

export default function Header({ clickedPhone, logoIcon = 'kiavi-logo', phoneNumber, className }) {
  const classes = classnames('lh-header', className);
  const marketingSite = gon.MARKETING_SITE;
  return (
    <header className={classes}>
      <div className="header-container">
        <a href={marketingSite}>
          <div className="header-logo">
            <SvgIcon
              icon={logoIcon}
              className="is-illustration"
            />
          </div>
        </a>
        <nav className="header-right-section">
          {phoneNumber && (
            <a
              href={`tel:${phoneNumber}`}
              tabIndex="-1"
              className="nav--item phone-link"
              onClick={clickedPhone}
            >
              Questions?&nbsp;
              {phoneNumber}
            </a>
          )}
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  clickedPhone: func,
  logoIcon: string,
  phoneNumber: string,
  className: string,
};
