import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Fieldset({ children, title, fieldsetLayout }) {
  const classes = classnames(
    {
      'multi-column': fieldsetLayout && fieldsetLayout.includes('two-column'),
    },
    fieldsetLayout
  );
  return (
    <fieldset>
      {title ? <div className="legend">{title}</div> : null}
      <section className={classes}>{children || null}</section>
    </fieldset>
  );
}

Fieldset.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  fieldsetLayout: PropTypes.string,
};
