import { set, merge } from 'lodash';

export default function mapStepValueToServerParams({ step, value, clientToServerMapping }) {
  let result = {};
  const mapping = clientToServerMapping[step];

  if (typeof mapping === 'object') {
    Object.keys(mapping).forEach((stepField) => {
      merge(result, set({}, mapping[stepField], value[stepField]));
    });
  } else if (mapping) {
    set(result, mapping, value);
  }
  return result;
}
