import moment from 'moment';
import isNil from 'lodash/isNil';

// Using moment.js date parsing. https://momentjs.com/docs/#/parsing/string-format/

export default class Date {
  constructor({
    format = 'MM/DD/YYYY',
    message = `Invalid date. Please use a valid ${format} formatted date.`,
    priority = null,
  }) {
    this.format = format;
    this.message = message;
    this.priority = priority;
  }

  checkValue(value) {
    try {
      const date = moment(value, this.format, true);
      return date.isValid();
    } catch (e) {
      return false;
    }
  }

  isValid(value) {
    // null values are not valid comparisons; should be handled by a required validator
    if (isNil(value) || value === '') return true;

    const retVal = this.checkValue(value);
    if (!retVal) {
      Frogger && Frogger.debug(`Invalid date specified: ${value}`);
    }
    return retVal;
  }
}
