import React from 'react';
import { connect } from 'react-redux';
import { LendingHomeFatalError } from '@lendinghome/core';
import { string } from 'prop-types';
import { borrowerEmail } from '../../../selectors';
import { userSignIn, resetPassword } from '../../../ajax';
import ResetPassword from '../../ResetPassword';
import SignInInputForm from './SignInInputForm';

class SignInSubmitter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { failedAttempts: 0, validating: false, passwordHasBeenReset: false };
    this.attemptSignIn = this.attemptSignIn.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.wrongPasswordEntered = this.wrongPasswordEntered.bind(this);
  }

  attemptSignIn() {
    this.setState({ validating: true });
    const { value, email, onSubmit } = this.props;
    const password = value;
    Promise.resolve(userSignIn({ email, password })).then(
      (success) => {
        if (success) {
          this.setState({ failedAttempts: 0 });
          onSubmit();
        } else {
          this.setState((previousState) => ({
            failedAttempts: previousState.failedAttempts + 1,
            validating: false,
          }));
        }
      },
      (error) => {
        throw new LendingHomeFatalError(`Error signing in user: ${error}`);
      }
    );
  }

  handleResetPassword(splits) {
    Promise.resolve(resetPassword(this.props.email, splits)).then(
      () => {
        this.setState({ passwordHasBeenReset: true });
      },
      (error) => {
        throw new LendingHomeFatalError(`Error resetting password: ${error}`);
      }
    );
  }

  wrongPasswordEntered() {
    return this.state.failedAttempts > 0;
  }

  render() {
    const invalidMessage = this.state.failedAttempts === 0 ? null : 'Invalid email or password.';
    const isValid = this.state.validating || !this.wrongPasswordEntered();
    const disabled = this.props.disabled || this.state.validating;
    const childProps = {
      ...this.props,
      disabled,
      onSubmit: this.attemptSignIn,
      invalidMessage,
      isValid,
    };

    return (
      <SignInInputForm {...childProps}>
        <ResetPassword
          handleResetPassword={this.handleResetPassword}
          passwordHasBeenReset={this.state.passwordHasBeenReset}
          disabled={disabled}
        />
      </SignInInputForm>
    );
  }
}

SignInSubmitter.propTypes = {
  email: string.isRequired,
};

const mapStateToProps = (state) => ({ email: borrowerEmail(state) });

export default connect(mapStateToProps)(SignInSubmitter);
