import { LoanProgram } from '@lendinghome/core/constants';
import { browserHistory } from 'react-router';

const PATHNAME_ROOT = '/m';

export function flowStepToString({ flow, step }) {
  return `${flow}/${step}`;
}

export function flowStepFromString(flowStep) {
  if (!flowStep || !flowStep.includes('/')) {
    return null;
  }
  const [flow, step] = flowStep.split('/');
  return { flow, step };
}

export function flowStepFromPathname(pathname) {
  if (!pathname || !pathname.includes('/')) {
    return null;
  }
  const parts = pathname.split('/');

  if (parts.length > 4) {
    const [, , flow, loanId, step = null] = parts;
    return { flow, loanId, step };
  }

  const [, , flow, step = null] = parts;
  return { flow, step };
}

export function flowStepPath({ flow, step, loanId }) {
  let result = `${PATHNAME_ROOT}/${flow}`;
  if (loanId) {
    result += `/${loanId}`;
  }
  if (step) {
    result += `/${step}`;
  }
  return result;
}

export function rootFlowStep(program, funnelExperimentName = undefined) {
  switch (program) {
    case LoanProgram.HARD_MONEY:
      return { flow: 'getRate', step: 'exitsLast24' };
    case LoanProgram.RENTAL:
      return { flow: 'rentalBorrower', step: 'dealsLast24' };
    case 'broker':
      return { flow: 'broker', step: 'index' };
    case [funnelExperimentName]:
    default:
      return { flow: 'getRate', step: 'index' };
  }
}

export function navigateTo({ flow, step, loanId, href }) {
  if (href) {
    window.location = href;
  } else if (flow && step) {
    browserHistory.push(flowStepPath({ flow, step, loanId }) + window.location.search);
  }
}
