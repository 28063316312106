/*\
      |*|
      |*|  :: cookies.js ::
      |*|
      |*|  A complete cookies reader/writer framework with full unicode support.
      |*|
      |*|  Revision #1 - September 4, 2014
      |*|
      |*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
      |*|  https://developer.mozilla.org/User:fusionchess
      |*|  https://github.com/madmurphy/cookies.js
      |*|
      |*|  This framework is released under the GNU Public License, version 3 or later.
      |*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
      |*|
      |*|  Syntaxes:
      |*|
      |*|  * setCookie(name, value[, end[, path[, domain[, secure]]]])
      |*|  * getCookie(name)
      |*|  * removeCookie(name[, path[, domain]])
      |*|  * hasCookie(name)
      |*|  * cookieKeys()
      |*|
      \*/

export const getCookie = (sKey) => {
  if (!sKey) {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      )
    ) || null
  );
};

export const setCookie = (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
  if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  var sExpires = '';
  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd;
        break;
      case String:
        sExpires = '; expires=' + vEnd;
        break;
      case Date:
        sExpires = '; expires=' + vEnd.toUTCString();
        break;
    }
  }
  document.cookie =
    encodeURIComponent(sKey) +
    '=' +
    encodeURIComponent(sValue) +
    sExpires +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '') +
    (bSecure ? '; secure' : '');
  return true;
};

export const removeCookie = (sKey, sPath, sDomain) => {
  if (!hasCookie(sKey)) {
    return false;
  }
  document.cookie =
    encodeURIComponent(sKey) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '');
  return true;
};

export const hasCookie = (sKey) => {
  if (!sKey) {
    return false;
  }
  return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
    document.cookie
  );
};

export const getCookieKeys = () => {
  var aKeys = document.cookie
    .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
    .split(/\s*(?:\=[^;]*)?;\s*/);
  for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
    aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
  }
  return aKeys;
};
