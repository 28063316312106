import React, { Component } from 'react';

export default class SafeComponent extends Component {
  constructor(props) {
    super(props);
    this.render = this.safeRender.bind(this, this.render);
  }

  // Returns your function instance auto-wrapped in a try/catch block. Also consumes/reports errors.
  // Useful for event handlers.
  safeBind(f, ...args) {
    return this.safe.bind(this, f, false, ...args);
  }

  // Auto-binds to your component's render function and consumes/reports errors
  safeRender(f, ...args) {
    return this.safe(f, true, ...args);
  }

  safe(f, renderErrorComponent, ...args) {
    try {
      return f.call(this, ...args);
    } catch (e) {
      if (Frogger) {
        Frogger.error(e);
      }
      return this.errorComponent();
    }
  }

  // Override this method to change the component which displays when a *render* error occurs
  errorComponent() {
    return <div />;
  }
}
