import React from 'react';
import classnames from 'classnames';
import { bemNamesFactory } from 'bem-names';

const bem = bemNamesFactory('social-proof');

function SocialProof({ description, location, name, quote, state }) {
  return (
    <div className={bem('container')}>
      <div className={bem('top-quote-mark')}>&ldquo;</div>
      <p className={bem('quote-text')}>
        {quote}
        <div className={bem('bottom-quote-mark')}>&rdquo;</div>
      </p>
      <div className={bem('author-container')}>
        <div className={classnames(bem('quote-photo'), state)} />
        <div className={bem('quote-author')}>
          <p className={bem('author-name')}>{name}</p>
          <p className={bem('author-location')}>{location}</p>
          {location && <p className={bem('author-description')}>{description}</p>}
        </div>
      </div>
    </div>
  );
}

export default SocialProof;
