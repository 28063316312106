import React from 'react';
import { MaskedNumberInput } from '@lendinghome/core/components';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


function NumberInput({ allowDecimal = false, ...props }) {
  const numberMask = createNumberMask({ allowDecimal, prefix: '' });

  const input = (<MaskedNumberInput
    {...props}
    mask={numberMask}
  />);

  return input;
}

NumberInput.propTypes = {
  allowDecimal: PropTypes.bool,
};

export default NumberInput;
