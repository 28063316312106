import React from 'react';
import { uiPosition } from '@lendinghome/core';
import { Tooltip } from '@lendinghome/core/components';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const { oneOf, bool, node, string } = PropTypes;

export default function Button({
  children,
  size,
  showSpinner,
  showAsLink,
  isSecondary,
  isDanger,
  isOnColor,
  isPositive,
  disabled,
  disabledReason,
  tooltipPosition,
  className,
  ...props
}) {
  const classes = classnames(
    {
      button: !showAsLink,
      'button--text': showAsLink,
      [`is-button-${size}`]: size !== 'normal',
      'is-button-secondary': isSecondary,
      'is-button-danger': isDanger,
      'is-button-on-color': isOnColor,
      'is-button-positive': isPositive,
      'button--spinner': showSpinner,
    },
    className
  );

  const button = (
    <button
      className={classes}
      disabled={showSpinner || disabled}
      {...props}
    >
      {children}
    </button>
  );

  if (disabled && disabledReason) {
    return (
      <Tooltip
        desc={disabledReason}
        position={tooltipPosition}
      >
        {button}
      </Tooltip>
    );
  }

  return button;
}

Button.propTypes = {
  size: oneOf(['large', 'normal', 'small']),
  isSecondary: bool,
  isDanger: bool,
  isOnColor: bool,
  isPositive: bool,
  showAsLink: bool,
  showSpinner: bool,
  disabled: bool,
  disabledReason: string,
  tooltipPosition: oneOf(Object.values(uiPosition)),
  children: node,
};

Button.defaultProps = {
  size: 'normal',
  isSecondary: false,
  isDanger: false,
  isOnColor: false,
  isPositive: false,
  showAsLink: false,
  showSpinner: false,
  disabled: false,
  disabledReason: null,
  tooltipPosition: uiPosition.TOP,
  children: null,
};
