import React from 'react';
import FunnelCake from '../FunnelCake';
import EmptyFunnelStepForm from '../EmptyFunnelStepForm';
import GetRateFullScreen from '../GetRateFullScreen';

function BrokerBridgeRentalFailureFullScreen(props) {
  return (
    <GetRateFullScreen
      headline={
        'Unfortunately, we only offer business-purpose loans secured by 1-4 family properties which are not owner-occupied.'
      }
    >
      <EmptyFunnelStepForm
        hideNextButton
        {...props}
      >
        If you&apos;re interested in providing bridge or rental loans to your real estate investor clients, please go
        back and try again.
      </EmptyFunnelStepForm>
    </GetRateFullScreen>
  );
}

const BrokerBridgeRentalFailure = FunnelCake({
  InputForm: BrokerBridgeRentalFailureFullScreen,
  mapValueToAjaxParams: null,
});

export default BrokerBridgeRentalFailure;
