import React from 'react';
import { SelectBar } from '@lendinghome/core/components';
import {
  number,
  func,
  oneOfType,
  string,
  bool,
  arrayOf,
  shape,
  any,
} from 'prop-types';

export default function VerticalSelectBar(props) {
  return (
    <div className="vertical-select">
      <SelectBar
        noVerticalSelectbar={false}
        {...props}
      />
    </div>
  );
}

VerticalSelectBar.defaultProps = {
  currentValue: undefined,
  cleanupFieldNames: true,
  className: null,
  dense: false,
  onChange: null,
  disabled: false,
};

VerticalSelectBar.propTypes = {
  /** The field value for controlled components */
  currentValue: oneOfType([string, number, bool]),
  /** The name for the field */
  fieldName: string.isRequired,
  /** If true, the fieldName provided is changed to kebab case before being used
   * in the input names and ids */
  cleanupFieldNames: bool,
  /** Whether to render the select bar with reduced margin and padding to fit in smaller spaces */
  dense: bool,
  /** Function to call when the user changes the selection */
  onChange: func,
  /** The options available for selection
   * const selectBarOptions = [
   *   {
   *     label: "Option 1",
   *     value: "First",
   *   },
   *   {
   *     label: "Option 2",
   *     value: "Second",
   *   },
   *   {
   *     label: "Option 3",
   *     value: true,
   *   }
   * ]
   */
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: any.isRequired,
    })
  ).isRequired,
  /** A className to be added to the top-most element */
  className: string,
  /** Whether the inputs are disabled */
  disabled: bool,
};
