import React, { Component, cloneElement } from 'react';
import { ensureChildrenHaveKeys } from '@lendinghome/utility-belt-react';
import { getEventValue, uiPosition, defaultDebounceOnChangeWait } from '@lendinghome/core';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import camelCase from 'lodash/camelCase';
import uniqueId from 'lodash/uniqueId';
import sortBy from 'lodash/sortBy';
import isPlainObject from 'lodash/isPlainObject';
import classnames from 'classnames';
import * as validators from '../validations';

const { string, bool, func, oneOf, number, oneOfType, shape, node, object } = PropTypes;

const validationProps = {};

for (const [name, validator] of Object.entries(validators)) {
  validationProps[camelCase(name)] = validator;
}

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.uniqueId = uniqueId('input-');
    this.state = {
      value: '',
      errors: [],
      validations: [],
      touched: false,
    };
  }

  componentWillMount() {
    if (!isNil(this.props.value)) {
      this.setValueFromProps();
    }

    if (this.requiresValidation()) {
      this.setValidationsFromProps();
    }
  }

  componentWillReceiveProps(nextProps) {
    const isPropsChanged = !isEqual(this.props, nextProps);

    if (isPropsChanged) {
      this.setValueFromProps(nextProps);

      if (this.requiresValidation(nextProps)) {
        this.setValidationsFromProps(nextProps, () => {
          this.runValidations(this.state.value);
        });
      }
    }

    if (this.props.debouncedOnChange && !nextProps.debouncedOnChange) {
      this.debouncedOnChange = null;
    }
  }

  /**
   * @param {string[]} errors an array of error messages
   * @returns {void}
   */
  onError(errors) {
    if (!this.shouldFireChangeHandler && this.props.debounceOnChange) {
      this.debouncedOnChange.cancel();
    }

    this.props.onError && this.props.onError(errors);
  }

  get shouldFireChangeHandler() {
    return this.isValid || !this.props.blockEventsWhenInvalid;
  }

  get isValid() {
    return this.state.errors.length === 0;
  }

  setValidationsFromProps(props = this.props, callback) {
    const validations = [];

    Object.keys(validationProps).forEach((name) => {
      if (props[name]) {
        const options = isPlainObject(props[name]) ? props[name] : { [name]: props[name] };
        validations.push(new validationProps[name](options));
      }
    });

    this.setState(
      {
        validations: sortBy(validations, 'priority'),
      },
      callback
    );
  }

  wrappedEventWithValue(eventHandler) {
    return (e) => {
      const value = getEventValue(e);
      this.setState({
        value: value || '',
      });
      eventHandler(value, e);
    };
  }

  runValidations(value, callback = () => {}) {
    if (!this.state.touched) return;
    const firstInvalid = this.state.validations.find((validation) => !validation.isValid(value));

    if (firstInvalid) {
      this.onError([firstInvalid.message]);

      this.setState(
        {
          errors: [firstInvalid.message],
        },
        callback
      );
    } else {
      this.setState(
        {
          errors: [],
        },
        callback
      );
    }

    if (typeof this.props.onValidationComplete === 'function') {
      this.props.onValidationComplete(!firstInvalid);
    }
  }

  wrappedEventForValidation(eventHandler) {
    return this.wrappedEventWithValue((value, event) => {
      const { validations } = this.state;
      const callback = () => {
        if (this.shouldFireChangeHandler) {
          eventHandler(value, event);
        }
      };

      if (validations && validations.length > 0) {
        this.runValidations(value, callback);
      } else if (this.shouldFireChangeHandler) {
        eventHandler(value, event);
      }
    });
  }

  requiresValidation(props = this.props) {
    // FIXME: This can return true if some of the properties happen to share the name of any validations
    const validationPropKeys = Object.keys(validationProps);
    return Object.keys(props).some((key) => validationPropKeys.includes(key) && props[key]);
  }

  wrappedOnChangeEvent(onChange = () => {}) {
    if (this.props.debounceOnChange && !this.debouncedOnChange) {
      this.debouncedOnChange = debounce(onChange, this.props.debounceOnChangeWait);
    }

    return this.wrappedEventForValidationAndBlur(this.debouncedOnChange || onChange);
  }

  wrappedOnBlurEvent(onBlur = () => {}) {
    return this.wrappedEventForValidationAndBlur(onBlur, true);
  }

  wrappedEventForValidationAndBlur(event, isBlurEvent) {
    const { validateOnBlur } = this.props;

    if (validateOnBlur && this.requiresValidation() && isBlurEvent) {
      return this.wrappedEventForValidation(event);
    }

    if (validateOnBlur) {
      return this.wrappedEventWithValue(event);
    }

    if (!validateOnBlur && this.requiresValidation()) {
      return this.wrappedEventForValidation(event);
    }

    return this.wrappedEventWithValue(event);
  }

  setValueFromProps(props = this.props) {
    const newPropsValue = isNil(props.value) ? '' : props.value;
    this.setState({ value: newPropsValue });
  }

  /**
   * setTouched - Set the `touched` state variable which indicates
   * that the input has been interacted with by the user.
   *
   * @return {void}
   */
  setTouched = (touched = true) => {
    this.setState({ touched });
  };

  onFocus = (e) => {
    this.setTouched();
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  };

  resetTouched = () => {
    this.setTouched(false);
  };

  render() {
    const {
      className,
      errorClassName,
      fieldsetClassName,
      fieldsetAttributes,
      labelClassName,
      errorLabelClassName,
      errorMessageContainerClassName,
      errorMessageClassName,
      disableValidations,
      onBlur,
      onChange,
      inputComponent,
      placeholder,
      name,
      labelPlacement,
      label,
      id,
      children,
      isFloatInput,
      infoTip,
      infoTipPlacement,
      showToolTip,
      maxLength,
      autoComplete,
      tooltipPosition,
      autoFocus,
    } = this.props;

    const { errors, value } = this.state;

    const inputId = id || this.uniqueId;

    const inputClassNames = classnames(className, {
      'float-input--input': isFloatInput,
      'is-input-empty': this.state.value === '',
      [errorClassName]: errorClassName && !this.isValid,
      'is-input-error': !this.isValid && isFloatInput,
    });

    const labelClassNames = classnames(labelClassName, {
      [errorLabelClassName]: errorLabelClassName && !this.isValid,
      'float-input--label': isFloatInput,
    });

    const fieldsetClassNames = classnames(fieldsetClassName, {
      'float-input': isFloatInput,
      'fieldset-has-error': !this.isValid,
    });

    const infoTipClasses = classnames('tip', {
      'is-tip-bottom': infoTipPlacement === 'bottom',
      'is-tip-right': infoTipPlacement === 'right',
    });

    const appliedValue = isNil(value) ? '' : value;
    const fullStoryProps = {};
    const tooltipPositionClass = `is-tip-${tooltipPosition}`;
    const showErrorAsTooltip = (error) => error.length > 1 && error[0].length > 100;

    // Do NOT report this value to fullstory if 'secret' is set
    if (this.props.secret) {
      fullStoryProps['data-fullstory'] = 'off';
    }

    let fieldsetChildren = [
      cloneElement(inputComponent, {
        ...fullStoryProps,
        placeholder,
        type: this.props.type || 'text',
        disabled: this.props.disabled,
        name: inputComponent.props.name ? inputComponent.props.name : name,
        id: inputId,
        key: inputId,
        maxLength,
        value: appliedValue,
        onChange: this.wrappedOnChangeEvent(onChange),
        onFocus: this.onFocus,
        onBlur: this.wrappedOnBlurEvent(onBlur),
        className: inputClassNames,
        readOnly: this.props.readOnly,
        min: this.props.type === 'number' ? this.props.min : null,
        max: this.props.type === 'number' ? this.props.max : null,
        autoComplete: autoComplete || 'off',
        autoFocus: autoFocus || false,
      }),
    ];

    if (label) {
      const labelComponent = (
        <label
          className={labelClassNames}
          key={`label-${this.uniqueId}`}
          htmlFor={inputId}
        >
          {label}
        </label>
      );

      if (labelPlacement === 'before') {
        fieldsetChildren = [labelComponent].concat(fieldsetChildren);
      } else {
        fieldsetChildren = fieldsetChildren.concat([labelComponent]);
      }
    }

    if (!disableValidations && errors.length > 0) {
      if (!isFloatInput) {
        fieldsetChildren = fieldsetChildren.concat([
          <ul className={errorMessageContainerClassName}>
            {errors.map((error) => (
              <li
                key={error}
                className={errorMessageClassName}
              >
                {error}
              </li>
            ))}
          </ul>,
        ]);
      } else if (showToolTip) {
        fieldsetChildren = fieldsetChildren.concat([
          <aside
            className={classnames(errorMessageContainerClassName, 'input--error', {
              tip: showErrorAsTooltip(errors),
              'is-tip-error': showErrorAsTooltip(errors),
              tooltipPositionClass,
            })}
          >
            {errors.map((error) => (
              <p
                key={error}
                className={errorMessageClassName}
              >
                {error}
              </p>
            ))}
          </aside>,
        ]);
      }
    }

    if (showToolTip && infoTip) {
      const infoTipComponent = <aside className={infoTipClasses}>{infoTip}</aside>;

      fieldsetChildren = fieldsetChildren.concat([infoTipComponent]);
    }

    if (children) {
      fieldsetChildren = fieldsetChildren.concat(
        children({
          isValid: this.isValid,
          value: this.state.value,
        })
      );
    }

    return (
      <fieldset
        key={`fieldset-${this.uniqueId}`}
        className={fieldsetClassNames}
        {...fieldsetAttributes}
      >
        {ensureChildrenHaveKeys(fieldsetChildren)}
      </fieldset>
    );
  }
}

Input.defaultProps = {
  validateOnBlur: false,
  inputComponent: <input />,
  debounceOnChange: true,
  debounceOnChangeWait: defaultDebounceOnChangeWait,
  errorLabelClassName: '',
  blockEventsWhenInvalid: false,
  disableValidations: false,
  isFloatInput: true,
  infoTipPlacement: 'right',
  showToolTip: true,
  secret: false,
  tooltipPosition: uiPosition.BOTTOM,
  maxLength: 256,
};

Input.propTypes = {
  id: string,
  label: string,
  labelClassName: string,
  fieldsetClassName: string,
  fieldsetAttributes: shape({}),
  invalidClassName: string,
  invalidLabelClassName: string,
  invalidFieldsetClassName: string,
  errorClassName: string,
  errorLabelClassName: string,
  errorMessageContainerClassName: string,
  errorMessageClassName: string,
  labelPlacement: oneOf(['before', 'after']),
  validateOnBlur: bool,
  blockEventsWhenInvalid: bool,
  debounceOnChange: bool,
  debounceOnChangeWait: number,
  maxLength: number,
  onChange: func,
  onBlur: func,
  onError: func,
  onValidationComplete: func,
  children: func,
  disableValidations: bool,
  disabled: bool,
  value: oneOfType([number, string]),
  /** When `true`, prevents the input value from being reported to fullstory. This should be used on all password or other sensitive fields. */
  secret: bool,
  type: string,
  inputComponent: node,
  readOnly: bool,
  tooltipPosition: oneOf(Object.values(uiPosition)),
  /** Validations */
  date: bool,
  ein: bool,
  email: bool,
  futureDate: bool,
  minValue: oneOfType([number, object]),
  maxValue: oneOfType([number, object]),
  minLength: oneOfType([number, object]),
  minRatio: object,
  pattern: object,
  phoneNumber: bool,
  required: bool,
  ssn: bool,
};
