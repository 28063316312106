import React from 'react';
import { connect } from 'react-redux';
import { LendingHomeFatalError } from '@lendinghome/core';
import { Button } from '@lendinghome/core/components';
import { LoanProgram } from '@lendinghome/core/constants';
import { string } from 'prop-types';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import { saveUnsupportedStepInfo, updateLead } from '../../ajax';
import { propertyStateCode, unsupportedErrorInfoCollected, propertyStateUnsupportedForRental } from '../../selectors';
import { stateNameForCode } from '../../util';
import SlideInfo from '../SlideInfo';
import ConsentText from '../ConsentText';
import UnsupportedErrorInputForm from './inputs/UnsupportedErrorInputForm';

function UnsupportedUsStateFullScreen(props) {
  const { flow, propertyStateName, unsupportedErrorInfoCollected: infoCollected, navigateToNext } = props;

  const borrowerHeadline = infoCollected
    ? 'Thanks! We will be in touch once we add more states.'
    : `Sorry, we do not currently lend in ${propertyStateName}.`;
  const brokerHeadline = infoCollected
    ? 'Thanks! We will be in touch once we add more states.'
    : 'Unfortunately, we currently do not offer loans in your state.';
  const borrowerInfo = infoCollected
    ? null
    : 'Please leave your contact information so we can get in touch with you if this changes in the future.';
  const brokerInfo = infoCollected
    ? null
    : 'If your clients invest in properties in multiple states, please go back and try another state. ' +
      'We’re growing fast and expanding to new locations, so if you leave us your contact information, ' +
      'we’ll notify you as soon as we start offering loans in your state(s).';

  const headline = {
    standardBorrower: borrowerHeadline,
    proBorrower: borrowerHeadline,
    broker: brokerHeadline,
    rentalBorrower: infoCollected
      ? "Thank you for your information. We'll be in touch!"
      : `Sorry, we do not currently offer rental loans in ${propertyStateName}.`,
  };

  const info = {
    standardBorrower: borrowerInfo,
    proBorrower: borrowerInfo,
    broker: brokerInfo,
    rentalBorrower: infoCollected
      ? null
      : 'Fill out your information and we will contact you when we expand our program to your state.',
  };

  const showCrossSellBridgeLink =
    flow === 'rentalBorrower' && props.propertyStateUnsupportedForRental && !infoCollected;

  const goToBridgeFlow = () => {
    // update lead with program_tag = 'hard_money' and then
    // navigate to step in bridge flow after `exitsLast24`
    Promise.resolve(updateLead({ program_tag: LoanProgram.HARD_MONEY })).then(
      () => {
        navigateToNext({ flow: 'getRate', step: 'exitsLast24' });
      },
      (error) => {
        throw new LendingHomeFatalError(`Error in Funnel Cakes: ${error}`);
      }
    );
  };

  const goToHomepage = () => {
    window.location = '/';
  };

  return (
    <GetRateFullScreen headline={headline[flow]}>
      {showCrossSellBridgeLink && (
        <SlideInfo className="go-to-bridge__container">
          <div>However, we do offer bridge loans there.</div>
          <Button
            size="large"
            onClick={goToBridgeFlow}
          >
            Get a bridge loan
          </Button>
        </SlideInfo>
      )}
      <SlideInfo>{info[flow]}</SlideInfo>
      {infoCollected && (
        <SlideInfo className="go-to-homepage__container">
          <Button
            size="large"
            onClick={goToHomepage}
          >
            Go Back
          </Button>
        </SlideInfo>
      )}
      <UnsupportedErrorInputForm
        hideBackButton={infoCollected}
        hideNextButton={infoCollected}
        nextButtonText="Keep me posted"
        nextButtonSize={showCrossSellBridgeLink ? 'large' : 'xl'}
        nextButtonClassNames={showCrossSellBridgeLink ? 'is-button-secondary' : null}
        infoCollected={infoCollected}
        {...props}
      />
      <ConsentText marketingSiteUrl={gon.MARKETING_SITE} />
    </GetRateFullScreen>
  );
}

UnsupportedUsStateFullScreen.defaultProps = {
  propertyStateName: 'your state',
};

UnsupportedUsStateFullScreen.propTypes = {
  propertyStateName: string,
};

const mapStateToProps = (state) => ({
  propertyStateName: stateNameForCode(propertyStateCode(state)),
  unsupportedErrorInfoCollected: unsupportedErrorInfoCollected(state),
  propertyStateUnsupportedForRental: propertyStateUnsupportedForRental(state),
});

const UnsupportedUsState = FunnelCake({
  InputForm: connect(mapStateToProps)(UnsupportedUsStateFullScreen),
  mapValueToAjaxParams: (value) => ({
    first_name: value.firstName,
    last_name: value.lastName,
    phone_number: value.phoneNumber,
    email: value.email,
  }),
  saveToServer: saveUnsupportedStepInfo,
  reenableUponComplete: true,
  saveFunnelStep: () => {},
});

export default UnsupportedUsState;
