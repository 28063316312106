import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import { checkIfPropertyStateUnsupported } from '../../ajax';
import PropertyStateInputForm from './inputs/PropertyStateInputForm';

function PropertyStateFullScreen(props) {
  return (
    <GetRateFullScreen headline="Where is your property located?">
      <PropertyStateInputForm {...props} />
    </GetRateFullScreen>
  );
}

const PropertyState = FunnelCake({
  InputForm: PropertyStateFullScreen,
  saveToServer: checkIfPropertyStateUnsupported,
});

export default PropertyState;
