import { camelCase, mapKeys } from 'lodash';
import { recursiveMapKeys } from './recursiveMapKeys';

export default (obj, options = {}) => {
  const { recursive = true, delimiter } = options;
  return recursive
    ? recursiveMapKeys(obj, (value, key) => {
        if (delimiter) {
          return key
            .split(delimiter)
            .map((keyPart) => camelCase(keyPart))
            .join(delimiter);
        }
        return camelCase(key);
      })
    : mapKeys(obj, (value, key) => camelCase(key));
};
