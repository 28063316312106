import { get } from 'lodash';

export default function restoreFormDataFromServer({ setFunnelStepValue, clientToServerMapping, serverFormData }) {
  Object.keys(clientToServerMapping).forEach((step) => {
    let value;
    const mapping = clientToServerMapping[step];
    if (typeof mapping === 'object') {
      value = {};
      Object.keys(mapping).forEach((stepField) => {
        value = {
          ...value,
          [stepField]: get(serverFormData, mapping[stepField]),
        };
      });
    } else {
      value = get(serverFormData, mapping);
    }
    setFunnelStepValue({ step, value });
  });
}
