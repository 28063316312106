import createLogger from 'redux-logger';
import { external } from './crash';

export default function loggerMiddleware() {
  const config = {
    predicate: (getState, action) => external(action),
  };

  if (!__DEVELOPMENT__) {
    const unsupported = () => {
      throw new Error('unsupported');
    };

    config.colors = {};
    config.actionTransformer = external;
    config.stateTransformer = () => null;
    config.logger = {
      group: unsupported,
      groupEnd: unsupported,
      groupCollapsed: unsupported,
      // eslint-disable-next-line no-console
      log: console.log.bind(console),
    };
  }

  return createLogger(config);
}
