import React from 'react';
import Select from '@lendinghome/core/components/Select';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import { memoize } from 'lodash';
import { UsStates } from '../constants';

const statesToOptions = (states, nameKey, valueKey) =>
  states.map((state) => ({
    label: state[nameKey],
    value: state[valueKey],
  }));

const getStates = memoize((filter) => (typeof filter === 'function' ? UsStates.filter(filter) : UsStates));

const UsStateSelect = (props) => {
  const { filter, className, nameKey, valueKey, ...rest } = props;
  const states = getStates(filter);
  return (
    <Select
      className={classnames('us-state-select', className)}
      {...rest}
      options={statesToOptions(states, nameKey, valueKey)}
    />
  );
};

UsStateSelect.defaultProps = {
  filter: null,
  className: '',
  label: '',
  isFloatInput: false,
  nameKey: 'name',
  valueKey: 'usps_code',
  searchable: true,
  labelPlacement: 'after',
};

UsStateSelect.propTypes = {
  /** Callback function to filter the list of states */
  filter: func,
  /** Custom className */
  className: string,
  /** Custom label */
  label: string,
  /** Label placement */
  labelPlacement: string,
  /** Whether to display a floating label */
  isFloatInput: bool,
  /** What format of name to display in dropdown * */
  nameKey: string,
  /** What format of name to save * */
  valueKey: string,
  /** Whether the select allows user typed searches */
  searchable: bool,
};

export default UsStateSelect;
