import React from 'react';
import { LoanProgram } from '@lendinghome/core/constants';
import FunnelCake from '../FunnelCake';
import { updateAndCreateLoan } from '../../ajax';
import GetRateFullScreen from '../GetRateFullScreen';
import SubmitFullScreen from './SubmitFullScreen';

function SubmitRentalFullScreen(props) {
  return (
    <GetRateFullScreen
      className="step--submit"
      slideBackground="no-background"
    >
      <SubmitFullScreen {...props} />
    </GetRateFullScreen>
  );
}

const SubmitRental = FunnelCake({
  InputForm: SubmitRentalFullScreen,
  mapValueToAjaxParams: () => ({
    // some defaults needed ensuring calculator plays nice
    program_tag: LoanProgram.RENTAL,
  }),
  saveToServer: updateAndCreateLoan,
  saveFunnelStep: () => {},
});

export default SubmitRental;
