import React from 'react';
import { MaskedNumberInput } from '@lendinghome/core/components';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

function PercentInput({
  allowDecimal,
  allowNegative,
  transformMultiple,
  ...props
}) {
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '%',
    allowDecimal,
    allowNegative,
    decimalLimit: 2,
  });

  return (
    <MaskedNumberInput
      {...props}
      allowNegative={allowNegative}
      transformMultiple={transformMultiple}
      mask={numberMask}
    />
  );
}

PercentInput.propTypes = {
  /** Indicates if the input should allow decimal numbers or not */
  allowDecimal: PropTypes.bool,
  /** Indicates if the input should allow negative numbers or not */
  allowNegative: PropTypes.bool,
  /**
   * The actual input value will be multiplied by this number while rendering
   */
  transformMultiple: PropTypes.number,
  /**
   * The percentage value to show, expressed with a decimal number.
   * One exception is '-' to be passed down to MaskedNumberInput.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PercentInput.defaultProps = {
  allowDecimal: false,
  allowNegative: false,
  transformMultiple: 100,
  value: null,
};

export default PercentInput;
