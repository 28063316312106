// See react-skylight API docs at http://marcio.github.io/react-skylight/
import React from 'react';
import { string, bool } from 'prop-types';
import SkyLight from 'react-skylight';

/**
 * ```
 * import { Modal } from '@lendinghome/core/components';
 *
 * <Modal
 *   ref={c => (this.modal = c)}
 *   title={title}
 *   onRequestClose={() => this.modal.hide()}
 *  >
 *    <p>my modal content</p>
 *  </Modal>
 *  ...
 *  this.modal.show();
 *  ```
 */
export default class Modal extends React.Component {
  state = {
    visible: false,
  };

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
    document.removeEventListener('keydown', this.handleEscapeKey);
  }

  handleEscapeKey = (evt) => {
    const {
      props: { closeOnEsc },
      state: { visible },
    } = this;

    if (closeOnEsc && visible) {
      let isEscape = false;
      if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape && this.props.closeOnEsc) {
        this.hide();
      }
    }
  };

  show() {
    this.setState({ visible: true }, () => {
      this.modalRef.show();
      document.addEventListener('keydown', this.handleEscapeKey);
    });
  }

  hide() {
    this.setState({ visible: false }, () => {
      this.modalRef.hide();
      document.removeEventListener('keydown', this.handleEscapeKey);
    });
  }

  beforeModalOpen() {
    document.body.classList.add('modal-open');
  }

  beforeModalClose = () => {
    document.body.classList.remove('modal-open');
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  ref = (c) => {
    this.modalRef = c;
  };

  render() {
    const defaultModalStyles = {
      width: this.props.width,
      height: this.props.height,
      padding: this.props.padding,
      borderRadius: this.props.borderRadius,
      marginTop: this.props.marginTop,
      marginLeft: this.props.marginLeft,
      top: this.props.top,
      left: this.props.left,
    };

    const defaultCloseButtonStyle = {
      top: '2%',
    };

    const defaultOverlayStyles = {
      backgroundColor: 'rgba(0,0,0,0.8)',
    };

    return (
      <SkyLight
        dialogStyles={defaultModalStyles}
        closeButtonStyle={defaultCloseButtonStyle}
        overlayStyles={defaultOverlayStyles}
        beforeOpen={this.beforeModalOpen}
        beforeClose={this.beforeModalClose}
        ref={this.ref}
        {...this.props}
      >
        {this.props.children}
      </SkyLight>
    );
  }
}

// Skylight will apply default inline styles unless the style keys are set to null.
// These are the inline styles it applies (taken from `react-skylight/lib/styles.js`):
//
// var styles = {
//   overlayStyles: {
//     position: 'fixed',
//     top: '0px',
//     left: '0px',
//     width: '100%',
//     height: '100%',
//     zIndex: '99',
//     backgroundColor: 'rgba(0,0,0,0.3)'
//   },
//   dialogStyles: {
//     width: '50%',
//     height: '400px',
//     position: 'fixed',
//     top: '50%',
//     left: '50%',
//     marginTop: '-200px',
//     marginLeft: '-25%',
//     backgroundColor: '#fff',
//     borderRadius: '2px',
//     zIndex: '100',
//     padding: '15px',
//     boxShadow: '0px 0px 4px rgba(0,0,0,.14),0px 4px 8px rgba(0,0,0,.28)'
//   },
//   title: {
//     marginTop: '0px'
//   },
//   closeButtonStyle: {
//     cursor: 'pointer',
//     position: 'absolute',
//     fontSize: '1.8em',
//     right: '10px',
//     top: '0px'
//   }
// };

Modal.noInlineDialogStyles = {
  width: null,
  height: null,
  position: null,
  top: null,
  left: null,
  marginTop: null,
  marginLeft: null,
  backgroundColor: null,
  borderRadius: null,
  zIndex: null,
  padding: null,
  boxShadow: null,
};

Modal.noInlineOverlayStyles = {
  position: null,
  top: null,
  left: null,
  width: null,
  height: null,
  zIndex: null,
  backgroundColor: null,
};

Modal.noInineTitleStyles = {
  marginTop: null,
};

Modal.noInlineCloseButtonStyles = {
  cursor: null,
  position: null,
  fontSize: null,
  right: null,
  top: null,
};

Modal.defaultProps = {
  width: '45%',
  height: '60%',
  top: '50%',
  left: '50%',
  padding: '.75rem 0 0rem 0',
  borderRadius: '4px',
  marginTop: '0',
  marginLeft: '0',
  closeOnEsc: false,
  hideOnOverlayClicked: true,
};

Modal.propTypes = {
  /** Css width of the modal */
  width: string,
  /** Css height of the modal */
  height: string,
  /** Css top value of the modal (for positioning) */
  top: string,
  /** Css left value of the modal (for positioning) */
  left: string,
  /** Internal padding of the modal */
  padding: string,
  /** Radius of the external modal border */
  borderRadius: string,
  /** Top margin which separates the modal title from the border */
  marginTop: string,
  /** Left margin which separates the modal content from the border */
  marginLeft: string,
  /** Whether to close the modal when the Escape key is pressed */
  closeOnEsc: bool,
  /** Whether to close the modal when the surrounding background is clicked */
  hideOnOverlayClicked: bool,
};
