import React from 'react';
import FunnelCake from '../FunnelCake';
import { createDirectUser, createBrokerUser } from '../../ajax';
import { LendingHomeValidationError } from '../../errorHandling';
import GetRateFullScreen from '../GetRateFullScreen';
import SlideInfo from '../SlideInfo';
import SetPasswordInputForm from './inputs/SetPasswordInputForm';

function handleSaveToServerError(error) {
  if (error.errors && error.errors.password) {
    throw new LendingHomeValidationError(error.errors.password);
  }
}

function SetPasswordFullScreen(props) {
  const { flow } = props;

  const headline = {
    proBorrower: 'Set a secure password.',
    standardBorrower: 'Enter a password for your account.',
    rentalBorrower: 'Set a secure password.',
    broker: 'Enter a password for your account.',
  };

  const borrowerInfo = (
    <div className="set-password__instructions">
      <p className="set-password__instructions-heading">
        Keep your information secure and save your progress. Password must:
      </p>
      <ul className="is-list-disc">
        <li>Be at least 8 characters</li>
        <li>Be at most 128 characters</li>
        <li>Contain 1 number</li>
        <li>Contain 1 upper case letter</li>
        <li>Contain 1 lower case letter</li>
      </ul>
    </div>
  );

  return (
    <GetRateFullScreen headline={headline[flow]}>
      <SlideInfo>{borrowerInfo}</SlideInfo>
      <SetPasswordInputForm {...props} />
    </GetRateFullScreen>
  );
}

export const SetPassword = FunnelCake({
  InputForm: SetPasswordFullScreen,
  saveToServer: createDirectUser,
  handleSaveToServerError,
  mapValueToAjaxParams: (value) => ({ password: value }),
});

export const SetBrokerPassword = FunnelCake({
  InputForm: SetPasswordFullScreen,
  saveToServer: createBrokerUser,
  handleSaveToServerError,
  mapValueToAjaxParams: (value) => ({ password: value }),
});
