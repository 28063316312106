import React from 'react';
import { number, bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import VerifyingPhoneInput from '../../VerifyingPhoneInput';
import FunnelStepForm from '../../hoc/FunnelStepForm';

const validator = VerifyingPhoneInput.validator;

function YourPhoneInput({ onChange, value, disabled }) {
  return (
    <Fieldset>
      <VerifyingPhoneInput
        label="Phone Number"
        htmlFor="phoneNumber"
        id="phoneNumber"
        isFloatInput
        onChange={onChange}
        value={value}
        disabled={disabled}
        autoFocus
      />
    </Fieldset>
  );
}

YourPhoneInput.propTypes = {
  onChange: func.isRequired,
  value: number,
  disabled: bool,
};

YourPhoneInput.defaultProps = {
  value: undefined,
  disabled: false,
};

export default FunnelStepForm({
  Input: YourPhoneInput,
  submitOnChange: false,
  isSubmittable: (value) => value && validator(value),
});
