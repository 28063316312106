import React from 'react';

const ConsentText = ({ marketingSiteUrl }) => (
  /* eslint-disable react/no-unescaped-entities */
  <div className="slide-info--legal slide-info">
      By submitting your info, you agree to our{' '}
    <a
      href={`${marketingSiteUrl}/legal/terms`}
      rel="noopener noreferrer"
      target="_blank"
    >
        terms and conditions
    </a>
      ,{' '}
    <a
      href={`${marketingSiteUrl}/legal/privacy`}
      rel="noopener noreferrer"
      target="_blank"
    >
        privacy policy
    </a>
      , and{' '}
    <a
      href={`${marketingSiteUrl}/legal/terms#cellular-contact-policy`}
      rel="noopener noreferrer"
      target="_blank"
    >
        cellular phone contact policy
    </a>
      . You consent to receive phone calls and SMS messages from Kiavi and its affiliates. We may contact you to provide
      updates on your request and/or for marketing purposes. Message frequency may depend on your activity. You may
      opt-out of texting by replying “STOP". Reply "Help" for more Information, Message and data rates may apply.
  </div>
);

export default ConsentText;
