import postal from 'postal/lib/postal.lodash';
import shouldSubscribeToEvent from './shouldSubscribeToEvent';

function ensurePluginSubscribedToEvent(eventBus, plugin, event) {
  const subscribers = postal.getSubscribersFor({ topic: event });

  const existingSubscriber = subscribers.find(
    (subscriber) =>
      subscriber.callback.__slug__ &&
      subscriber.callback.__slug__ === plugin.trigger
  );

  if (!existingSubscriber) {
    // Wrapping trigger in a test-friendly way to make sure we preserve `this`
    // in the caller
    //
    const triggerPlugin = (data, envelope) =>
      plugin.trigger.apply(plugin, [event, data]);
    // Saving original function for when we perform equality check above
    // in subsequent `eventBus.trigger` calls
    triggerPlugin.__slug__ = plugin.trigger;

    if (shouldSubscribeToEvent(event, plugin)) {
      eventBus.subscribe(event, triggerPlugin);
    }
  }
}

export default ensurePluginSubscribedToEvent;
