import React from 'react';
import { LendingHomeFatalError } from '@lendinghome/core';

export default class FourOhFour extends React.Component {
  componentDidMount() {
    throw new LendingHomeFatalError(`Error in Funnel Cakes: 404 ${this.props.location.pathname} is not found`);
  }

  render() {
    return null;
  }
}
