import React from 'react';
import { UsStateSelect } from '@lendinghome/core/components';
import { string, bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function BrokerStateInput({ onChange, value, disabled }) {
  return (
    <UsStateSelect
      name="brokerLendableStates"
      disabled={disabled}
      placeholder="Select states"
      value={value}
      onChange={onChange}
      multi
      simpleValue
      delimiter=";"
    />
  );
}

BrokerStateInput.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};

BrokerStateInput.defaultProps = {
  value: '',
  disabled: false,
};

export default FunnelStepForm({
  Input: BrokerStateInput,
  submitOnChange: false,
});
