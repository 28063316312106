import isEmpty from 'lodash/isEmpty';

export default class MaxValue {
  constructor({
    maxValue,
    compareToValue,
    inclusive = false,
    message = `Can't be greater than ${maxValue}`,
    priority = null,
  }) {
    this.priority = priority;
    this.maxValue = maxValue;
    this.inclusiveOffset = inclusive ? 1 : 0;
    this.compareToValue = compareToValue;
    this.message = message;
  }

  checkValue(value) {
    try {
      if (typeof value === 'string' && isEmpty(value)) return true;
      if (isNaN(value)) return true;
      return (
        value <=
        (this.compareToValue ? this.compareToValue : this.maxValue) -
          this.inclusiveOffset
      );
    } catch (e) {
      return false;
    }
  }

  isValid(value) {
    return this.checkValue(Number(value));
  }
}
