import * as steps from '../../components/steps';

const getRateRoutes = [
  {
    flow: 'getRate',
    step: 'loanProgram',
    component: steps.LoanProgram,
    stepUrl: 'index',
  },
  { flow: 'getRate', step: 'loanProgram', component: steps.LoanProgram },
  { flow: 'getRate', step: 'exitsLast24', component: steps.ExitsLast24 },
  { flow: 'getRate', step: 'infillExitsLast60', component: steps.InfillExitsLast60 },
  { flow: 'getRate', step: 'unsupportedBorrowerInfillExits', component: steps.UnsupportedBorrowerInfillExits },
];

export default getRateRoutes;
