import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@lendinghome/core/components';
import { string } from 'prop-types';
import FunnelCake from '../FunnelCake';
import PropertyFlowFullScreen from '../PropertyFlowFullScreen';
import { saveUnsupportedStepInfo } from '../../ajax';
import { propertyStateCode, unsupportedErrorInfoCollected } from '../../selectors';
import { stateNameForCode } from '../../util';
import SlideInfo from '../SlideInfo';
import UnsupportedErrorInputForm from './inputs/UnsupportedErrorInputForm';

function UnsupportedIndividualUsStateFullScreen(props) {
  const { propertyStateName, unsupportedErrorInfoCollected: infoCollected } = props;

  const headline = infoCollected
    ? "Thank you for your information. We'll be in touch!"
    : `Unfortunately, we only lend to entities in ${propertyStateName}`;

  const info = infoCollected
    ? null
    : 'Please leave your contact information so we can get in touch with you if this changes in the future';

  const goToHomepage = () => {
    window.location = '/';
  };

  return (
    <PropertyFlowFullScreen headline={headline}>
      <SlideInfo>{info}</SlideInfo>
      {infoCollected && (
        <SlideInfo className="go-to-homepage__container">
          <Button
            size="large"
            onClick={goToHomepage}
          >
            Go Back
          </Button>
        </SlideInfo>
      )}

      <UnsupportedErrorInputForm
        hideBackButton={infoCollected}
        hideNextButton={infoCollected}
        nextButtonText="Next"
        nextButtonSize="xl"
        infoCollected={infoCollected}
        {...props}
      />
    </PropertyFlowFullScreen>
  );
}

UnsupportedIndividualUsStateFullScreen.defaultProps = {
  propertyStateName: 'this state',
};

UnsupportedIndividualUsStateFullScreen.propTypes = {
  propertyStateName: string,
};

const mapStateToProps = (state) => ({
  propertyStateName: stateNameForCode(propertyStateCode(state)),
  unsupportedErrorInfoCollected: unsupportedErrorInfoCollected(state),
});

const UnsupportedIndividualUsState = FunnelCake({
  InputForm: connect(mapStateToProps)(UnsupportedIndividualUsStateFullScreen),
  ajaxParams: (value) => ({
    first_name: value.firstName,
    last_name: value.lastName,
    phone_number: value.phoneNumber,
    email: value.email,
  }),
  saveToServer: saveUnsupportedStepInfo,
  reenableUponComplete: true,
  saveFunnelStep: () => {},
});

export default UnsupportedIndividualUsState;
