import { FunnelFlowVersions } from './constants';
import getRate from './flows/getRate';
import proBorrower from './flows/proBorrower';
import standardBorrower from './flows/standardBorrower';
import broker from './flows/broker';
import rentalBorrower from './flows/rentalBorrower';
import bridgePropertyPreCalc from './flows/bridgePropertyPreCalc';

export function makeFunnelStepOrdering(flowSequences, options = {}) {
  const flows = {};
  const withLoan = options.withLoan || [];

  Object.keys(flowSequences).forEach((flow) => {
    flows[flow] = {};
    const flowSequence = flowSequences[flow];
    flowSequence.forEach((stepConfig, index) => {
      flows[flow][stepConfig.step] = { ...stepConfig, index };
    });
  });

  function isStepPermitted({ flow, step, state }) {
    const stepConfig = flows[flow][step];
    if (!stepConfig) {
      return false;
    }
    const guard = stepConfig.guard;
    if (guard) {
      return guard(state);
    }
    return true;
  }

  function flowStepRoutingObject({ flow, step, href, state }) {
    if (href) {
      return { href };
    }
    if (withLoan.includes(flow)) {
      return { flow, step, loanId: state.loan.getId() };
    }
    return { flow, step };
  }

  function getStep({ flow, step }) {
    if (flows[flow]) {
      return flows[flow][step];
    }
    return {};
  }

  function getNextStep({ flow, step, state }) {
    const stepConfig = flows[flow][step];

    if (!stepConfig) {
      return undefined;
    }
    if (stepConfig.terminal) {
      return {};
    }
    if (explicitNextStep()) {
      return routedExplicitNextStep();
    }
    if (stepIsNotLastInFlow()) {
      if (nextStepInSequenceIsPermitted()) {
        return routedNextStepInSequence();
      }
      return routedFirstPermittedStepAfterNextInSequence();
    }

    function explicitNextStep() {
      return stepConfig.nextStep && stepConfig.nextStep(state);
    }

    function routedExplicitNextStep() {
      return flowStepRoutingObject({ ...explicitNextStep(), state });
    }

    function stepIsNotLastInFlow() {
      return stepConfig.index < flowSequences[flow].length - 1;
    }

    function nextStepInSequence() {
      return flowSequences[flow][stepConfig.index + 1].step;
    }

    function nextFlowStepStateInSequence() {
      return { flow, step: nextStepInSequence(), state };
    }

    function nextStepInSequenceIsPermitted() {
      return isStepPermitted(nextFlowStepStateInSequence());
    }

    function routedNextStepInSequence() {
      return flowStepRoutingObject(nextFlowStepStateInSequence());
    }

    function routedFirstPermittedStepAfterNextInSequence() {
      return getNextStep(nextFlowStepStateInSequence());
    }
  }

  function getPreviousStep({ flow, step, state }) {
    const stepConfig = flows[flow][step];

    if (!stepConfig) {
      return undefined;
    }
    if (explicitPreviousStep()) {
      return routedExplicitPreviousStep();
    }
    if (stepIsNotFirstInFlow()) {
      if (previousStepInSequenceIsPermitted()) {
        return routedPreviousStepInSequence();
      }
      return routedFirstPermittedStepBeforePreviousInSequence();
    }

    function explicitPreviousStep() {
      return stepConfig.previous && stepConfig.previous(state);
    }

    function routedExplicitPreviousStep() {
      return flowStepRoutingObject({ ...explicitPreviousStep(), state });
    }

    function stepIsNotFirstInFlow() {
      return stepConfig.index > 0;
    }

    function previousStepInSequence() {
      return flowSequences[flow][stepConfig.index - 1].step;
    }

    function previousFlowStepStateInSequence() {
      return { flow, step: previousStepInSequence(), state };
    }

    function previousStepInSequenceIsPermitted() {
      return isStepPermitted(previousFlowStepStateInSequence());
    }

    function routedPreviousStepInSequence() {
      return flowStepRoutingObject(previousFlowStepStateInSequence());
    }

    function routedFirstPermittedStepBeforePreviousInSequence() {
      return getPreviousStep(previousFlowStepStateInSequence());
    }
  }

  function isStepFurtherThanOtherStep(flowStep, otherStep) {
    // This is a simplifying assumption.  We think it's okay to only remember the furthest step of the most
    // recently visited flow and consider that _the_ furthest step.  This does run the risk that if a user
    // backs out of a flow that comes after another flow, progress in that later flow will be lost (only
    // the furthest step will be lost, not their answers to any of the questions).  We think that will be rare
    // and justifiable in most cases.
    if (!otherStep || flowStep.flow !== otherStep.flow) {
      return true;
    }
    const flow = flowStep.flow;
    if (!flows[flow][otherStep.step]) {
      return true;
    }
    return flows[flow][flowStep.step].index > flows[flow][otherStep.step].index;
  }

  function getFurthestStep({ furthestFlowStep, currentFlowStep, state }) {
    const flowSteps = flowSequences[furthestFlowStep.flow];

    if (furthestFlowStep.flow === currentFlowStep.flow) {
      let furthestIndex = flowSteps.findIndex(({ step }) => step === furthestFlowStep.step);
      let currentIndex = flowSteps.findIndex(({ step }) => step === currentFlowStep.step);
      if (currentIndex >= 0 && furthestIndex >= currentIndex) {
        return { ...currentFlowStep };
      }
      return { ...furthestFlowStep };
    }
    const firstStep = flowSteps[0];
    if (firstStep.previous) {
      return firstStep.previous(state);
    }
    return { flow: furthestFlowStep.flow, step: firstStep.step };
  }

  function getFurthestLegalStep({ furthestFlowStep, currentFlowStep, state }) {
    if (!furthestFlowStep) {
      return null;
    }

    const result = getFurthestStep({
      furthestFlowStep,
      currentFlowStep,
      state,
    });
    if (isStepPermitted({ flow: result.flow, step: result.step, state })) {
      return result;
    }
    return getPreviousStep({ flow: result.flow, step: result.step, state });
  }

  // Since it is possible for steps to be deleted between visits to the funnel, "safe" means it still exists
  // or just the first step of the flow if the step in question has been deleted.
  function getSafeStepForFlow({ flow, step }) {
    const stepsForFlow = flows[flow];
    if (stepsForFlow) {
      if (stepsForFlow[step]) {
        return { flow, step };
      }
      return { flow, step: flowSequences[flow][0].step };
    }
  }

  return {
    getNextStep,
    getPreviousStep,
    isStepFurtherThanOtherStep,
    getFurthestLegalStep,
    getSafeStepForFlow,
    getStep,
  };
}

const defaultFlows = {
  getRate,
  proBorrower,
  standardBorrower,
  rentalBorrower,
  broker,
};

// No rental flow because it has been migrated to loan-application-flow
const defaultStepOrdering = makeFunnelStepOrdering(defaultFlows);

const bridgePropertyWithEarlierAddress = {
  ...defaultFlows,
  bridgePropertyPreCalc,
};

// No rental flow because it has been migrated to loan-application-flow
const bridgeWithEarlierAddressStepOrdering = makeFunnelStepOrdering(bridgePropertyWithEarlierAddress, {
  withLoan: ['bridgePropertyPreCalc'],
});

const funnelStepOrdering = (funnelExperimentVersion = FunnelFlowVersions.DEFAULT) => {
  switch (funnelExperimentVersion) {
    case FunnelFlowVersions.BRIDGE_WITH_EARLIER_PROPERTY_ADDRESS:
      return bridgeWithEarlierAddressStepOrdering;
    case FunnelFlowVersions.DEFAULT:
    default:
      return defaultStepOrdering;
  }
};

export default funnelStepOrdering;
