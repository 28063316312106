import Pattern from './Pattern';

/*
Validation rules from: https://lendinghome.atlassian.net/browse/TECH-15098

The Social Security number is a nine-digit number in the format "AAA-GG-SSSS".
The number is divided into three parts.

- The middle two digits are the Group Number. The Group Numbers range from 01 to 99.
- The last four digits are Serial Numbers. They represent a straight numerical
  sequence of digits from 0001 to 9999 within the group.
- Some special numbers are never allocated:
  - Numbers with all zeros in any digit group (000-##-####, ###-00-####, ###-##-0000).
  - Numbers with 666 or 900-999 (Individual Taxpayer Identification Number) in
    the first digit group.
  - SSNs used in advertising have rendered those numbers invalid.

Regex from: https://github.com/LendingHome/lendinghome-monolith/blob/master/consumer/app/assets/javascripts/shared/core/forms-validations.js.erb#L124
*/

export default class SSN extends Pattern {
  constructor({ message = 'Invalid SSN. Please use a valid 9 digit SSN.' }) {
    super({
      pattern: /^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/,
      message,
    });
  }

  isValid(value) {
    const retVal = super.isValid(value);
    if (!retVal) {
      Frogger && Frogger.debug('Invalid SSN specified');
    }
    return retVal;
  }
}
