import { json, fetchApi, HttpMethod, setCsrfToken } from '@lendinghome/core';
import get from 'lodash/get';
import auth0 from '../shared/utils/auth0Split';
import funnelStepOrdering from './funnelStepOrdering';
import { flowStepToString } from './navigation';
import * as primitive from './ajax/primitive';

export function updateLead(params) {
  return primitive.updateLead(params);
}

export function updatePropertyLoan({ loanId, loan }) {
  return primitive.updatePropertyLoan(loanId, loan);
}

export function checkIfEmailExists(params, setFunnelMetaData) {
  return primitive.updateLead(params).then((response) => {
    setFunnelMetaData({
      key: 'userEmailExists',
      value: response.nextStep !== 'create_password',
    });
    setFunnelMetaData({
      key: 'userHasLoans',
      value: response.nextStep === 'dashboard',
    });
  });
}

export function createDirectUser(params, setFunnelMetaData) {
  return primitive.updateLead(params).then((response) => {
    setFunnelMetaData({
      key: 'analyticsIdentity',
      value: { lh_user_id: response.userId },
    });
  });
}

export function createBrokerUser(params, setFunnelMetaData) {
  return json('/m/broker-flow/lead', {
    body: params,
    method: HttpMethod.Patch,
  }).then((response) => {
    setFunnelMetaData({
      key: 'analyticsIdentity',
      value: { lh_user_id: response.userId },
    });
  });
}

export function checkIfBrokerStateUnsupported(params, setFunnelMetaData) {
  return json('/m/broker-flow/lead', {
    body: { ...params },
    method: HttpMethod.Patch,
  }).then((response) => {
    setFunnelMetaData({
      key: 'brokerStateUnsupported',
      value: response.unsupportedBrokerState,
    });
  });
}

export function userSignIn(params) {
  return fetchApi('/users/sign_in', {
    body: JSON.stringify({ user: params }),
    method: HttpMethod.Post,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
  }).then(
    (response) => {
      setCsrfToken(response.response.headers.get('x-new-csrf-token'));
      return true;
    },
    (reason) => {
      const status = get(reason, ['response', 'status']);
      if (status === 401) {
        return false;
      }
      throw reason.response;
    }
  );
}

export function resetPassword(email, splits) {
  return auth0.auth0Enabled(splits)
    ? json('/auth0/unauthenticated_send_password_reset_instructions', {
      body: { email },
      method: HttpMethod.Post,
    })
    : json('/passwords/send-reset-instructions', {
      body: { email },
      method: HttpMethod.Post,
    });
}

export function createLoan(params, setFunnelMetaData) {
  return json('/loans/m_flow_submit', {
    body: params,
    method: HttpMethod.Post,
  }).then((response) => {
    const { loanId } = response;
    if (loanId) {
      setFunnelMetaData({
        key: 'loanId',
        value: loanId,
      });
    }
  });
}

export function updateAndCreateLoan(params, setFunnelMetaData) {
  return json('/get_rate_flow/lead', {
    body: params,
    method: HttpMethod.Patch,
  }).then(() =>
    // TODO: what happens when response is not 200?
    createLoan({ product_tag: params.product_tag }, setFunnelMetaData)
  );
}

export function saveUnsupportedStepInfo(params, setFunnelMetaData) {
  return json('/get_rate_flow/lead', {
    body: params,
    method: HttpMethod.Patch,
  }).then(() => {
    setFunnelMetaData({
      key: 'unsupportedErrorInfoCollected',
      value: true,
    });
  });
}

export function savePropertyAddress({ loanId, ...params }) {
  const loan = {
    property: {
      address: {
        input_line1: params.line1,
        input_line2: params.line2,
        input_city: params.city,
        input_state: params.state,
        input_zip: params.zip,
      },
    },
  };

  return primitive.updatePropertyLoan(loanId, loan);
}

export function checkIfPropertyStateUnsupported(params, setFunnelMetaData) {
  return primitive.updateLead({ funnel_cakes_feature_flag: true, ...params }).then((response) => {
    setFunnelMetaData({
      key: 'propertyStateUnsupported',
      value: response.unsupportedUsState,
    });
    setFunnelMetaData({
      key: 'propertyStateUnsupportedForRental',
      value: response.unsupportedStateForRental,
    });
    setFunnelMetaData({
      key: 'entityRequiredInPropertyState',
      value: response.stateRequiresEntity,
    });
  });
}

export async function savePropertyAddressThenCheckStateSupported(params, setFunnelMetaData) {
  const addressValidationResponse = await savePropertyAddress(params, setFunnelMetaData);
  await primitive.completeInitialApplication(params.loanId);
  setFunnelMetaData({
    key: 'entityRequiredInPropertyState',
    value: addressValidationResponse.stateRequiresEntity,
  });
  const response = await primitive.propertyStateSupportedForLoanProgram(params.loanId, params.state);
  const supported = response.isStateSupported;
  setFunnelMetaData({
    key: 'propertyStateUnsupported',
    value: !supported,
  });
  return supported;
}

export function swapApplicants({ loanId }) {
  return json(`/pflow/${loanId}/swap_applicants`, {
    method: HttpMethod.Patch,
  });
}

export async function checkEligibility({ loanId }, setFunnelMetaData) {
  const response = await primitive.checkEligibility(loanId);
  const failingRules = response.topOfFunnel;
  setFunnelMetaData({
    key: 'failingEligibilityRules',
    value: failingRules,
  });
  return !failingRules || failingRules.length === 0;
}

function savePossiblyFurthestStep({ flow, step, furthestStepVisited, flowName }, setFunnelMetaData, saveFunction) {
  const flowStep = { flow, step };
  const flowStepString = flowStepToString(flowStep);

  if (funnelStepOrdering(flowName).isStepFurtherThanOtherStep(flowStep, furthestStepVisited)) {
    setFunnelMetaData({
      key: 'furthestStepVisited',
      value: flowStep,
    });
    return saveFunction({
      last_step_visited: flowStepString,
      furthest_step_visited: flowStepString,
    });
  }
  return saveFunction({
    last_step_visited: flowStepString,
  });
}

export function saveFunnelStep(params, setFunnelMetaData) {
  return savePossiblyFurthestStep(params, setFunnelMetaData, primitive.updateLead);
}

export function saveFunnelStepForLoan(params, setFunnelMetaData) {
  const { funnelVisitId } = params;
  function updateFunnelVisitForFunnelVisitId(funnelVisitParams) {
    return primitive.updateFunnelVisit(funnelVisitId, funnelVisitParams);
  }

  return savePossiblyFurthestStep(params, setFunnelMetaData, updateFunnelVisitForFunnelVisitId);
}
