// Shared UI constants

const Constants = {
  breakpoints: {
    mobile_wide: 750,
    mobile_max: 768,
  },
  keycodes: {
    esc: 27,
  },
};

module.exports = Constants;
