import React from 'react';
import { string, bool } from 'prop-types';

const LoadingSpinner = ({ asDots, classNamePrefix }) => {
  let className = 'loading-spinner';
  if (classNamePrefix) {
    className = `${classNamePrefix}--${className}`;
  }

  return (
    <div className={className}>
      {asDots ? (
        <div className="spinner-dots">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      ) : (
        <i className="spinner" />
      )}
    </div>
  );
};

LoadingSpinner.defaultProps = {
  asDots: false,
  classNamePrefix: '',
};

LoadingSpinner.propTypes = {
  /** show the 3-dots loading animation */
  asDots: bool,
  /** an app-specific prefix to control custom styling by the app using the LoadingSpinner */
  classNamePrefix: string,
};

export default LoadingSpinner;
