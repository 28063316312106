import { combineReducers } from 'redux';
import {
  ADD_FILE,
  REMOVE_FILE,
  UPDATE_FILE_ID,
  UPDATE_FILE_STATUS,
  UPDATE_FILE_PROGRESS,
} from '../actions/fileList';

export const filesReducer = (state = [], action) => {
  let newState = state;

  const file = action.id && state.find((f) => f.id === action.id);
  if (file) {
    switch (action.type) {
      case UPDATE_FILE_ID:
        file.id = action.newId;
        break;

      case UPDATE_FILE_PROGRESS:
        file.progress = action.progress;
        break;

      case UPDATE_FILE_STATUS:
        file.status = action.status || file.status;
        file.statusLabel = action.statusLabel || file.statusLabel;
        break;

      case REMOVE_FILE:
        state.splice(state.indexOf(file), 1);
        break;

      default:
        break;
    }

    newState = Array.from(state);
  } else if (action.type === ADD_FILE) {
    newState = [
      ...state,
      {
        id: action.id,
        name: action.name,
        status: action.status,
        statusLabel: action.statusLabel,
        progress: null,
        // New properties can be added here
      },
    ];
  }

  return newState;
};

export const getFiles = (state) => state.files;

export default combineReducers({
  files: filesReducer,
});
