import { LendingHomeFatalError } from '@lendinghome/core';

export function genericErrorHandler(error) {
  throw new LendingHomeFatalError(`Error in Funnel Cakes: ${error}`);
}

export class LendingHomeValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LendingHomeValidationError';
    // set prototype to make instanceof work properly
    // https://stackoverflow.com/questions/47429157/instanceof-not-working-properly
    Object.setPrototypeOf(this, LendingHomeValidationError.prototype);
  }
}
