import React from 'react';
import { UsStateSelect } from '@lendinghome/core/components';
import { object, bool, func } from 'prop-types';
import { get as dig, camelCase } from 'lodash';
import Fieldset from './Fieldset';
import VerifyingGeosuggest from './VerifyingGeosuggest';
import VerifyingTextField from './VerifyingTextField';
import VerifyingZipInput from './VerifyingZipInput';

function VerifyingMultiLineAddress({ onChange, value, disabled, onSuggestSelect, ...extraProps }) {
  function transformAddressFromGmapsArray(array) {
    const addressComponentsMap = array.reduce(
      (acc, component) => ({
        ...acc,
        [camelCase(component.types[0])]: {
          longName: component.long_name,
          shortName: component.short_name,
        },
      }),
      {}
    );
    const {
      streetNumber,
      route,
      locality,
      sublocalityLevel1,
      neighborhood,
      administrativeAreaLevel1,
      administrativeAreaLevel3,
      postalCode,
    } = addressComponentsMap;
    const city = locality || administrativeAreaLevel3 || sublocalityLevel1 || neighborhood;
    return {
      line1: `${dig(streetNumber, 'longName', '')} ${dig(route, 'shortName', '')}`,
      line2: '',
      city: dig(city, 'longName', ''),
      state: dig(administrativeAreaLevel1, 'shortName', ''),
      zip: dig(postalCode, 'longName', ''),
    };
  }

  function handleSelect(selection) {
    if (selection && selection.gmaps) {
      const newValue = transformAddressFromGmapsArray(selection.gmaps.address_components);

      if (onSuggestSelect) {
        onSuggestSelect(newValue);
      }
      onChange(newValue);
    }
  }

  function setLine1(line1) {
    onChange({ ...value, line1 });
  }

  function setLine2({ target }) {
    onChange({ ...value, line2: target.value });
  }

  function setCity({ target }) {
    onChange({ ...value, city: target.value });
  }

  function setState(usState) {
    onChange({ ...value, state: usState });
  }

  function setZip(zip) {
    onChange({ ...value, zip });
  }

  return (
    <fieldset className="multi-line-address">
      <VerifyingGeosuggest
        htmlFor="line1"
        placeholder="Address Line 1"
        onSuggestSelect={handleSelect}
        label="Address Line 1"
        autoFocus={false}
        onChange={setLine1}
        value={value.line1}
        disabled={disabled}
        {...extraProps}
      />
      <VerifyingTextField
        htmlFor="line2"
        label="Address Line 2"
        onChange={setLine2}
        value={value.line2}
        disabled={disabled}
        required={false}
      />
      <VerifyingTextField
        htmlFor="city"
        label="City"
        onChange={setCity}
        value={value.city}
        disabled={disabled}
      />
      <Fieldset fieldsetLayout="two-column">
        <fieldset className="float-input">
          <UsStateSelect
            name="state"
            id="state"
            disabled={disabled}
            placeholder="State"
            value={value.state}
            onChange={setState}
            searchable
            // exclude US territories
            filter={(state) => state.status === 0}
          />
        </fieldset>
        <VerifyingZipInput
          htmlFor="zip"
          id="zip"
          label="Zip"
          onChange={setZip}
          value={value.zip}
          disabled={disabled}
        />
      </Fieldset>
    </fieldset>
  );
}

VerifyingMultiLineAddress.propTypes = {
  onChange: func.isRequired,
  onSuggestSelect: func,
  value: object,
  disabled: bool,
};

VerifyingMultiLineAddress.defaultProps = {
  value: { line1: '', line2: '', city: '', state: '', zip: '' },
  disabled: false,
};

VerifyingMultiLineAddress.validator = (value) =>
  value && value.line1 && value.city && value.state && value.zip && VerifyingZipInput.validator(value.zip);

export default VerifyingMultiLineAddress;
