import React from 'react';
import { string, bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import VerifyingEmailInput from '../../VerifyingEmailInput';
import FunnelStepForm from '../../hoc/FunnelStepForm';

const validator = VerifyingEmailInput.validator;

function YourEmailInput({ onChange, value, disabled }) {
  function setEmail({ target }) {
    onChange(target.value);
  }

  return (
    <Fieldset>
      <VerifyingEmailInput
        label="Email Address"
        htmlFor="email"
        onChange={setEmail}
        value={value}
        disabled={disabled}
        autoFocus
      />
    </Fieldset>
  );
}

YourEmailInput.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};

YourEmailInput.defaultProps = {
  value: '',
  disabled: false,
};

export default FunnelStepForm({
  Input: YourEmailInput,
  submitOnChange: false,
  isSubmittable: (value) => value && validator(value),
});
