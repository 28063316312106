import React from 'react';
import { LendingHomeFatalError } from '@lendinghome/core';
import PropTypes from 'prop-types';
import ApplicationErrorPage from '../components/ApplicationErrorPage';

const { string, node, func } = PropTypes;

export default class ErrorHandling extends React.Component {
  static propTypes = {
    applicationName: string,
    errorHeader: func,
    errorFooter: func,
    children: node,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasFatalError: false,
    };

    const originalUnhandledRejection = window.onunhandledrejection;
    const originalWindowOnError = window.onerror;

    // Log any unhandled errors which occur inside of promises
    window.onunhandledrejection = (promiseRejection) => {
      const error = promiseRejection.reason;
      this.setErrorState(error);
      if (this.froggerExists()) {
        this.logErrorToThirdParties(error, {
          propagation: 'onunhandledrejection',
        });
      } else if (typeof originalUnhandledRejection === 'function') {
        originalUnhandledRejection(error);
      }
    };

    // Log any unhandled errors not caught within the normal Render or Promise error flow
    window.onerror = (...args) => {
      const error = args.find((arg) => arg instanceof Error);
      this.setErrorState(error || args[0]);

      if (this.froggerExists()) {
        this.logErrorToThirdParties(error || args[0], {
          propagation: 'window.onerror',
        }); // handle string error types also
      } else if (typeof originalWindowOnError === 'function') {
        originalWindowOnError(...args);
      }
    };
  }

  logErrorToThirdParties(error, tags) {
    if (error && this.froggerExists()) {
      if (error.tags && error.tags instanceof Array) {
        error.tags.push({ applicationName: this.props.applicationName }, tags);
      } else if (typeof error === 'object') {
        error.tags = [{ applicationName: this.props.applicationName }, tags];
      }
      if (this.errorIsFatal(error)) {
        try {
          window.Frogger.fatal(error);
        } catch (e) {
          /* Frogger rethrows errors in some environments, but we don't want that behavior at the very top
          of the application stack, so just consume any error if rethrown from Frogger */
        }
      } else {
        try {
          window.Frogger.error(error);
        } catch (e) {
          /* do nothing, see comment above */
        }
      }
    }
  }

  setErrorState = (e) => {
    if (this.errorIsFatal(e)) {
      this.setState(() => ({
        hasFatalError: true,
      }));
    }
  };

  errorIsFatal = (e) => {
    if (typeof e !== 'object') return false;
    return e.name === 'LendingHomeFatalError' || e.fatal;
  };

  froggerExists = () => typeof window.Frogger === 'object' && window.Frogger.isInitialized();

  componentDidCatch(error, info) {
    // All errors which bubble up from `render()` are considered fatal if not caught below this point
    // We wrap *ALL* fatal errors so we can have a standardized error message grouping in Sentry
    const wrappingError = new LendingHomeFatalError(
      `A Fatal Application Error Occurred in ${this.props.applicationName}`,
      error
    );
    this.setErrorState(wrappingError);
    wrappingError.data.push(info);

    // Send a Fatal error to Sentry, Fullstory, etc (fatal because it bubbled
    // to the top of the Application and blocked the user from continuing due to an invalid render state)
    this.logErrorToThirdParties(wrappingError, {
      propagation: 'componentDidCatch',
    });
  }

  render() {
    if (this.state.hasFatalError) {
      return (
        <ApplicationErrorPage
          errorHeader={this.props.errorHeader}
          errorFooter={this.props.errorFooter}
        />
      );
    }
    return this.props.children;
  }
}
