import postal from 'postal/lib/postal.lodash';
import ensurePluginSubscribedToEvent from './ensurePluginSubscribedToEvent';

// Just a humble wrapper for https://github.com/postaljs/postal.js that enables
// automatically subscribing a given list of plugins
// This should provide a place to add any business logic we may feel is necessary
// (e.g. naming conventions) as well
// as make it easier to switch libraries, should we deem it appropriate.
//
// Postal API can be found here: https://github.com/postaljs/postal.js/wiki/API
const eventBus = {
  reset() {
    postal.reset();
  },

  trigger(eventType, eventProperties, ...plugins) {
    if (plugins.length > 0) {
      for (const plugin of plugins) {
        ensurePluginSubscribedToEvent(this, plugin, eventType);
      }
    }

    return postal.publish({
      topic: eventType,
      data: eventProperties,
    });
  },

  subscribe(eventType, callback) {
    return postal.subscribe({
      topic: eventType,
      callback,
    });
  },

  unsubscribe(sub) {
    return postal.unsubscribe(sub);
  },

  unsubscribeFor(eventType) {
    return postal.unsubscribeFor({
      topic: eventType,
    });
  },
};

eventBus.init = (options = {}) => {
  const { loadEvents, plugins } = options;

  const bus = eventBus.withPlugins(...plugins);

  if (loadEvents) {
    loadEvents.forEach((event) =>
      bus.trigger(event.name, event.properties || undefined)
    );
  }

  return bus;
};

eventBus.withPlugins = (...plugins) => ({
  ...eventBus,
  trigger: (event, payload) => {
    eventBus.trigger(event, payload, ...plugins);
  },
});

export default eventBus;
