import React from 'react';
import { bemNamesFactory } from 'bem-names';
import { Link } from 'react-router';
import classnames from 'classnames';
import { flowStepPath } from '../../navigation';

export function BrokerLink() {
  const bem = bemNamesFactory('broker-link');
  return (
    <div className={bem()}>
      <p
        style={{ fontWeight: 'bold' }}
        className={bem('heading')}
      >
        Are you a broker?
      </p>
      <p className={bem('sub-heading', [])}>{'Join the Kiavi Broker Program.'}</p>
      <Link
        className={classnames(bem('cta'), 'button', 'is-button-large', 'is-button-secondary')}
        to={flowStepPath({ flow: 'broker', step: 'index' })}
      >
        Start Now
      </Link>
    </div>
  );
}
