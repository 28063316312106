import React from 'react';
import { json, HttpMethod } from '@lendinghome/core';
import { Callout } from '@lendinghome/core/components';
import { get, debounce, isEmpty } from 'lodash';
import { func, number } from 'prop-types';
import { LobErrorCodes } from '../constants';
import VerifyingMultiLineAddress from './VerifyingMultiLineAddress';

function geocodeWithLob(address, setErrors) {
  return json('/geocode_with_lob', {
    body: address,
    method: HttpMethod.Post,
  }).then(
    () => {
      setErrors(null);
    },
    (reason) => {
      const status = get(reason, ['response', 'status']);
      if (status === 422) {
        setErrors(reason.body);
      }
      // Otherwise, eat the error.  It isn't a show stopper and we don't need to show the yellow cone.
    }
  );
}

class GeocodingMultiLineAddress extends React.Component {
  state = { geocodeErrors: { codes: [], errors: null } };

  setGeocodeErrors = (geocodeErrors) => {
    if (!isEmpty(geocodeErrors)) {
      this.setState({ geocodeErrors });
    } else {
      this.setState({ geocodeErrors: { codes: [], errors: [] } });
    }
  };

  handleSelect = (value) => {
    this.geocode(value);
  };

  handleChange = (value) => {
    this.props.onChange(value);

    if (GeocodingMultiLineAddress.validator(value)) {
      debounce(this.geocode, this.props.debounceWait)(value);
    }
  };

  geocode = (value) => {
    this.props.geocoder(value, this.setGeocodeErrors);
  };

  render() {
    const { onChange, ...props } = this.props;
    const { geocodeErrors } = this.state;

    let errors = geocodeErrors.errors;
    if (geocodeErrors.codes.includes(LobErrorCodes.N1)) {
      errors = [...errors, 'Or click Next to continue with this address.'].join(' ');
    }

    return (
      <React.Fragment>
        <VerifyingMultiLineAddress
          onChange={this.handleChange}
          onSuggestSelect={this.handleSelect}
          {...props}
        />
        {!isEmpty(errors) && <Callout messages={errors} />}
      </React.Fragment>
    );
  }
}

GeocodingMultiLineAddress.propTypes = {
  geocoder: func,
  debounceWait: number,
};

GeocodingMultiLineAddress.defaultProps = {
  geocoder: geocodeWithLob,
  debounceWait: 1000,
};

GeocodingMultiLineAddress.validator = VerifyingMultiLineAddress.validator;

export default GeocodingMultiLineAddress;
