import { useEffect, useState } from 'react';
import { getIsBrokerApprovedForInfill } from '../graphql/graphql';

export default function useIsBrokerApprovedForInfill(isUserBroker) {
  const [isBrokerApprovedForInfill, setIsBrokerApprovedForInfill] = useState(null);

  useEffect(() => {
    if (isUserBroker) {
      getIsBrokerApprovedForInfill()
        .then((res) => {
          setIsBrokerApprovedForInfill(!!res?.broker?.isApprovedForInfill);
        })
        .catch(() => {
          setIsBrokerApprovedForInfill(false);
        });
    }
  }, [isUserBroker]);

  return { isBrokerApprovedForInfill, loading: isUserBroker && isBrokerApprovedForInfill === null };
}
