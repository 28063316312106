import passwordValidator from '../validators/passwordValidator';
import PasswordInput from './PasswordInput';
import CreateVerifyingTextField from './hoc/CreateVerifyingTextField';

const VerifyingPasswordInput = CreateVerifyingTextField({
  Input: PasswordInput,
  validator: passwordValidator,
  defaultInvalidMessage:
    'Password must be 8 to 128 characters, contain a number, upper case letter, and lower case letter.',
});

VerifyingPasswordInput.validator = passwordValidator;

export default VerifyingPasswordInput;
