/**
 * IMPORTANT!
 *
 * This file requires Intl available to be imported, so you
 * should import it INDIVIDUALLY after making sure you
 * loaded the Intl polyfill.
 */

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { bool } from 'prop-types';

export const bigMoneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const FormattedMoney = (props) => (
  <FormattedNumber
    {...moneyFormat.resolvedOptions()}
    {...props}
  />
);

const FormattedBigMoney = (props) => {
  const mergedProps = {
    ...bigMoneyFormat.resolvedOptions(),
    ...props,
  };

  // When an Intl.NumberFormat is constructed with maximumFractionDigits and minimumFractions
  // values equal to 0, the response from `resolvedOptions()` does not contain either value.
  //
  // const resolved = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).resolvedOptions();
  // console.assert(resolved.minimumFractionDigits === 0, 'minimumFractionDigits should be 0');
  // console.assert(resolved.maximumFractionDigits === 0, 'maximumFractionDigits should be 0');
  //
  // We believe this is a bug in chrome and have filed this issue:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1013340#c2
  //
  // We detect that case here and explicitly restore the two options we expect
  if (
    mergedProps.minimumFractionDigits === undefined &&
    mergedProps.maximumFractionDigits === undefined
  ) {
    mergedProps.minimumFractionDigits = 0;
    mergedProps.maximumFractionDigits = 0;
  }

  return <FormattedNumber {...mergedProps} />;
};

export default function FormatMoney({ isBigMoney, ...props }) {
  if (isBigMoney) {
    return <FormattedBigMoney {...props} />;
  }
  return <FormattedMoney {...props} />;
}

FormatMoney.defaultProps = {
  isBigMoney: false,
};

FormatMoney.propTypes = {
  isBigMoney: bool,
};
