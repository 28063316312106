import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, UnmountClosed } from 'react-collapse';

const { bool, node } = PropTypes;

export default function CollapsibleContent({
  isOpened,
  keepCollapsedContent,
  children,
}) {
  const CollapseComponent = keepCollapsedContent ? Collapse : UnmountClosed;
  return <CollapseComponent isOpened={isOpened}>{children}</CollapseComponent>;
}

CollapsibleContent.defaultProps = {
  keepCollapsedContent: true,
};

CollapsibleContent.propTypes = {
  isOpened: bool.isRequired,
  keepCollapsedContent: bool,
  children: node.isRequired,
};
