/**
 * DON'T IMPORT THIS FILE UNLESS ABSOLUTELY NECESSARY
 *
 * The import in the next line is required so DatePicker works, but
 * the same line is known to cause issues on IE11. Don't include this
 * file unless really necessary and make sure to test on IE11,
 * specially on the get-rates-flow app (/homeloans/flow).
 */
import 'react-dates/initialize';
import moment from 'moment';
import React, { Component } from 'react';
import { Select } from '@lendinghome/core/components';
import { SvgIcon } from '@lendinghome/components-core';
import momentBusinessDays from 'moment-business-days';
import { omit, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import { uiOrientation } from '../constants';

/**
 * Custom Select component to prevent using bind()/arrow functions in DatePicker.render()
 */
class DatePickerSelect extends Component {
  onChange = (selectedMonth) => {
    const { onChangeCallback, momentObject } = this.props;
    onChangeCallback(momentObject, selectedMonth);
  };

  render = () => {
    const { className, value, options } = this.props;

    return (
      <Select
        className={className}
        value={value}
        onChange={this.onChange}
        options={options}
        isFloatInput={false}
        searchable
      />
    );
  };
}

DatePickerSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  options: PropTypes.array,
  onChangeCallback: PropTypes.func,
  momentObject: PropTypes.object,
};

const getMomentDate = (value, isUTC = false) => {
  if (isUTC) {
    return value
      ? moment(value)
        .utc()
        .format('MM/DD/YYYY')
      : null;
  }
  return value ? moment(value) : null;
};

/**
 * DatePicker component class
 */
export default class DatePicker extends Component {
  constructor(props) {
    super(props);

    momentBusinessDays.updateLocale('us', {
      holidays: props.holidays,
      holidayFormat: 'YYYY-MM-DD',
    });

    this.state = {
      focused: props.focused,
      selectedMonth: null,
    };
  }

  onDateChange = (date) => {
    const { onDateChange, onChange, format, name } = this.props;
    onDateChange && onDateChange(date);
    const dateString = date && date.format(format);
    const event = new Event('onchange', { bubbles: true, cancelable: true });
    Object.defineProperty(event, 'target', {
      value: {
        name,
        value: dateString,
        type: 'text',
      },
    });
    onChange && onChange(dateString, event);
  };

  onFocusChange = (focused) => {
    this.setState(focused);
    this.props.onFocusChange && this.props.onFocusChange(focused);
  };

  /**
   * Renders the month/year selectors of the DatePicker component
   *
   * @param  Moment data.month         A Moment object representing current month/year
   * @param  func   data.onMonthSelect A callback for changing current month
   * @param  func   data.onYearSelect  A callback for changing current year
   *
   * @return Node
   */
  renderMonthElement = (data) => {
    const { month, onMonthSelect, onYearSelect } = data;
    const [monthOptions, yearOptions] = [this.buildMonthOptions(), this.buildYearOptions()];

    return (
      // We render our own set of day-of-week labels since the ones that come with react-dates
      // end up in a different stacking context and it's very difficult to prevent them from
      // showing on top of the Select components below. Hiding those labels and adding our own
      // on the same stacking context as our Select components hacks the issue away.
      // https://imgur.com/a/83F8OoD
      <div className="date-picker-controls">
        <div className="DayPicker_weekHeader">
          <ul className="DayPicker_weekHeader_ul">
            {moment.weekdaysMin().map((day) => (
              <li
                key={day}
                className="DayPicker_weekHeader_li"
              >
                {day}
              </li>
            ))}
          </ul>
        </div>

        <DatePickerSelect
          className="month-selector"
          value={month.month()}
          onChangeCallback={onMonthSelect}
          momentObject={month}
          options={monthOptions}
        />
        <DatePickerSelect
          className="year-selector"
          value={month.year()}
          onChangeCallback={onYearSelect}
          momentObject={month}
          options={yearOptions}
        />
      </div>
    );
  };

  buildMonthOptions = () => moment.months().map((label, value) => ({ value, label }));

  buildYearOptions = () => {
    const { lowestYear, highestYear } = this.props;
    return Array.from(Array(highestYear - lowestYear).keys()).map((value) => {
      const option = lowestYear + value;
      return { value: option, label: option };
    });
  };

  shouldRejectDays = (day) =>
    !isNil(this.props.earliestSelectableDate) && !isInclusivelyAfterDay(day, this.props.earliestSelectableDate);

  shouldRejectNonBusinessDays = (day) => this.props.allowBusinessDaysOnly && !momentBusinessDays(day).isBusinessDay();

  render = () => {
    const props = omit(this.props, DatePicker.unrequiredProps);

    const calendarIcon = <SvgIcon icon="calendar" />;

    return (
      <div className={`date-picker ${this.props.className}`}>
        <SingleDatePicker
          {...props}
          date={getMomentDate(this.props.value, this.props.isUTC)}
          onDateChange={this.onDateChange}
          focused={this.state.focused}
          onFocusChange={this.onFocusChange}
          renderMonthElement={this.renderMonthElement}
          noBorder
          isOutsideRange={this.shouldRejectDays}
          inputIconPosition="after"
          customInputIcon={calendarIcon}
          hideKeyboardShortcutsPanel
          isDayBlocked={this.shouldRejectNonBusinessDays}
        />
      </div>
    );
  };
}

DatePicker.propTypes = {
  /** The generated input ID */
  id: PropTypes.string,
  /** The component will show the date picker immediately upon rendering */
  focused: PropTypes.bool,
  /** Field name of the input element */
  name: PropTypes.string,
  /** The generated input placeholder */
  placeholder: PropTypes.string,
  /** If the generated input would be required or not */
  required: PropTypes.bool,
  /** If the generated input would be disabled or not */
  disabled: PropTypes.bool,
  /** Lowest year to show in DatePicker's year selectors */
  lowestYear: PropTypes.number,
  /** Highest year to show in DatePicker's year selectors */
  highestYear: PropTypes.number,
  /** Configure the first selectable date in the DatePicker using Moment. All dates past this date will be blocked. Defaults to null, allowing all days to be selectable. */
  earliestSelectableDate: PropTypes.object,
  /** Blocking selection of weekends and provided holidays. Note: This does not match lh-business time out of the box; to do so, configure the holidays using LH::BusinessTime::General.holidays */
  allowBusinessDaysOnly: PropTypes.bool,
  /** If allowBusinessDaysOnly === true, configure the date picker to also block selection for holidays. */
  holidays: PropTypes.array,
  /** Function to call when the user select a new date */
  onDateChange: PropTypes.func,
  /** Function to call when the user the component gets focused or loses
   *  focus */
  onFocusChange: PropTypes.func,
  /** How many months to show at once */
  numberOfMonths: PropTypes.number,
  /** How big the day square should be in the show calendar (in pixels) */
  daySize: PropTypes.number,
  /** How months should be aligned */
  orientation: PropTypes.oneOf(Object.values(uiOrientation)),
  /** Function to call on date change with string value */
  onChange: PropTypes.func,
  /** Moment compatible date format applied to date object and passed down to onChange */
  format: PropTypes.string,
  /** Sets the value of the Date Picker using Moment. Use if this is a controlled component */
  value: PropTypes.any,
  /** String of class names to hand down to the single date picker */
  className: PropTypes.string,
  /** Bool option to parse date in UTC format */
  isUTC: PropTypes.bool,
};

DatePicker.defaultProps = {
  placeholder: 'Date',
  name: 'date',
  focused: false,
  required: false,
  disabled: false,
  lowestYear: 1900,
  highestYear: 2100,
  earliestSelectableDate: null,
  allowBusinessDaysOnly: false,
  holidays: [],
  numberOfMonths: 1,
  daySize: 40,
  orientation: uiOrientation.HORIZONTAL,
  onDateChange: () => {},
  onFocusChange: () => {},
  onChange: () => {},
  format: 'MM/DD/YYYY',
  value: null,
  className: '',
  isUTC: false,
};

DatePicker.unrequiredProps = [
  'label',
  'options',
  'value',
  'onChange',
  'onBlur',
  'className',
  'lowestYear',
  'highestYear',
  'earliestSelectableDate',
  'allowBusinessDaysOnly',
  'holidays',
  'type',
  'field',
  'children',
  'name',
  'format',
  'isUTC',
];

export class UncontrolledDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: getMomentDate(this.props.defaultValue),
    };
  }

  onDateChange = (date) => {
    const { onDateChange } = this.props;
    this.setState({
      date: getMomentDate(date),
    });
    onDateChange && onDateChange(date);
  };

  render() {
    return (<DatePicker
      {...this.props}
      onDateChange={this.onDateChange}
      value={this.state.date}
    />);
  }
}

UncontrolledDatePicker.propTypes = {
  defaultValue: PropTypes.any,
  ...omit(DatePicker.propTypes, 'value'),
};

UncontrolledDatePicker.defaultProps = {
  defaultValue: null,
  ...omit(DatePicker.defaultProps, 'value'),
};
