import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { uiStates } from '@lendinghome/core';
import PropTypes from 'prop-types';
import { debounce, isObject, values, isEmpty } from 'lodash';

class Callout extends React.Component {
  componentDidMount() {
    this.hide = debounce(this.props.hide, 8000);
  }

  componentDidUpdate() {
    if (this.isActive() && this.autoClear()) {
      this.hide();
    }
  }

  componentWillUnmount() {
    this.hide.cancel();
    this.hide = null;
  }

  isActive = () => !isEmpty(this.props.title) || !isEmpty(this.props.messages);

  autoClear = () => this.props.state === uiStates.SUCCESS;

  render() {
    const { state, title, messages } = this.props;

    if (!this.isActive()) {
      return null;
    }

    const renderedMessages =
      messages &&
      (typeof messages === 'string' ? (
        <li>{messages}</li>
      ) : (
        messages.map((message) => {
          let rendered = message;

          if (isObject(message)) {
            rendered = <FormattedHTMLMessage {...message} />;
          }

          return <li key={message}>{rendered}</li>;
        })
      ));

    return (
      <div className={`callout is-callout-${state}`}>
        {title && <h1 className="callout--title">{title}</h1>}
        <div className="callout--body">
          <ul>{renderedMessages}</ul>
        </div>
      </div>
    );
  }
}

Callout.defaultProps = {
  title: null,
  state: uiStates.INFO,
  messages: null,
  hide: () => {},
};

const shape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.arrayOf(PropTypes.object),
]);

Callout.propTypes = {
  title: PropTypes.string,
  state: PropTypes.oneOf(values(uiStates)),
  messages: shape,
  hide: PropTypes.func,
};

export default Callout;
