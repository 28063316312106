import React, { Component } from 'react';
import jump from 'jump.js';
import { number, func, bool } from 'prop-types';

// Simple component for scrolling to a separate part
// of the page. Add a boolean property to your state
// (ie. `scrollStatus`) and pass that into this
// component's `isActive` prop. Define a function on
// your component to set your scroll state to false
// and pass that as `onJumpComplete`. When you set
// the scroll to `true` the browser will scroll
// to that element.
//
class JumpTarget extends Component {
  static defaultProps = {
    duration: 500,
  };

  static propTypes = {
    a11y: bool,
    callback: func,
    duration: number.isRequired,
    easing: func,
    isActive: bool,
    offset: number,
    onJumpComplete: func,
  };

  saveTarget = (target) => {
    this.target = target;
  };

  jumpToTarget() {
    setTimeout(() => {
      if (!this.target) return;
      jump(this.target, {
        a11y: this.props.a11y,
        callback: this.props.onJumpComplete,
        duration: this.props.duration,
        easing: this.props.easing,
        offset: this.props.offset,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      this.jumpToTarget();
    }
  }

  componentWillMount() {
    if (this.props.isActive) {
      this.jumpToTarget();
    }
  }

  render() {
    return <span ref={this.saveTarget} />;
  }
}

export default JumpTarget;
