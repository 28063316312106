import isEvent from './isEvent';

// Borrowed from: https://github.com/erikras/redux-form/blob/master/src/events/getValue.js
export default function getEventValue(event) {
  if (isEvent(event)) {
    const {
      target: { type, value, checked, files },
      dataTransfer,
    } = event;
    if (type === 'checkbox') {
      return checked;
    }
    if (type === 'file') {
      return files || (dataTransfer && dataTransfer.files);
    }
    return value;
  }

  return event && typeof event === 'object' && event.value !== undefined
    ? event.value // extract value from { value: value } structure. https://github.com/nikgraf/belle/issues/58
    : event;
}
