import MinLength from './MinLength';

export default class PhoneNumber extends MinLength {
  constructor({
    fieldName,
    message = 'Please enter a valid phone number',
    priority = null,
  }) {
    super({ fieldName, minLength: 10, message });
    this.priority = priority;
  }

  isValid(value) {
    const stringValue = value.toString();
    const digitsValue = value && stringValue.replace(/[\D]/g, '');
    return super.checkLength(digitsValue);
  }
}
