import React from 'react';
import FunnelCake from '../FunnelCake';
import GetRateFullScreen from '../GetRateFullScreen';
import SlideInfo from '../SlideInfo';
import BrokerBridgeInputForm from './inputs/BrokerBridgeInputForm';

function BrokerBridgeRentalFullScreen(props) {
  return (
    <GetRateFullScreen
      headline={
        'Are you looking to offer bridge (fix and flip, new construction) and rental loans to your real estate investor clients?'
      }
    >
      <SlideInfo>Our loan products are for residential non-owner-occupied properties.</SlideInfo>
      <BrokerBridgeInputForm
        fieldName="brokerBridgeRental"
        {...props}
      />
    </GetRateFullScreen>
  );
}

const BrokerBridgeRental = FunnelCake({
  InputForm: BrokerBridgeRentalFullScreen,
  // DANGER WILL ROBINSON!!!
  // This is a bit of a kludge. This sets account type for the entire broker flow.
  //
  // IF THIS IS EVER -NOT- THE FIRST STEP IN THE BROKER FLOW, MOVE THIS TO THE NEW FIRST STEP OR FACE THE WRATH OF KIRK!
  mapPropsToAjaxParams: () => ({
    account_type: 'broker',
  }),
});

export default BrokerBridgeRental;
