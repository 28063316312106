/**
 * Validates that the name value does not contain the character
 *  <, >, {, }, [, ], and/or = anywhere in the text.
 *
 * @param {String} value - Value for the text name
 * @returns {Boolean} whether or not `value` is is valid
 */
export default function validator(value) {
  return !`${value}`.match(/(<|>|{|}|\[|\]|=)/);
}
