import { segment, dataEvents } from './destinations';

class EventNotifier {
  static notify(event, data) {
    // postaljs does not return a promise when sending events to segment
    // it uses a pub/sub event bus
    segment.notify(event, data);
    // dataEvents uses fetch to log events, so return the Promise from the call
    // to be handled by the caller
    return dataEvents.notify(event, data);
  }

  static notifyFlowCompleted({ flow, step, state }) {
    return EventNotifier.notify('flow.completed', { flow, step, state });
  }

  static notifyIfProPlus({ step, state }) {
    const selectedBorrowerExperience = state.stepValue(step);
    if (selectedBorrowerExperience === '5+') {
      return EventNotifier.notify('borrower.isProPlus', { state });
    }
    // for linter warning: method expects a return
    return null;
  }

  static noOp() {}

  static notifyStepLoaded({ flow, step, state, url }) {
    return EventNotifier.notify('step.loaded', { flow, step, state, url });
  }

  static notifyStepCompleted({ flow, step, state, url }) {
    return EventNotifier.notify('step.completed', { flow, step, state, url });
  }

  static notifyFlowLoaded({ flow, step, state }) {
    return EventNotifier.notify('flow.loaded', { flow, step, state });
  }

  static notifyHardMoneyFlowLoaded({ flow, step, state }) {
    return EventNotifier.notify('flow.hard_money.loaded', { flow, step, state });
  }

  static notifyRentalFlowLoaded({ flow, step, state }) {
    return EventNotifier.notify('flow.rental.loaded', { flow, step, state });
  }
}
export default EventNotifier;
