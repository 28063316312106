import * as routes from '../routes';

const flow = 'proBorrower';

export default [
  {
    step: 'propertyState',
    previous: (state) => {
      if (state.project.isInfill()) {
        return { flow: 'getRate', step: 'infillExitsLast60' };
      }

      return { flow: 'getRate', step: 'exitsLast24' };
    },
    nextStep: (state) => {
      if (state.property.stateUnsupported()) {
        return { flow, step: 'unsupportedUsState' };
      }
    },
    stepNumber: '2.1',
  },
  // unsupportedUsState is a terminal state
  {
    step: 'yourEmail',
    nextStep: (state) => {
      if (state.auth0IsDisabled() && state.user.emailExists()) {
        return { flow, step: 'signIn' };
      }
      return { flow, step: 'yourPhone' };
    },
    stepNumber: '2.2',
  },
  {
    step: 'signIn',
    guard: (state) => state.user.emailExists(),
    nextStep: (state) => {
      if (state.user.hasLoans()) {
        return { href: '/intro' };
      }
      return { flow, step: 'submit' };
    },
    stepNumber: '2.2.1',
  },
  {
    step: 'yourPhone',
    previous: () => ({ flow, step: 'yourEmail' }),
    stepNumber: '2.3',
  },
  {
    step: 'yourName',
    nextStep: (state) => {
      if (state.auth0IsDisabled()) {
        return { flow, step: 'setPassword' };
      }

      return { flow, step: 'checkYourEmail' };
    },
    stepNumber: '2.4',
  },
  {
    step: 'checkYourEmail',
    previous: () => ({ flow, step: 'yourName' }),
    nextStep: (state) => {
      if (state.property.stateRequiresEntity()) {
        return { flow, step: 'leadPropertyAddress' };
      }

      return { flow, step: 'entity' };
    },
    stepNumber: '2.4.1',
  },
  {
    step: 'setPassword',
    previous: () => ({ flow, step: 'yourName' }),
    nextStep: (state) => {
      if (state.property.stateRequiresEntity()) {
        return { flow, step: 'leadPropertyAddress' };
      }

      return { flow, step: 'entity' };
    },
    stepNumber: '2.5',
  },
  {
    step: 'entity',
    previous: (state) => {
      if (state.auth0IsDisabled()) {
        return { flow, step: 'setPassword' };
      }

      return { flow, step: 'checkYourEmail' };
    },
    nextStep: (state) => {
      const requiresEntity =
        state.split.getTreatment('enable_require_entity_new_borrower') === 'on' && !state.borrower.hasEntity();

      if (requiresEntity) {
        return { flow, step: 'unsupportedBorrowerNoEntity' };
      }
    },
    guard: (state) => !state.property.stateRequiresEntity(),
    stepNumber: '2.5.2',
  },
  {
    step: 'entityInfo',
    guard: (state) => state.borrower.hasEntity(),
    stepNumber: '2.5.3',
  },
  {
    step: 'leadPropertyAddress',
    nextStep: (state) => {
      if (state.property.stateUnsupported()) {
        return { flow, step: 'unsupportedUsState' };
      }

      return { flow, step: 'submit' };
    },
    stepNumber: '2.5.1',
  },
  {
    step: 'submit',
    nextStep: (state) => ({
      href: routes.editLoan(state.loan.getId()),
    }),
    stepNumber: '2.6',
  },
  {
    step: 'unsupportedUsState',
    previous: () => ({ flow, step: 'propertyState' }),
    terminal: true,
    stepNumber: '2.1.1',
  },
  {
    step: 'unsupportedBorrowerNoEntity',
    previous: () => ({ flow, step: 'entity' }),
    guard: (state) => !state.property.hasEntity(),
    terminal: true,
    stepNumber: '2.5.2',
  },
];
