import React, { Component } from 'react';
import { CollapsibleContent } from '@lendinghome/components-core';
import { normalizeBoolean } from '@lendinghome/core/utils';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';

const { number, any, func, oneOfType, string, bool, arrayOf, shape } = PropTypes;

class RadioButtonCollection extends Component {
  static defaultProps = {
    cleanupFieldNames: true,
  };

  static label(option, key) {
    return (
      <label
        className="lh-radio"
        htmlFor={key}
      >
        {option.label}
      </label>
    );
  }

  static sectionHeader(option) {
    if (!option.sectionHeader) {
      return null;
    }

    return <div className="radio--section-header">{option.sectionHeader}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.props.currentValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      currentValue: nextProps.currentValue,
    }));
  }

  fieldName() {
    const name = this.props.fieldName || this.props.name;
    if (this.props.cleanupFieldNames) {
      return kebabCase(name);
    }

    return name;
  }

  fieldId(value) {
    const id = `${this.fieldName()}_${value}`;
    if (this.props.cleanupFieldNames) {
      return kebabCase(id);
    }

    return id;
  }

  handleOnChange = (event) => {
    const value = normalizeBoolean(event.target.value);

    this.setState({
      currentValue: value,
    });

    if (this.props.onChange) {
      this.props.onChange(event, value);
    }
  };

  render() {
    const { currentValue } = this.state;
    const containerClassNames = classnames(['radio--container', this.props.className]);

    const radios = this.props.options.map((option) => {
      const key = this.fieldId(option.value);
      const checked = currentValue === option.value;

      return (
        <div
          key={key}
          className="radio"
        >
          {this.constructor.sectionHeader(option)}
          <input
            className="radio--native"
            id={key}
            name={this.fieldName()}
            type="radio"
            value={option.value}
            onChange={this.handleOnChange}
            checked={checked}
            disabled={this.props.disabled}
          />
          {this.constructor.label(option, key)}
          {option.subtext && (
            <CollapsibleContent isOpened={this.props.uncollapseAllContent || checked}>
              <div className="radio--description-text">{option.subtext}</div>
            </CollapsibleContent>
          )}
        </div>
      );
    });

    return (
      <fieldset className={containerClassNames}>
        <div className="radio--container-flex">{radios}</div>
      </fieldset>
    );
  }
}

RadioButtonCollection.defaultProps = {
  options: [],
  disabled: false,
};

RadioButtonCollection.propTypes = {
  className: string,
  cleanupFieldNames: bool,
  currentValue: oneOfType([string, number, bool]),
  fieldName: string,
  name: string,
  onChange: func,
  disabled: bool,
  options: arrayOf(
    shape({
      value: any,
      label: any,
      subtext: any,
      sectionHeader: string,
    })
  ),
  uncollapseAllContent: bool,
};

export default RadioButtonCollection;
