import React from 'react';
import { connect } from 'react-redux';
import { localStorageHelpers } from '@lendinghome/core/utils';
import { LendingHomeFatalError } from '@lendinghome/core';
import { RehabType } from '@lendinghome/core/constants';
import { withSplitClient } from '@splitsoftware/splitio-react';
import { hot } from 'react-hot-loader';
import { Router, Route, browserHistory } from 'react-router';
import { get as dig, isNil } from 'lodash';
import { flowStepFromString, flowStepFromPathname, flowStepPath, rootFlowStep, navigateTo } from '../navigation';
import funnelStepOrdering from '../funnelStepOrdering';
import clientToServerMapping from '../clientToServerMapping';
import restoreFormDataFromServer from '../restoreFormDataFromServer';
import * as actions from '../actions';
import allRoutesConfig from '../flows/routes';
import FourOhFour from './FourOhFour';

const ROOT_FLOW = 'flow';

class App extends React.Component {
  constructor(props) {
    super(props);

    // This is a super dirty low level detail.  Deal with it once here to insulate the rest of the code from it,
    // and do a copy to protect against rogue modifications to a global object.
    this.serverData = {
      formData: { ...gon.form_data, loan: gon.loan },
      analytics: { ...gon.analytics },
      flowName: gon.default_flow_name,
      funnelVisit: { ...gon.funnel_visit },
    };

    this.setFunnelStepOrdering();
    this.restoreStepValuesFromServer();
    this.restoreMetaDataFromServer();
    this.restoreFurthestStepVisited();
    this.restoreIdentityMetaData();
  }

  componentDidMount() {
    this.redirectFromLastVisit();
  }

  setFunnelStepOrdering() {
    const flowName = dig(this.serverData, 'flowName', '');

    this.funnelStepOrdering = funnelStepOrdering(flowName);

    if (isNil(this.funnelStepOrdering)) {
      throw new LendingHomeFatalError('No step ordering configured in Funnel-Cakes', {
        data: { flowName },
      });
    }
  }

  restoreStepValuesFromServer() {
    restoreFormDataFromServer({
      stateQuerier: this.props.stateQuerier,
      setFunnelStepValue: this.props.setFunnelStepValue,
      serverFormData: this.serverData.formData,
      clientToServerMapping,
    });
  }

  restoreMetaDataFromServer() {
    const { setFunnelMetaData } = this.props;

    // TODO:
    // This should do something similar to restoreFormDataFromServer in which there is a consistent, reusable
    // mapping.  But for now, I'll just pick things off the gon object as it was formatted for pflow
    // one by one as I need them.
    if (gon.loan) {
      setFunnelMetaData({ key: 'loanId', value: gon.loan.id });
      if (gon.loan.approved_tofu) {
        // TODO:
        // I don't love how this is coupled to the knowledge of how the selector works.  It proves the concept though.
        setFunnelMetaData({
          key: 'tofuSubmitResult',
          value: 'approved',
        });
      }
      setFunnelMetaData({ key: 'isInfill', value: gon.loan.project_data.rehab_type === RehabType.INFILL });
    }

    if (gon.borrower) {
      setFunnelMetaData({
        key: 'borrowerId',
        value: gon.borrower.id,
      });

      setFunnelMetaData({
        key: 'borrowerHasTofuDecision',
        value: gon.borrower.has_tofu_decision,
      });

      setFunnelMetaData({
        key: 'borrowerFullName',
        value: gon.borrower.full_name,
      });
    }

    if (gon.referral) {
      setFunnelMetaData({
        key: 'referral',
        value: gon.referral,
      });
    }

    if (gon.experiments) {
      setFunnelMetaData({
        key: 'experiments',
        value: gon.experiments,
      });
    }

    setFunnelMetaData({
      key: 'auth0IsDisabled',
      value: gon.disable_auth0_top_of_funnel,
    });

    setFunnelMetaData({
      key: 'presentAddressId',
      value: gon.present_address_id,
    });
    setFunnelMetaData({
      key: 'supportedPropertyStatesList',
      value: gon.supported_states_sentence,
    });
    const funnelVisitId = dig(this.serverData, 'funnelVisit.id');
    setFunnelMetaData({ key: 'funnelVisitId', value: funnelVisitId });

    setFunnelMetaData({ key: 'loanPurpose', value: gon.loan_purpose });

    setFunnelMetaData({ key: 'lhHolidays', value: gon.lh_holidays });
    setFunnelMetaData({ key: 'minClosingDate', value: gon.min_closing_date });

    setFunnelMetaData({
      key: 'lowestInterestRates',
      value: gon.lowest_interest_rates,
    });

    if (gon.user) {
      setFunnelMetaData({ key: 'userIsPendingBroker', value: gon.user.is_pending_broker });
    }
    if (gon.current_user) {
      setFunnelMetaData({ key: 'userIsLoggedIn', value: true });
    }
  }

  restoreFurthestStepVisited() {
    if (this.furthestStepVisited()) {
      this.props.setFunnelMetaData({
        key: 'furthestStepVisited',
        value: flowStepFromString(this.furthestStepVisited()),
      });
    }
  }

  restoreIdentityMetaData() {
    const identityData = dig(this.serverData, 'analytics.identity', {});
    if (identityData) {
      this.props.setFunnelMetaData({
        key: 'analyticsIdentity',
        value: identityData,
      });
    }
  }

  getURLSearchParams(param) {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  }

  getRedirectMode() {
    const defaultMode = 'default';

    if (!localStorageHelpers.isLocalStorageAvailable) {
      return defaultMode;
    }

    const LOCAL_STORAGE_KEY = 'funnelCakesRedirectMode';
    let mode = this.getURLSearchParams('mode');

    if (mode) {
      localStorage.setItem(LOCAL_STORAGE_KEY, mode);
      return mode;
    }
    mode = localStorage.getItem(LOCAL_STORAGE_KEY);
    return mode || defaultMode;
  }

  redirectFromLastVisit() {
    const mode = this.getRedirectMode();

    if (mode === 'developer') {
      // Do nothing.  Let the given URL drive where we go.
    } else if (mode === 'experimental') {
      this.redirectExperimentalMode();
    } else {
      this.redirectDefaultMode();
    }
  }

  redirectExperimentalMode() {
    const furthestFlowStep = flowStepFromString(dig(this.serverData, 'funnelVisit.furthest_step_visited', ''));
    const currentFlowStep = flowStepFromPathname(window.location.pathname);

    if (currentFlowStep.flow === ROOT_FLOW && !currentFlowStep.step) {
      this.redirectDefaultMode();
    } else {
      const furthestLegalStep = this.funnelStepOrdering.getFurthestLegalStep({
        furthestFlowStep,
        currentFlowStep,
        state: this.props.stateQuerier,
      });
      if (furthestLegalStep) {
        navigateTo(furthestLegalStep);
      } else {
        navigateTo(this.rootProgramFlow());
      }
    }
  }

  redirectDefaultMode() {
    const currentFlowStep = flowStepFromPathname(window.location.pathname);

    if (currentFlowStep.flow === ROOT_FLOW && !currentFlowStep.step) {
      // navigation for repeat visits to the flow explicitly via the homepage,
      // product-specific pages and direct campaign links
      // eg: m/flow, m/flow?program=hard_money, m/flow?program=rental
      navigateTo(this.rootProgramFlow());
    } else if (this.lastStepVisited()) {
      // navigation when browser is refreshed mid-flow
      // eg: m/standardBorrower/email, m/rentalBorrower/propertyState
      const safeFlowStep = this.funnelStepOrdering.getSafeStepForFlow(flowStepFromString(this.lastStepVisited()));
      if (safeFlowStep) {
        const { flow, step } = safeFlowStep;
        navigateTo({ flow, step, loanId: this.loanId() });
      } else {
        navigateTo(this.rootProgramFlow());
      }
    } else {
      // default navigation for new leads
      navigateTo(this.rootProgramFlow());
    }
  }

  rootProgramFlow() {
    const program = this.getURLSearchParams('program');
    const flowName = dig(this.serverData, 'flowName', '');
    return rootFlowStep(program, flowName);
  }

  lastStepVisited() {
    return dig(this.serverData, 'funnelVisit.last_step_visited', '');
  }

  furthestStepVisited() {
    return dig(this.serverData, 'funnelVisit.furthest_step_visited', '');
  }

  loanId() {
    return dig(this.serverData, 'formData.loan.id');
  }

  render() {
    // Wait until the google maps api is available before allowing the app to continue
    if (!this.props.googleMapsApiLoaded) return null;

    const stateProps = {
      stateQuerier: Object.assign({}, this.props.stateQuerier, {
        split: this.props.client,
      }),
    };

    function createRoute({ flow, step, component: Component, stepUrl = step, withLoanId = false }) {
      const loanId = withLoanId ? ':loanId' : null;
      const path = flowStepPath({ flow, step: stepUrl, loanId });
      return (
        <Route
          path={path}
          key={path}
          component={(args) => (<Component
            {...args}
            {...stateProps}
            flow={flow}
            step={step}
          />)}
        />
      );
    }

    const flowName = dig(this.serverData, 'flowName', '');

    this.props.setFunnelMetaData({
      key: 'flowName',
      value: flowName,
    });

    if (isNil(allRoutesConfig[flowName])) {
      throw new LendingHomeFatalError('No flow configured in Funnel-Cakes', {
        data: { flowName },
      });
    }

    let allRoutes = allRoutesConfig[flowName].map((routeProperties) => createRoute(routeProperties));
    allRoutes.push(<Route
      path={flowStepPath({ flow: ROOT_FLOW })}
      key="root"
    />);
    allRoutes.push(<Route
      path={`${flowStepPath({ flow: 'property' })}/*`}
      key="property"
    />);
    allRoutes.push(<Route
      path="*"
      component={FourOhFour}
      key="404"
    />);

    return <Router history={browserHistory}>{allRoutes}</Router>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFunnelStepValue: ({ step, value }) => dispatch(actions.setFunnelStepValue({ step, value })),
  setFunnelMetaData: ({ key, value }) => dispatch(actions.setFunnelMetaData({ key, value })),
});

export default hot(module)(
  connect(
    ({ googleMapsApiState: { loaded: googleMapsApiLoaded } }) => ({
      googleMapsApiLoaded,
    }),
    mapDispatchToProps
  )(withSplitClient()(App))
);
