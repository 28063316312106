import React from 'react';
import PropTypes from 'prop-types';
import { ErrorHandling } from '../concerns';

const { node, string, func } = PropTypes;

export default class Application extends React.Component {
  static propTypes = {
    errorHeader: func,
    errorFooter: func,
    children: node,
    name: string,
  };

  static defaultProps = {
    applicationName: 'LendingHome Base Application',
  };

  render() {
    return (
      <ErrorHandling
        applicationName={this.props.name}
        errorHeader={this.props.errorHeader}
        errorFooter={this.props.errorFooter}
      >
        {this.props.children}
      </ErrorHandling>
    );
  }
}
