import SegmentEventBusPluginBase from '../src/SegmentEventBusPlugin';
import reactRouterMapper from './reactRouterMapper';

class SegmentEventBusPlugin extends SegmentEventBusPluginBase {
  constructor({ mapper, ...options }) {
    options.mapper = {
      ...reactRouterMapper,
      ...mapper,
    };

    super(options);
  }
}

export default SegmentEventBusPlugin;
