import Frogger from './src/frogger';

const config = window.gon && gon.global && gon.global.frogger;

if (!config) {
  /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
  console && console.error && console.error('Frogger configuration not present');

  const sentry = window.Sentry;
  sentry &&
    sentry.captureMessage &&
    sentry.withScope((scope) => {
      scope.setTags({ source: 'frogger' });
      sentry.captureException('Frogger configuration not present');
    });
}

// Instantiate Frogger even if config is not present (use default config)
let froggerTemp;
try {
  window.Frogger = window.Frogger || new Frogger(config || {});
  froggerTemp = window.Frogger;
} catch (e) {
  console && console.error && console.error('Error instantiating Frogger', e);
}

const frogger = froggerTemp;
export default frogger;
