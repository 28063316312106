export const mFlowStepLoaded = `
  mutation LogMFlowStepLoaded($input: LogFlowEventInput!) {
    logMFlowStepLoadedEvent(input: $input) {
      status
    }
  }
`;

export const mFlowStepCompleted = `
  mutation LogMFlowStepCompletedEvent($input: LogFlowEventInput!) {
    logMFlowStepCompletedEvent(input: $input) {
      status
    }
  }
`;

export const propertyFlowStepLoaded = `
  mutation LogPropertyFlowStepLoadedEvent($input: LogPropertyFlowEventInput!) {
    logPropertyFlowStepLoadedEvent(input: $input) {
      status
    }
  }
`;

export const propertyFlowStepCompleted = `
  mutation LogPropertyFlowStepCompletedEvent($input: LogPropertyFlowEventInput!) {
    logPropertyFlowStepCompletedEvent(input: $input) {
      status
    }
  }
`;
