import { connect } from 'react-redux';
import React, { Component } from 'react';
import SvgIcon from '@lendinghome/components-core/components/SvgIcon';
import ImageThumbnail from '@lendinghome/shared/components/ImageThumbnail';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

/**
 * Main component which renders a list of files.
 *
 * If you're using the Connected version of this component, you'll need to pass
 * a redux store which contains a `files` property. If you're using the Provider
 * component from react-redux, then a store will get passed automatically.
 * Learn how to add some files to the store by checking out the actions defined
 * in client/src/core/actions/fileList.js.
 */
export const FileList = ({
  files,
  thumbnails,
  renderToolbar,
  alwaysShowToolbar,
  renderFileListHeader,
  renderFileInfo,
}) => (
  <div className="file-list">
    {!isEmpty(files) && renderFileListHeader && renderFileListHeader()}
    {files.map((file, index) => (
      <FileListItem
        key={file.id || index}
        file={file}
        showThumbnail={thumbnails}
        renderToolbar={renderToolbar}
        alwaysShowToolbar={alwaysShowToolbar}
        renderFileInfo={renderFileInfo}
      />
    ))}
  </div>
);

FileList.propTypes = {
  files: PropTypes.array,
  thumbnails: PropTypes.bool,
  renderToolbar: PropTypes.func,
  alwaysShowToolbar: PropTypes.bool,
  renderFileListHeader: PropTypes.func,
  renderFileInfo: PropTypes.func,
};

FileList.defaultProps = {
  files: [],
  thumbnails: false,
  renderToolbar: null,
  alwaysShowToolbar: false,
  renderFileListHeader: null,
  renderFileInfo: () => {},
};

/**
 * Internal component to represent a single file.
 */
export class FileListItem extends Component {
  renderFileInfoSection = () => {
    const { file, renderFileInfo } = this.props;
    let info = <div className="name">{file.name}</div>;
    if (file.status === FileList.STATUS_OK) {
      info = (
        <a
          href={file.downloadUrl}
          rel="noopener noreferrer"
          target="_blank"
          className="name"
        >
          {file.name}
        </a>
      );
    }
    return <div className="info-wrapper">{renderFileInfo(file) || info}</div>;
  };

  renderStatusSection = () => {
    const { file, alwaysShowToolbar } = this.props;
    const { status, statusLabel } = file;

    let toolbar;
    if (this.props.renderToolbar) {
      toolbar = <div className="toolbar">{this.props.renderToolbar(file)}</div>;
    }

    const classes = classnames({
      status: true,
      processing: status === FileList.STATUS_PROCESSING,
      success: status === FileList.STATUS_OK,
      error: status === FileList.STATUS_ERROR,
    });

    return (
      <div
        className={classnames('status-toolbar-wrapper', {
          'always-show-toolbar': toolbar && alwaysShowToolbar && (isEmpty(status) || status === FileList.STATUS_OK),
        })}
      >
        <div className={classes}>{statusLabel}</div>
        {toolbar}
      </div>
    );
  };

  renderProgressBar = (progress) => (<div
    className="progressbar"
    style={{ width: `${progress || 0}%` }}
  />);

  render = () => {
    const { file, showThumbnail } = this.props;
    const { status, progress, thumbnail } = file;
    const error = status === FileList.STATUS_ERROR;
    const showPreview = showThumbnail && thumbnail;

    const classes = {
      'file-list-item': true,
      image: showPreview,
    };

    return (
      <div className={classnames(classes)}>
        <div className="icon-wrapper">
          {showPreview ? (
            <ImageThumbnail
              openGallery={false}
              file={file}
              className={'img'}
            />
          ) : (
            <SvgIcon
              icon="file"
              className="file-icon"
            />
          )}
          {error && <SvgIcon
            icon="error-badge"
            className="error-badge"
          />}
        </div>
        <div className="caption-progressbar-wrapper">
          <div className="info-status-wrapper">
            {this.renderFileInfoSection()}
            {this.renderStatusSection()}
          </div>
          {progress && this.renderProgressBar(progress)}
        </div>
      </div>
    );
  };
}

FileListItem.propTypes = {
  showThumbnail: PropTypes.bool,
  file: PropTypes.object.isRequired,
  renderFileInfo: PropTypes.func,
  renderToolbar: PropTypes.func,
  alwaysShowToolbar: PropTypes.bool,
};

FileListItem.defaultProps = {
  showThumbnail: false,
  renderFileInfo: () => {},
  renderToolbar: null,
  alwaysShowToolbar: false,
};

// Status constants
FileList.STATUS_OK = 'success';
FileList.STATUS_PROCESSING = 'processing';
FileList.STATUS_ERROR = 'error';

// Connected component
const mapStateToProps = (state) => ({
  files: state.files,
});

export default connect(mapStateToProps)(FileList);
