import { connect } from 'react-redux';
import { loanId, funnelVisitId } from '../selectors';
import * as ajax from '../ajax';
import FunnelCake from './FunnelCake';

export default function PropertyFunnelCake({
  // This may look a little unconventional, but it's arguably a pretty slick trick.
  // We're using the Template Method pattern to allow "subclasses" to enhance the props to ajax params
  // without having to explicitly ensure the "super" behavior still happens, but doing so in such a way that
  // the overrideable "method" has the same name as the one it's extending.
  mapPropsToAjaxParams: mapAdditionalPropsToAjaxParams = () => ({}),
  saveFunnelStep = ajax.saveFunnelStepForLoan,
  saveToServer = ajax.updatePropertyLoan,
  ...options
}) {
  const mapPropsToAjaxParams = (props) => ({
    loanId: props.loanId,
    ...mapAdditionalPropsToAjaxParams(props),
  });

  const mapStateToProps = (state) => ({
    loanId: loanId(state),
    funnelVisitId: funnelVisitId(state),
  });

  return connect(mapStateToProps)(
    FunnelCake({
      ...options,
      mapPropsToAjaxParams,
      saveFunnelStep,
      saveToServer,
    })
  );
}
