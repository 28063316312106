import { connect } from 'react-redux';
import React, { Component } from 'react';
import SvgIcon from '@lendinghome/components-core/components/SvgIcon';
import classnames from 'classnames';
import { difference } from 'lodash';
import AjaxUploader from 'rc-upload/lib/AjaxUploader';
import PropTypes from 'prop-types';
import { getFiles } from '../reducers/fileList';
import * as actions from '../actions/fileUploader';
import FileTypes from '../utils/file_types';
import S3 from '../utils/s3_service';
import FileList from './FileList';

export class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.s3Requester = this.getS3Requester();
  }

  getS3Requester = () => {
    const { uploadTestTime } = this.props;

    /**
     * S3-compatible requester function
     * @see https://github.com/react-component/upload#customrequest
     *
     * @param function(event: { percent: number }): void   rcOptions.onProgress
     * @param function(event: Error, body?: Object): void  rcOptions.onError:
     * @param function(body: Object): void                 rcOptions.onSuccess
     * @param object                                       rcOptions.data
     * @param string                                       rcOptions.filename
     * @param File                                         rcOptions.file
     * @param bool                                         rcOptions.withCredentials
     * @param string                                       rcOptions.action
     * @param object                                       rcOptions.headers
     *
     * @return object
     */
    return (rcOptions) => {
      const { file: uploadingFile } = rcOptions;

      let handler;
      if (this.isTestMode()) {
        this.fakeUpload(uploadingFile, uploadTestTime);
        handler = { abort: () => {} }; // Empty abort handler
      } else {
        this.realUpload(rcOptions, (abortHandler) => {
          handler = abortHandler;
        });
      }

      return { abort: () => handler.abort() };
    };
  };

  fakeUpload = (uploadingFile, uploadTestTime) => {
    // Fake upload. Don't call S3, just return a successful upload.
    window.Frogger.info('NOTICE: FileUploader is running on test mode!');
    setTimeout(() => this.onSuccess({ key: `upload/staging/${uploadingFile.name}` }, uploadingFile), uploadTestTime);
  };

  realUpload = (rcOptions, setAbortHandler) => {
    // Real mode. Get S3 credentials and upload file.
    S3.getService().then(
      (s3) => setAbortHandler(this.uploadFile(s3, s3.credentials, rcOptions)),
      (error) => this.props.onError(error, rcOptions.file, false)
    );
  };

  uploadFile = (s3, credentials, rcOptions) => {
    const { file: uploadingFile, onError, onSuccess, onProgress } = rcOptions;
    const params = this.getS3UploadParams(credentials, uploadingFile);
    const handler = s3.upload(params);

    handler.on('httpUploadProgress', (progress) => {
      const percent = parseInt((progress.loaded * 100) / progress.total, 10);
      onProgress(percent, { name: progress.key });
    });

    handler.send((err, data) => {
      err ? onError(err, uploadingFile) : onSuccess(data, { name: data.Key }); // AWS returns a capitalized Key for some reason.
    });

    return handler;
  };

  getS3UploadParams = (credentials, rcUploadFile) => {
    const fileKey = this.getFileKey(credentials.key, rcUploadFile.name);

    return {
      Key: fileKey,
      Body: rcUploadFile,
      ContentType: rcUploadFile.type,
      Bucket: credentials.bucket,
    };
  };

  getFileKey = (baseKey, filename) =>
    baseKey.replace(
      '${filename}', // eslint-disable-line no-template-curly-in-string
      `${Math.random()
        .toString(36)
        .substr(2, 5)}-${filename}`
    );

  onSuccess = (result, rcUploadFile) => {
    let file = (this.props.files || []).find((f) => f.id === rcUploadFile.uid);

    if (!file) {
      // If file is not in store we fallback to rcUploadFile.
      file = rcUploadFile;
      file.id = rcUploadFile.uid;
    }

    const { onSuccess, markAsComplete, onError } = this.props;

    // Run onSuccess callback with uploaded file extracted from store
    if (onSuccess) {
      try {
        onSuccess(file, result, (f) => markAsComplete(f));
      } catch (e) {
        onError(e, rcUploadFile);
      }
    } else {
      markAsComplete(file);
    }
  };

  getAllowedFileTypes = (types, allowedFileTypes) => {
    const array = types instanceof Array ? types : [types];
    const fileTypesMap = allowedFileTypes || FileTypes;
    const exts = array.reduce((arr, type) => arr.concat(fileTypesMap[type] || []), []);

    return difference(exts, this.props.excludedExtensions).join(',');
  };

  isTestMode = () => {
    const { testMode } = this.props;

    return typeof testMode === 'function' ? testMode() : testMode;
  };

  render = () => {
    const uploaderProps = {
      component: 'div',
      className: 'uploader',
      accept: this.getAllowedFileTypes(this.props.fileTypes, this.props.allowedFileTypes),
      multiple: true,
      customRequest: this.s3Requester,
      beforeUpload: this.props.onBeforeUpload,
      onStart: this.props.onStart,
      onSuccess: this.onSuccess,
      onProgress: this.props.onProgress,
      onError: this.props.onError,
      openFileDialogOnClick: true,
      id: 'file-uploader',
    };

    let fileList;
    if (this.props.showFileList) {
      fileList = (
        <FileList
          filter={this.props.fileListFilter}
          renderToolbar={this.props.renderToolbar}
          alwaysShowToolbar={this.props.alwaysShowToolbar}
          renderFileListHeader={this.props.renderFileListHeader}
          renderFileInfo={this.props.renderFileInfo}
        />
      );
    }

    return (
      <div
        className={classnames('file-uploader', this.props.className, {
          dense: this.props.dense,
        })}
      >
        {this.props.showDropzone && (
          <div
            className="dropzone"
            data-loan-id={this.props.loanId}
            data-data-type={this.props.dataType}
          >
            <AjaxUploader {...uploaderProps}>
              <div className="cloud-icon">
                <SvgIcon icon="upload" />
              </div>
              <h4>
                Drop files here to upload
                <br />
                or <b>choose files</b>
              </h4>
            </AjaxUploader>
          </div>
        )}
        {fileList}
      </div>
    );
  };
}

FileUploader.propTypes = {
  /** Public props */
  fileListFilter: PropTypes.func,
  allowedFileTypes: PropTypes.object,
  excludedExtensions: PropTypes.array.isRequired,
  fileTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  onSuccess: PropTypes.func,
  renderToolbar: PropTypes.func,
  renderFileListHeader: PropTypes.func,
  renderFileInfo: PropTypes.func,
  alwaysShowToolbar: PropTypes.bool,
  showFileList: PropTypes.bool,
  showDropzone: PropTypes.bool,
  testMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  uploadTestTime: PropTypes.number,
  /** A condensed version of the component for ops */
  dense: PropTypes.bool,

  /** Internal callbacks for use with redux-thunks */
  markAsComplete: PropTypes.func,
  onBeforeUpload: PropTypes.func,
  onError: PropTypes.func,
  onProgress: PropTypes.func,
  onStart: PropTypes.func,
};

FileUploader.defaultProps = {
  allowedFileTypes: null,
  excludedExtensions: [],
  fileTypes: Object.keys(FileTypes),
  alwaysShowToolbar: false,
  showFileList: true,
  showDropzone: true,
  testMode: () => Boolean(window && window.__TEST_MODE__),
  uploadTestTime: 250,
  renderFileListHeader: () => {},
  renderFileInfo: () => {},
  dense: false,
};

const mapStateToProps = (state) => ({
  files: getFiles(state),
});

export default connect(
  mapStateToProps,
  actions
)(FileUploader);
