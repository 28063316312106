/* eslint-disable no-use-before-define */
import camelizeKeys from '@lendinghome/utility-belt/camelCaseKeys';
import includes from 'array-includes';

import * as eventTypes from '../eventTypes';

// Removes null and undefined values
// camelCases all keys
// NOTE: currently only called on "identify" payloads (traits)
const cleanPayload = (properties) => {
  Object.getOwnPropertyNames(properties).forEach((property) => {
    const prop = properties[property];
    // Setting a property to null or undefined in Segment has no effect on new or existing
    // properties so we can just get rid of them all and significantly reduce payload size.
    if (prop === null || prop === undefined) {
      delete properties[property];
    }
  });

  // This may be a good place to clean out any properties we never want to send up
  // Might also be a good place to standardize property name formats

  return camelizeKeys(properties, { delimiter: '.' });
};

// Just an identify call with an explicitly `null` userId. This should attribute the
// traits to the current user, whether they are identified or not.
// NOTE: In MOST cases you should use this and NOT identify
export const addUserTraitsPayload = (traits) => identifyUserPayload(null, traits);

// NOTE: userId is a Segment identifier and not necessarily the id of the LH User model.
export const identifyUserPayload = (userId, userData) => {
  const sanitizedUserTraits = cleanPayload(userData);
  const { firstName, lastName, email, phone } = sanitizedUserTraits;

  return {
    eventType: eventTypes.IDENTIFY,
    eventPayload: {
      userId,
      firstName,
      lastName,
      email,
      phone,
      traits: { ...sanitizedUserTraits },
      options: {
        integrations: {
          Marketo: false,
        },
      },
    },
  };
};

export const trackPayload = (name, properties) => ({
  eventType: eventTypes.TRACK,
  eventPayload: {
    event: name,
    properties,
  },
});

export const pageViewPayload = (properties) => {
  const { name, pageName, ...rest } = properties;
  return {
    eventType: eventTypes.PAGE,
    eventPayload: {
      name: name || pageName,
      properties: rest,
    },
  };
};

export const aliasPayload = (id) => ({
  eventType: eventTypes.ALIAS,
  eventPayload: {
    userId: id,
  },
});

export const processIdentity = (userData = {}, userId = null, aliasId = null) => {
  const payload = [];

  // Alias
  if (aliasId) {
    // This guy is a little special in that it needs to be triggered BEFORE most other events
    // If one of the server events requires an alias mapping to be performed, this needs to be
    // done before an identify is called.
    payload.push(aliasPayload(aliasId));
  }

  // Identify
  payload.push(identifyUserPayload(userId, userData));

  return payload;
};

const ALIASABLE_EVENTS = ['user.login', 'lead.convert'];

const hasAliasableEvents = (events) => events && events.some((event) => includes(ALIASABLE_EVENTS, event.name));

/*
Initializes and returns a SegmentEventBusPlugin instance and identifies a visitor in a
standardized way.

options are thus:

  PluginClass:    The constructor to use when creating the plugin instance. Added because
                  react pages use a subclass of the default SegmentEventBusPlugin class.
  initialEvents:  Events to be triggered on load. These are only used to determine whether
                  ALIAS should be called (which needs to happen before any IDENTIFY calls
                  on a given page.
  userData:       Standard contextual data to identify a user. c.f. analytics_service.rb
  anything else:  All other options are passed to the plugin constructor.
*/
export const initPlugin = (options) => {
  const { PluginClass = SegmentEventBusPlugin.default, initialEvents, userData, mapper, ...rest } = options;

  let mappings = mapper;

  if ('initialEvents' in options) {
    // Automatically add server event mappings if the `serverEvents` option was provided (regardless
    // of value). Allow original mappings to override server mappings.
    mappings = {
      ...serverEventMappings(),
      ...mappings,
    };
  }

  const analyticsPlugin = new PluginClass({
    mapper: mappings,
    ...rest,
  });

  if (userData) {
    const standardizedUserData = camelizeKeys(userData);
    const userId = standardizedUserData.userId;

    const aliasId = userId && hasAliasableEvents(initialEvents) ? userId : null;

    // The presence of userData indicates that we want to identify the user. This isn't
    // really a response to some kind of event, so do just invoke it directly.
    analyticsPlugin.callMultipleSpecs(processIdentity(standardizedUserData, userId, aliasId));
  }

  return analyticsPlugin;
};

/*
Configures and returns an EventBus object configured with an analytics plugin. Does all
the stuff required for standard analytics instrumentation using the even bus.

Arguments and Configuration options are thus:
  eventBus:       A reference to the singleton EventBus object
  initialEvents:  Events relayed from the server that should be triggered when
                  the page is loaded
  plugins:        An array of any other event bus plugins you may wish to use
  anything else:  All other options are passed to the `initPlugin`
*/
export const initAnalyticsBus = (eventBus, configs) => {
  const { plugins = [], ...rest } = configs;

  const analyticsPlugin = initPlugin({
    ...rest,
  });

  return eventBus.init({
    loadEvents: rest.initialEvents,
    plugins: [analyticsPlugin, ...plugins],
  });
};

export const serverEventMappings = () => ({
  'user.login': (data) => trackPayload('User Signed In', data),
  'user.logout': (data) => trackPayload('User Signed Out', data),
});
