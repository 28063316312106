import { UsStates } from '@lendinghome/core/constants';

export function stateNameForCode(code) {
  const matchingState = UsStates.find((stateObject) => stateObject.usps_code === code);
  if (matchingState) {
    return matchingState.name;
  }
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
