import React from 'react';
import { object, bool, func } from 'prop-types';
import Fieldset from '../../Fieldset';
import VerifyingNameField from '../../VerifyingNameField';
import VerifyingEmailInput from '../../VerifyingEmailInput';
import VerifyingPhoneInput from '../../VerifyingPhoneInput';
import FunnelStepForm from '../../hoc/FunnelStepForm';

const emailValidator = VerifyingEmailInput.validator;
const phoneValidator = VerifyingPhoneInput.validator;

function UnsupportedErrorInputForm({ onChange, value, disabled, infoCollected }) {
  function setFirstName({ target }) {
    onChange({ ...value, firstName: target.value });
  }

  function setLastName({ target }) {
    onChange({ ...value, lastName: target.value });
  }

  function setEmail({ target }) {
    onChange({ ...value, email: target.value });
  }

  function setPhoneNumber(inputValue) {
    onChange({ ...value, phoneNumber: inputValue });
  }

  return infoCollected ? null : (
    <div className="unsupported-error">
      <Fieldset fieldsetLayout="two-column first-and-last-name">
        <VerifyingNameField
          label="First Name"
          htmlFor="firstName"
          onChange={setFirstName}
          value={value.firstName}
          disabled={disabled}
          autoFocus
        />
        <VerifyingNameField
          label="Last Name"
          htmlFor="lastName"
          onChange={setLastName}
          value={value.lastName}
          disabled={disabled}
        />
      </Fieldset>
      <Fieldset fieldsetLayout="phone-number">
        <VerifyingPhoneInput
          label="Phone Number"
          htmlFor="phoneNumber"
          id="phoneNumber"
          isFloatInput
          onChange={setPhoneNumber}
          value={value.phoneNumber}
          disabled={disabled}
        />
      </Fieldset>
      <Fieldset>
        <VerifyingEmailInput
          label="Email Address"
          htmlFor="email"
          onChange={setEmail}
          value={value.email}
          disabled={disabled}
        />
      </Fieldset>
    </div>
  );
}

UnsupportedErrorInputForm.propTypes = {
  onChange: func.isRequired,
  value: object,
  disabled: bool,
  infoCollected: bool,
};

UnsupportedErrorInputForm.defaultProps = {
  value: { firstName: '', lastName: '', email: '', phoneNumber: undefined },
  disabled: false,
  infoCollected: false,
};

function areValidNames({ firstName, lastName }) {
  const validator = VerifyingNameField.validator;

  return firstName && lastName && validator(firstName) && validator(lastName);
}

export default FunnelStepForm({
  Input: UnsupportedErrorInputForm,
  submitOnChange: false,
  isSubmittable: (value) =>
    value &&
    areValidNames(value) &&
    value.email &&
    emailValidator(value.email) &&
    value.phoneNumber &&
    phoneValidator(value.phoneNumber),
});
