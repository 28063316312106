import React from 'react';
import { VerticalSelectBar } from '@lendinghome/core/components';
import { bool, func } from 'prop-types';
import FunnelStepForm from '../../hoc/FunnelStepForm';

function FirstTimeHomeBuyerInput({ onChange, value, disabled }) {
  const options = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  return (
    <VerticalSelectBar
      options={options}
      fieldName="firstTimeHomeBuyer"
      currentValue={`${value}`}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

FirstTimeHomeBuyerInput.propTypes = {
  onChange: func.isRequired,
  value: bool,
  disabled: bool,
};

FirstTimeHomeBuyerInput.defaultProps = {
  value: undefined,
  disabled: false,
};

export default FunnelStepForm({
  Input: FirstTimeHomeBuyerInput,
  submitOnChange: true,
  isSubmittable: (value) => value || value === false,
});
