function createUserAndGroupHelpers(getClient) {
  const getUser = () => getClient().user();
  const getGroup = () => getClient().group();

  return {
    get userId() {
      return getUser().id();
    },

    set userId(value) {
      return getUser().id(value);
    },

    get anonymousId() {
      return getUser().anonymousId();
    },

    set anonymousId(value) {
      return getUser().anonymousId(value);
    },

    get traits() {
      return getUser().traits();
    },

    set traits(value) {
      return getUser().traits(value);
    },

    clearTraits() {
      // Not using getUser() because user() doesn't
      // exist until the first identify(...) call, meaning
      // getUser() doesn't work as the first call on initial
      // page load
      return getClient().identify();
    },

    get groupId() {
      return getGroup().id();
    },

    set groupId(value) {
      return getGroup().id(value);
    },

    get groupTraits() {
      return getGroup().traits();
    },

    set groupTraits(value) {
      return getGroup().traits(value);
    },

    clearGroupTraits() {
      return getGroup().traits({});
    },
  };
}

export default createUserAndGroupHelpers;
