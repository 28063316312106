import * as selectors from './selectors';

export default function makeStateQuerier(store) {
  return {
    stepValue: (step) => selectors.getFunnelSteps(store.getState())[step],
    flowName: () => selectors.flowName(store.getState()),
    experiments: () => selectors.experiments(store.getState()),
    flowDetails: () => selectors.flowDetails(store.getState()),
    experimentInsertionPointId: () => selectors.experimentInsertionPointId(store.getState()),
    auth0IsDisabled: () => selectors.auth0IsDisabled(store.getState()),
    borrower: {
      doesHoldRental: () => selectors.borrowerHoldsRental(store.getState()),
      isPro: () => selectors.isBorrowerPro(store.getState()),
      hasEntity: () => selectors.borrowerHasEntity(store.getState()),
      isRentalProgram: () => selectors.isRentalProgram(store.getState()),
      isRentalPortfolio: () => selectors.isRentalPortfolio(store.getState()),
      hasNoRentalProperties: () => selectors.hasNoRentalProperties(store.getState()),
      isFirstTimeHomeBuyer: () => selectors.isFirstTimeHomeBuyer(store.getState()),
      hasApprovedTofu: () => selectors.borrowerHasApprovedTofu(store.getState()),
      hasDeclinedTofu: () => selectors.borrowerHasDeclinedTofu(store.getState()),
      doesNotHaveTofuDecision: () => !selectors.borrowerHasTofuDecision(store.getState()),
      hasFrozenCredit: () => selectors.creditIsFrozen(store.getState()),
      hasCreditPullTimeout: () => selectors.creditPullTimeout(store.getState()),
      hasCreditPullRateLimit: () => selectors.hasCreditPullRateLimit(store.getState()),
      hasLhbrNetworkError: () => selectors.lhbrNetworkError(store.getState()),
      creditCheckRequiresSsn: () => selectors.creditHistoryNotFound(store.getState()),
      hasFullName: () => selectors.hasFullName(store.getState()),
      hasZeroExits: () => selectors.hasZeroExits(store.getState()),
      hasEnoughInfillExperience: () => selectors.hasEnoughInfillExperience(store.getState()),
    },
    loan: {
      getId: () => selectors.loanId(store.getState()),
      failedEligibility: () => selectors.failingEligibilityRules(store.getState()).length > 0,
      isPurchase: () => selectors.isPurchase(store.getState()),
      fundingState: () => selectors.fundingState(store.getState()),
    },
    project: {
      isInfill: () => selectors.isInfill(store.getState()) || selectors.isRehabTypeInfill(store.getState()),
    },
    property: {
      addressProvided: () => selectors.propertyAddressProvided(store.getState()),
      stateUnsupported: () => selectors.propertyStateUnsupported(store.getState()),
      stateUnsupportedForRental: () => selectors.propertyStateUnsupportedForRental(store.getState()),
      stateRequiresEntity: () => selectors.propertyStateRequiresEntity(store.getState()),
    },
    user: {
      emailExists: () => selectors.userEmailExists(store.getState()),
      hasLoans: () => selectors.hasLoans(store.getState()),
      isPendingBroker: () => selectors.userIsPendingBroker(store.getState()),
      userIsLoggedIn: () => selectors.userIsLoggedIn(store.getState()),
    },
    broker: {
      brokersBridgeLoans: () => selectors.brokersBridgeLoans(store.getState()),
      brokersBridgeRentalLoans: () => selectors.brokersBridgeRentalLoans(store.getState()),
      needsStateSpecificLicensing: () => selectors.needsStateSpecificLicensing(store.getState()),
      stateUnsupported: () => selectors.brokerStateUnsupported(store.getState()),
    },
    analytics: {
      identityData: () => selectors.analytics.identityData(store.getState()),
      leadId: () => selectors.analytics.leadId(store.getState()),
      userToken: () => selectors.analytics.userToken(store.getState()),
      userCreatedAt: () => selectors.analytics.userCreatedAt(store.getState()),
      browserToken: () => selectors.analytics.browserToken(store.getState()),
      userId: () => selectors.analytics.userId(store.getState()),
      adminUserId: () => selectors.analytics.adminUserId(store.getState()),
      ip: () => selectors.analytics.iP(store.getState()),
      isFakeLead: () => selectors.analytics.isFakeLead(store.getState()),
      urlParams: () => selectors.analytics.urlParams(store.getState()),
    },
  };
}
