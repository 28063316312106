import { PAGE } from '../src/eventTypes';

export default {
  '@@router/INIT_PATH': PAGE,
  '@@router/UPDATE_PATH': PAGE,
  '@@router/LOCATION_CHANGE': PAGE,
  '@@reduxReactRouter/initRoutes': PAGE,
  '@@reduxReactRouter/routerDidChange': PAGE,
  '@@reduxReactRouter/replaceRoutes': PAGE,
};
