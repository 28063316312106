import React from 'react';
import PageFooter from './PageFooter';

export default function LandingFooter() {
  return (
    <section className="landing--footer">
      <section className="landing--container">
        <PageFooter />
      </section>
    </section>
  );
}
